import { HideFilterActions } from '../actionTypes';
import { SessionStorageKeys } from '../../lib/constants';
import { isNullOrUndefined } from '../../lib/utils';
import { getSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';
/**
 * Gets the initial state for the hide store.
 * It uses any saved filter in session storage if available, or returns the default filter.
 * Only exporting for unit tests
 * @returns the initial state for the hide store
 */
export const getInitialState = (id) => {
  let initialState = {
    hideCancelledFlights: true,
    hideShortTurnAlerts: false,
    hideMxMessages: false,
  };
  try {
    const savedFilter = getSessionStorageItem(SessionStorageKeys.HIDE_FILTER);
    if (savedFilter && !isNullOrUndefined(id) && Object.keys(savedFilter).includes(id.toString())) {
      initialState = savedFilter[id];
    } else if (savedFilter && Object.keys(savedFilter).includes('hideCancelledFlights')) {
      initialState = savedFilter;
    }
  } catch (error) {
    console.error('Error parsing saved filter from session storage', error);
  }
  return initialState;
};

const initialState = getInitialState();

/**
 * Given the previous state and action payload, returns the new state for the store
 */
const hideFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT': {
      return getInitialState(action.id);
    }
    case HideFilterActions.TOGGLE_HIDE_CANCELLED_FLIGHTS:
      return {
        ...state,
        hideCancelledFlights: action.payload?.hideCancelledFlights ?? initialState.hideCancelledFlights,
      };
    case HideFilterActions.TOGGLE_HIDE_SHORT_TURN_ALERTS:
      return { ...state, hideShortTurnAlerts: action.payload?.hideShortTurnAlerts ?? initialState.hideShortTurnAlerts };
    case HideFilterActions.TOGGLE_HIDE_MX_MESSAGES:
      return { ...state, hideMxMessages: action.payload?.hideMxMessages ?? initialState.hideMxMessages };
    default:
      return state;
  }
};

export default hideFilterReducer;
