import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAirportsContext } from '../../../contexts/AirportsContext/AirportsContext';
import { utcToStationLocalTime, formatDateTime } from '../../../lib/dateTimeUtils';

export const DateDisplay = ({ utcDate, station, dateTimeFormat, isPDT }) => {
  const [stationTimeZone, setStationTimeZone] = useState(null);
  const { isLoading, getAirportDetail } = useAirportsContext();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const airportObject = getAirportDetail(station);

    if (airportObject != null) {
      setStationTimeZone(airportObject.airportTimeZone);
    }
  }, [isLoading, getAirportDetail, station]);

  return isPDT
    ? formatDateTime(utcDate, dateTimeFormat)
    : stationTimeZone
    ? utcToStationLocalTime(utcDate, stationTimeZone, dateTimeFormat)
    : '-';
};

DateDisplay.propTypes = {
  aircraftData: PropTypes.string,
  station: PropTypes.string,
  dateTimeFormat: PropTypes.string,
  isPDT: PropTypes.bool,
};
