import { getApiErrorMessage, logError } from '../../lib/appInsightsUtils';
import { getDatetimeUtcNowString } from '../../lib/dateTimeUtils';

const moduleName = 'authenticationServiceCallbacks.js';

export const accessTokenExpiredCallback = (userManager) => {
  // the message we will use for this event.
  const errorMessage = 'User Access Token Expired.';
  // Send a Track Trace to AppInsights and add the user's email as a
  userManager
    .getUser()
    .then((user) => {
      logError(errorMessage, moduleName, 'accessTokenExpiredCallback', {
        Email: user?.profile?.email,
      });
    })
    .catch((innerError) => {
      logError(getApiErrorMessage(innerError), moduleName, 'accessTokenExpiredCallback');

      console.error(innerError);
    });

  console.error(getDatetimeUtcNowString('YYYY-MM-DD HH:mm:ss') + 'Z => ' + errorMessage);
};

export const accessTokenExpiringCallback = () =>
  console.info(getDatetimeUtcNowString('YYYY-MM-DD HH:mm:ss') + 'Z => Access Token Expiring Event Raised.');

export const silentRefreshErrorCallback = (e) => {
  if (e != null && typeof e !== 'undefined') {
    logError(getApiErrorMessage(e), moduleName, 'silentRefreshErrorCallback');
    console.error(getDatetimeUtcNowString('YYYY-MM-DD HH:mm:ss') + 'Z => OIDC Silent Renew Error: ', e);
  }
};
