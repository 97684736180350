import React from 'react';
import Grid from '@material-ui/core/Grid';
import SummaryAircraftDetailRow from './SummaryAircraftDetailRow/SummaryAircraftDetailRow';
import TabSkeletonSection from '../../../FlightDetailPane/TabSkeletonSection/TabSkeletonSection';
import { useSelectedItemStore } from '../../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import { useAircraftDetails } from '../../../../../hooks/useAirTrakManagerQuery/useAirTrakManagerQuery';
import { formatDateTime } from '../../../../../lib/dateTimeUtils';
import {
  DateFormat,
  AirlineFilter,
  EntitlementNames,
  Treatment,
  TimeZonePreference,
  ApiErrorName,
} from '../../../../../lib/constants';
import { getAirlineName } from '../../../../../lib/displayUtils';
import AocAuthAccessCheck from '../../../../AocAuthAccessCheck/AocAuthAccessCheck';
import { isGroundEventPuck } from '../../../../../lib/utils';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { createErrListForClientSideFailures } from '../../../../../lib/AirTrakManagerUtils';
import PropTypes from 'prop-types';
import './SummaryAircraftTab.css';

const SummaryAircraftContainer = ({ children }) => {
  return (
    <div className="summary-aircraft-tab-container" data-cy="summary-aircraft-tab-container">
      <div className="aircraft-tab-container-row">{children}</div>
    </div>
  );
};

const MEL_DETAIL_ID = 'MEL_DATA';
const MX_MSG_DETAIL_ID = 'MAINTENANCE_MESSAGES';

//* SummaryAircraftTab component renders the MEL & Mx Messages and maintenance messages for the selected flight leg */
const SummaryAircraftTab = () => {
  // feature flag
  const { showFeature } = useFeatureFlag();
  const mxMsgFlag = showFeature(Treatment.MEL_MX_MESSAGES);

  // Get the selected flight leg from the store
  const { data: summaryFlightLeg } = useSelectedItemStore();
  const isGroundEvent = isGroundEventPuck(summaryFlightLeg?.puckType);
  const isSkywestFlight = summaryFlightLeg?.airline === getAirlineName(AirlineFilter.OO);

  // Call the useAircraftDetails hook to get the aircraft details for the selected flight leg from the AirTrakManager API
  const {
    isLoading: aircraftLoading,
    data: aircraftData,
    isRefetching,
  } = useAircraftDetails(
    {
      airline: summaryFlightLeg?.airline,
      origin: summaryFlightLeg?.orig,
      destination: summaryFlightLeg?.dest,
      flightNumber: summaryFlightLeg?.flightNumber,
      operatingAirlineCode: summaryFlightLeg?.operatingAirlineCode,
      departureCount: summaryFlightLeg?.departureCount,
      scheduledOperatingDate: formatDateTime(summaryFlightLeg?.departureDate, DateFormat.DEFAULT),
      aircraft: summaryFlightLeg?.aircraft,
    },
    isGroundEvent,
    !isSkywestFlight,
  );

  if (isSkywestFlight) {
    return (
      <SummaryAircraftContainer>
        <div data-cy="error-message-skywest-flight" className="no-mels-cdls-exist-aircraft">
          No Maintenance data is shown for Skywest flights.
        </div>
      </SummaryAircraftContainer>
    );
  }

  if (aircraftLoading || isRefetching) {
    return (
      <SummaryAircraftContainer>
        <TabSkeletonSection />
      </SummaryAircraftContainer>
    );
  }

  const melCdlList = aircraftData?.aircraftDetails?.melCdlList ?? [];
  const maintenanceMessages = aircraftData?.aircraftDetails?.maintenanceMessages ?? [];
  const errors =
    aircraftData?.errors ??
    createErrListForClientSideFailures([ApiErrorName.MAINTENANCE_MESSAGES, ApiErrorName.MEL_DATA]);

  return (
    <SummaryAircraftContainer>
      <Grid container>
        <SummaryAircraftDetailRow
          aircraftDetails={melCdlList}
          station={TimeZonePreference.SEA_LOCAL}
          detailId={MEL_DETAIL_ID}
          errors={errors}
        />
      </Grid>
      {mxMsgFlag && (
        <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.MXMESSAGES, EntitlementNames.SUPPORTVALIDATION]}>
          <Grid container>
            <SummaryAircraftDetailRow
              aircraftDetails={maintenanceMessages}
              station={TimeZonePreference.SEA_LOCAL}
              detailId={MX_MSG_DETAIL_ID}
              errors={errors}
            />
          </Grid>
        </AocAuthAccessCheck>
      )}
    </SummaryAircraftContainer>
  );
};

SummaryAircraftContainer.propTypes = {
  children: PropTypes.object.isRequired,
};

export default React.memo(withAppInsightsTracking(SummaryAircraftTab));
