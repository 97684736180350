import React from 'react';
import PropTypes from 'prop-types';
import CrewTable from '../CrewTable/CrewTable';
import { Grid } from '@material-ui/core';
import { reArrangeDeadheadsCrewData } from '../../../summaryPanelUtils';
import './DeadheadCrewTables.css';

const DeadheadCrewTables = ({
  flightCrew,
  nextFlightCrew,
  currentFlightErr,
  nextFlightErr,
  fallbackDepartureStation,
}) => {
  const columnNames = ['', '', 'Trip'];

  const { table1: currentTable1, table2: currentTable2 } = reArrangeDeadheadsCrewData(flightCrew.deadHeadCrewTrips);

  const { table1: nextTable1, table2: nextTable2 } = reArrangeDeadheadsCrewData(nextFlightCrew?.deadHeadCrewTrips);

  const displayHeadersCurrent1 = currentTable1.length > 0;
  const displayCurrent2 = currentTable2.length > 0;
  const displayHeadersNext1 = nextTable1.length > 0;
  const displayNext2 = nextTable2.length > 0;

  return (
    <Grid container spacing={2} className="deadheads-table">
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <CrewTable
          flightInfo={flightCrew.flight}
          errorObj={currentFlightErr}
          crewInfoList={currentTable1}
          columnNames={columnNames}
          displayHeaders={displayHeadersCurrent1}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <CrewTable
          flightInfo={flightCrew.flight}
          errorObj={currentFlightErr}
          crewInfoList={currentTable2}
          columnNames={columnNames}
          displayHeaders={displayCurrent2}
          displayTitle={displayCurrent2}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <CrewTable
          flightInfo={nextFlightCrew?.flight}
          errorObj={nextFlightErr}
          crewInfoList={nextTable1}
          columnNames={columnNames}
          displayHeaders={displayHeadersNext1}
          fallbackDepartureStation={fallbackDepartureStation}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <CrewTable
          errorObj={nextFlightErr}
          crewInfoList={nextTable2}
          columnNames={columnNames}
          displayHeaders={displayNext2}
          displayTitle={displayNext2}
          fallbackDepartureStation={fallbackDepartureStation}
        />
      </Grid>
    </Grid>
  );
};

DeadheadCrewTables.propTypes = {
  flightCrew: PropTypes.object.isRequired,
  nextFlightCrew: PropTypes.object,
  currentFlightErr: PropTypes.object,
  nextFlightErr: PropTypes.object,
  fallbackDepartureStation: PropTypes.string,
};

export default DeadheadCrewTables;
