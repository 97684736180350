import PropTypes from 'prop-types';
import { getFlattenedFilteredFlightLegs, getIrropsMenu } from './../lib/utils';
import { formatDateTime } from '../lib/dateTimeUtils';
import { getTurnTimesV2, getBlockMinutes } from '../services/apiClient/flightReferenceTimesApi/flightReferenceTimesApi';
import {
  IrropsModalFieldType,
  IrropsModal_Notification,
  IrropsCode,
  FormDataBrickType,
  IrropsMenuItemAction,
} from '../lib/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(duration);

/**
 * @description compares 2 times and determines if the matchTime is after targetTime
 * @param {*} matchTime
 * @param {*} targetTime
 * @returns returns true if matchTime is after targetTime
 */
export const timeIsAfter = (matchTime, targetTime) => {
  return matchTime !== null && targetTime !== null && dayjs.utc(matchTime).isAfter(dayjs.utc(targetTime));
};

/**
 * @description returns true if the ETA warning should be displayed
 * @param {*} timeETA object representing the ETA time
 * @param {*} warningMessages Enum of possible warning messages
 * @returns true if the ETA warning should be displayed
 */
export const showETAWarning = (timeETA, warningMessages) => {
  return (
    timeETA &&
    timeETA.errorMessage &&
    (timeETA.errorMessage === warningMessages.BLOCK_TIME_WARNING ||
      timeETA.errorMessage === warningMessages.ESTIMATES_WARNING)
  );
};

/**
 * @param {*} dateVal
 * @param {*} dateBase
 * @param {*} hoursThreshold
 * @returns {boolean} true if the time difference is within 8 hours
 */
export const isTimeDiffAfterWithin8Hours = (dateVal, dateBase, hoursThreshold = 8) => {
  let hoursDiff = dayjs.duration(dayjs.utc(dateVal).diff(dayjs.utc(dateBase)));

  return timeIsAfter(dateVal, dateBase) && hoursDiff.asHours() < hoursThreshold;
};

/**
 * @description Given a new date (dateVal) and a date base, validates the dateVal against dateBase and returns an updated dateVal.
 * The returned date will be dateVal incremented by 1 day when dateVal is less than dateBase and within the hoursThreshold.
 * @param {*} dateVal
 * @param {*} dateBase
 * @param {*} hoursThreshold
 * @returns it's validation date with 8 hours after
 */
export const getCorrectedDateWithinThreshold = (dateVal, dateBase) => {
  const isTimeDiffAfterWithin8Hours = (dateVal, dateBase, hoursThreshold = 8) => {
    let hoursDiff = dayjs.duration(dayjs.utc(dateVal).diff(dayjs.utc(dateBase)));
    if (!timeIsAfter(dateBase, dateVal) && hoursDiff.asHours() < hoursThreshold) {
      return true;
    }
    return false;
  };

  if (isTimeDiffAfterWithin8Hours(dateVal, dateBase)) {
    return dateVal;
  }
  let nextDayVal = dayjs.utc(dateVal).clone().add(1, 'days');
  if (isTimeDiffAfterWithin8Hours(nextDayVal, dateBase)) {
    return nextDayVal;
  }
  return dateVal;
};

/**
 * Given a base date time and a time string, returns a new dayjs utc object with the given time string.
 * The date of the returned dayjs utc object will be incremented by 1-day accordingly.
 * @param {Object} baseDateTime - a dayjs utc object
 * @param {string} timeString - time string formatted as HH:mm
 * @returns a dayjs utc object
 */
export const getTimeFormat = (baseDateTime, timeString) => {
  if (!isNullOrWhitespace(timeString)) {
    const currentTimeVal = dayjs(timeString, 'HH:mm');
    const hours = currentTimeVal.hours();
    const minutes = currentTimeVal.minutes();

    const updatedDateTime = dayjs.utc(baseDateTime).set('hour', hours).set('minutes', minutes).set('seconds', 0);

    return getCorrectedDateWithinThreshold(updatedDateTime, baseDateTime);
  }
};

/**
 * @description Checks to see if the passed in value is NULL or is just whitespace
 * @param {*} value
 * @returns returns true if the passed in value is NULL or whitespace
 */
export const isNullOrWhitespace = (value) => {
  if (value != null && value.toString().trim() !== '') {
    return false;
  }
  return true;
};

/**
 * @description Checks to see if the passed in value is NULL or not a number
 * @param {*} value
 * @returns returns true if the passed in value is NULL or whitespace or alpha-numeric and false if value is a number
 */
export const isNullOrNotNumber = (value) => {
  return isNullOrWhitespace(value) || isNaN(value);
};

/**
 * @description Checks to see if the passed in value is NULL
 * @param {*} value
 * @returns returns true if the passed in value is NULL
 */
export const isNull = (value) => {
  if (value === null) {
    return true;
  }
  return false;
};

/**
 * @description return boolean whether null or whitespace
 * @param {*} irropETA
 * @param {*} turnTimeMinutes
 * @param {*} contETA
 * @param {*} contETD
 * @param {*} selectedReason
 * @param {*} userComments
 * @returns returns true or false
 */
export const isCommitEnabled = (
  irropETA,
  turnTimeMinutes,
  contETD,
  contETA,
  selectedReason,
  userComments,
  hasAirTurnBackEntitlement,
  commitButtonClicked,
) => {
  return (
    !isNullOrWhitespace(irropETA.hhmm) &&
    !isNullOrWhitespace(irropETA.value) &&
    !isNullOrNotNumber(turnTimeMinutes.value) &&
    !isNullOrWhitespace(contETD.hhmm) &&
    !isNullOrWhitespace(contETD.value) &&
    !isNullOrWhitespace(contETA.hhmm) &&
    !isNullOrWhitespace(contETA.value) &&
    !isNullOrWhitespace(selectedReason) &&
    !isNullOrWhitespace(selectedReason.key) &&
    ((selectedReason.isCommentsRequired && !!userComments) ||
      //isCommitEnabled return true value as !null = !false = true. Added the below code to handle the same.
      selectedReason.isCommentsRequired === false) &&
    hasAirTurnBackEntitlement &&
    !commitButtonClicked
  );
};

/**
 * @description returns a FlightDetailBrick
 * @param {*} title
 * @param {*} value
 * @param {*} value2
 * @param {*} brickType
 * @param {*} showActive
 * @returns returns a FlightDetailBrickv
 */
export const getFlightDetailBrick = (title, value, value2, brickType, showActive = true) => {
  return { title, value, value2, brickType, isActive: showActive };
};

/**
 * @description "returns a bool for whether or not a flight leg can perform a given irrop"
 * @param {*} flightdata
 * @param {*} IrropsMenuItem
 * @returns returns true or false
 */
export const canPerformIrrop = (flightdata, IrropsMenuItem) => {
  //Hook to get default filter object
  let filter = {};
  let flightLegData = [];
  flightLegData.push(flightdata);
  let isExistIrrops = getIrropsMenu(getFlattenedFilteredFlightLegs(flightLegData, false, filter)[0]).some(
    (x) => x.menuActionType === IrropsMenuItem,
  );

  return isExistIrrops; // it's return true /false
};

export const getDetailsBlocks = (
  irropsCode,
  isDataForCFD,
  isActive,
  flightDateUtc,
  flightNumber,
  aircraftRegistration,
  origin,
  destination,
  outTime,
  etd,
  eta,
  irropsModal = '',
) => {
  let cfd = [];

  cfd.push(getFlightDetailBrick('Date (Z)', flightDateUtc, null, FormDataBrickType.STRING, isActive));
  cfd.push(getFlightDetailBrick('Flight', flightNumber.toString(), null, FormDataBrickType.STRING, isActive));
  cfd.push(getFlightDetailBrick('Aircraft', aircraftRegistration, null, FormDataBrickType.STRING, isActive));
  cfd.push(getFlightDetailBrick('Stations', origin, destination, FormDataBrickType.STATION_MULTIPLE, isActive));

  if (isDataForCFD) {
    // if the use case for this function is Current Flight Details
    switch (irropsCode) {
      case IrropsCode.DIVERT_FLIGHT:
      case IrropsCode.AIR_TURN_BACK:
      case IrropsCode.BLOCK_TURN_BACK:
      case IrropsCode.RETURN_TO_GATE:
        cfd.push(
          getFlightDetailBrick('Out Time', formatDateTime(outTime, 'HH:mm'), null, FormDataBrickType.TIME, isActive),
        );
        break;
      case IrropsCode.REROUTE_FLIGHT:
      case IrropsCode.REDISPATCH_FLIGHT:
      case IrropsCode.ADD_FLIGHT:
        // The ADD_CONTINUE_FLIGHT modal for an RRT flight if there is actual out time available then it should be OUT time
        if (irropsModal == IrropsMenuItemAction.ADD_CONTINUE_FLIGHT && !isNullOrWhitespace(outTime)) {
          cfd.push(
            getFlightDetailBrick('Out Time', formatDateTime(outTime, 'HH:mm'), null, FormDataBrickType.TIME, isActive),
          );
        } else {
          cfd.push(getFlightDetailBrick('ETD', formatDateTime(etd, 'HH:mm'), null, FormDataBrickType.TIME, isActive));
        }
        break;
      default:
        break;
    }
  } else {
    // if used for Cancel Flight Details
    if (
      irropsCode === IrropsCode.DIVERT_FLIGHT ||
      irropsCode === IrropsCode.REROUTE_FLIGHT ||
      irropsCode === IrropsCode.CANCELLED_FLIGHT
    ) {
      cfd.push(getFlightDetailBrick('ETD', formatDateTime(etd, 'HH:mm'), null, FormDataBrickType.TIME, isActive));
    }
  }

  if (eta) {
    cfd.push(getFlightDetailBrick('ETA', eta, null, FormDataBrickType.TIME, isActive));
  }

  return cfd;
};

export const isTimeDiffAfterWithinThresholdHours = (referenceDateTime, valueDateTime, hoursThreshold = 8) => {
  let hoursDiff = dayjs.duration(dayjs.utc(valueDateTime).diff(dayjs.utc(referenceDateTime)));
  if (!timeIsAfter(referenceDateTime, valueDateTime) && hoursDiff.asHours() < hoursThreshold) {
    return true;
  }
  return false;
};

/**
 * @description given a reference datetime and a time value in HHmm, this function returns a full dateTime value with corrected Date.
 * this function considers different permutations to see if time given is on next calendar day and adjusts date based on that.
 * @param {*} referenceDateTime
 * @param {*} timeInHHmm
 * @returns appropriate dateTime string value adjusted to next day date if necessary and formatted in YYYY-MM-DDTHH:mm:ssZ
 */
export const getCorrectedTimeOnDateWithThresholdInfo = (referenceDateTime, timeInHHmm, returnVal) => {
  let refDateUtc = dayjs.utc(referenceDateTime);
  let refTimeMmt = refDateUtc.format('HH:mm');
  let timeMmt = dayjs.utc(refDateUtc.format('YYYY-MM-DD') + timeInHHmm);
  let preCorrectedSameDayDateTime = dayjs
    .utc(referenceDateTime)
    .set('hour', timeMmt.hour())
    .set('minutes', timeMmt.minute());

  const etdHandler = () => {
    if (returnVal == 'ETD') {
      return `${IrropsModal_Notification.REFTIME_WARNING} ${refTimeMmt}`;
    } else {
      return IrropsModal_Notification.BLOCKTIME_WARNING;
    }
  };

  if (!preCorrectedSameDayDateTime.isBefore(dayjs.utc(referenceDateTime))) {
    let isError = !isTimeDiffAfterWithinThresholdHours(referenceDateTime, preCorrectedSameDayDateTime);
    let error = isError ? etdHandler() : '';
    return {
      value: preCorrectedSameDayDateTime.format('YYYY-MM-DDTHH:mm:ssZ'),
      isError,
      error,
      hhmm: preCorrectedSameDayDateTime.format('HH:mm'),
    };
  }
  let correctedToNextDate = preCorrectedSameDayDateTime.clone().add(1, 'days');
  let isError = !isTimeDiffAfterWithinThresholdHours(referenceDateTime, correctedToNextDate);
  let error = isError ? etdHandler() : '';
  return {
    value: correctedToNextDate.format('YYYY-MM-DDTHH:mm:ssZ'),
    isError,
    error,
    hhmm: correctedToNextDate.format('HH:mm'),
  };
};

/**
 * @description updates estimated values for all irrops input fields and calculates validity of fields and returns an object with all suggested changes to the irrops modal state.
 * all expected dateTime values should be in the format YYYY-MM-DDTHH:mm:ssZ
 * @param {string} operatingAirlineCode
 * @param {string} legNumber
 * @param {string} aircraftRegistration
 * @param {string} referenceStartDateTime
 * @param {string} origin
 * @param {string} destination
 * @param {string} irropStation
 * @param {object} irropETA
 * @param {bool}   isUpdateIrropETAWithBlockMinutes
 * @param {bool}   checkedContFlightNeeded
 * @param {object} userTurnTimeMinutes
 * @param {object} contETD
 * @param {object} contETA
 * @param {string} dirtyField
 * @param {string} dirtyValue
 * @returns appropriate dateTime value adjusted to next day date if necessary
 */
export const getCalculatedIrropDataEstimates = async (
  operatingAirlineCode,
  legNumber,
  aircraftRegistration,
  referenceStartDateTime,
  origin,
  destination,
  irropStation,
  irropETA,
  isUpdateIrropETAWithBlockMinutes,
  checkedContFlightNeeded,
  userTurnTimeMinutes,
  contETD,
  contETA,
  dirtyField,
  dirtyValue,
  shouldFetchServerTurnTimeOnIrropETAChange = false,
) => {
  let currentIrropETA = {
    hhmm: irropETA != null ? irropETA.hhmm : '',
    isDirty: false,
    isUpdateWithBlockMinutes: isUpdateIrropETAWithBlockMinutes,
    shouldFetchServerTurnTimeOnIrropETAChange: shouldFetchServerTurnTimeOnIrropETAChange,
  };
  let continuationChecked = {
    checked: checkedContFlightNeeded,
    isDirty: false,
  };
  let stations = {
    origin: origin,
    irropStation: irropStation.station,
    destination: destination,
    contTurnTimeMinutes: irropStation.contTurnTimeMinutes,
    irropBlockMinutes: irropStation.irropBlockMinutes,
    contBlockMinutes: irropStation.contBlockMinutes,
    isDirty: false,
  };
  let currentTurnTimeMinutes = {
    value: userTurnTimeMinutes != null ? userTurnTimeMinutes.value : null,
    isDirty: false,
  };
  let continuationETD = {
    hhmm: contETD != null ? contETD.hhmm : null,
    isDirty: false,
  };
  let continuationETA = {
    hhmm: contETA != null ? contETA.hhmm : null,
    isDirty: false,
  };
  switch (dirtyField) {
    case IrropsModalFieldType.IRROP_ETA:
      currentIrropETA.hhmm = dirtyValue;
      currentIrropETA.isDirty = true;
      break;
    case IrropsModalFieldType.CONTINUATION_CHECKED:
      continuationChecked.checked = dirtyValue;
      continuationChecked.isDirty = true;
      break;
    case IrropsModalFieldType.STATIONS:
      stations.irropStation = dirtyValue;
      stations.isDirty = true;
      break;
    case IrropsModalFieldType.CONTINUATION_TURNTIME:
      currentTurnTimeMinutes.value = dirtyValue;
      currentTurnTimeMinutes.isDirty = true;
      break;
    case IrropsModalFieldType.CONTINUATION_ETD:
      continuationETD.hhmm = dirtyValue;
      continuationETD.isDirty = true;
      break;
    case IrropsModalFieldType.CONTINUATION_ETA:
      continuationETA.hhmm = dirtyValue;
      continuationETA.isDirty = true;
      break;
    case IrropsModalFieldType.STATION_CONTINUATION_ADD:
      stations.destination = dirtyValue;
      stations.isDirty = true;
      break;
    default:
  }

  let result = await updateIrropDataEstimates(
    operatingAirlineCode,
    legNumber,
    aircraftRegistration,
    referenceStartDateTime,
    currentIrropETA,
    continuationChecked,
    stations,
    currentTurnTimeMinutes,
    continuationETD,
    continuationETA,
  );
  return result;
};

/**
 * @description updates estimated values for all irrops input fields and calculates validity of fields and returns an object with all suggested changes to the irrops modal state.
 * all expected dateTime values should be in the format YYYY-MM-DDTHH:mm:ssZ
 * @param {string} operatingAirline
 * @param {string} flightLegKey
 * @param {string} aircraftRegistration
 * @param {string} referenceStartDateTime
 * @param {object} irropETA
 * @param {object} continuationFlightChecked
 * @param {object} stations
 * @param {object} continuationETD
 * @param {object} continuationETA
 * @returns appropriate dateTime value adjusted to next day date if necessary
 */
export const updateIrropDataEstimates = async (
  operatingAirline,
  flightLegKey,
  aircraftRegistration,
  referenceStartDateTime,
  irropETA,
  continuationFlightChecked,
  stations,
  turnTimeMinutes,
  continuationETD,
  continuationETA,
) => {
  let isContStationsPresent =
    stations != null &&
    !isNullOrWhitespace(stations.irropStation) &&
    stations.irropStation.length === 3 &&
    !isNullOrWhitespace(stations.destination) &&
    stations.destination.length === 3;
  let isStationDirty = stations != null && stations.isDirty;
  let isIrropETADirty = irropETA.isDirty;
  let isTurnTimeDirty =
    continuationFlightChecked.checked &&
    (continuationFlightChecked.isDirty ||
      (turnTimeMinutes != null &&
        turnTimeMinutes.value != null &&
        (turnTimeMinutes.isDirty ||
          (isContStationsPresent && stations.contTurnTimeMinutes !== turnTimeMinutes.value))) ||
      (turnTimeMinutes != null && turnTimeMinutes.value != null && isContStationsPresent));
  let isContETDDirty =
    continuationFlightChecked.checked &&
    (continuationFlightChecked.isDirty ||
      (continuationETD != null && continuationETD.hhmm != null && continuationETD.isDirty));
  let isContETADirty =
    continuationFlightChecked.checked &&
    (continuationFlightChecked.isDirty ||
      (continuationETA != null && continuationETA.hhmm != null && continuationETA.isDirty));

  let returnVal = {};
  /*
  Return Objects helper methods begin
  */
  const setReturnElement = (value, elementType) => {
    returnVal[elementType] = value;
  };
  /*
  Return Objects helper methods end
  */
  /*
  Calculation helper methods below
  */

  const getReturnWithDateSet = (dateTimeStr, hhmm, etdVal) => {
    if (isNullOrWhitespace(dateTimeStr) || isNullOrWhitespace(hhmm)) {
      return { value: '', isError: false, error: '', hhmm: '' };
    }
    return getCorrectedTimeOnDateWithThresholdInfo(dateTimeStr, hhmm, etdVal);
  };

  const getUsableTurnTime = async () => {
    if (
      !isNullOrWhitespace(turnTimeMinutes) &&
      (!isNullOrWhitespace(turnTimeMinutes.value) || turnTimeMinutes.isDirty)
    ) {
      return isNaN(turnTimeMinutes.value) ? turnTimeMinutes.value.replace(/[^0-9]/g, '') : turnTimeMinutes.value;
    }
    return await getServerTurnTime();
  };

  const getServerTurnTime = async (forceApiCall = false, irropEtaValue = null) => {
    if (!forceApiCall && returnVal[IrropsModalFieldType.STATIONS]) {
      return returnVal[IrropsModalFieldType.STATIONS].contTurnTimeMinutes;
    }
    if (
      !isNullOrWhitespace(stations) &&
      !isNullOrWhitespace(stations.irropStation) &&
      !isNullOrWhitespace(stations.destination) &&
      !isNullOrWhitespace(flightLegKey)
    ) {
      if (stations.isDirty || forceApiCall) {
        let turnTimeResult = await getTurnTimesV2(
          operatingAirline,
          stations.irropStation,
          aircraftRegistration,
          flightLegKey,
          stations.origin,
          stations.destination,
          irropEtaValue,
        );
        return isNullOrNotNumber(turnTimeResult) ? '' : turnTimeResult;
      } else {
        return stations.contTurnTimeMinutes ?? null;
      }
    }
    return null;
  };

  const getServerBlockMinutes = async () => {
    if (returnVal[IrropsModalFieldType.STATIONS]) {
      return {
        irrop: returnVal[IrropsModalFieldType.STATIONS].irropBlockMinutes,
        continuation: returnVal[IrropsModalFieldType.STATIONS].contBlockMinutes,
      };
    }
    if (
      !isNullOrWhitespace(stations) &&
      !isNullOrWhitespace(stations.origin) &&
      !isNullOrWhitespace(stations.destination)
    ) {
      if (!isNullOrWhitespace(stations.irropStation) && stations.isDirty) {
        let irropBlockMinutesResult = null;
        if (irropETA.isUpdateWithBlockMinutes) {
          irropBlockMinutesResult = getBlockMinutes(operatingAirline, stations.origin, stations.irropStation);
        }
        let contBlockMinutesResult = getBlockMinutes(operatingAirline, stations.irropStation, stations.destination);
        let ib = null,
          cb = null;
        if (irropETA.isUpdateWithBlockMinutes) {
          try {
            ib = await irropBlockMinutesResult;
            ib = ib == null || ib == undefined ? '' : ib;
          } catch (ex) {
            ib = '';
          }
        }
        try {
          cb = await contBlockMinutesResult;
          cb = cb == null || cb == undefined ? '' : cb;
        } catch (ex) {
          cb = '';
        }
        return { irrop: isNullOrNotNumber(ib) ? '' : ib, continuation: isNullOrNotNumber(cb) ? '' : cb };
      } else {
        return {
          irrop: stations.irropBlockMinutes != null ? stations.irropBlockMinutes : '',
          continuation: stations.contBlockMinutes != null ? stations.contBlockMinutes : '',
        };
      }
    }
    return { irrop: '', continuation: '' };
  };

  const getIrropETAValue = async () => {
    // if(referenceStartDateTime == null || (!irropETA.isUpdateWithBlockMinutes && irropETA == null)) {
    //   return null;
    // }

    if (returnVal[IrropsModalFieldType.IRROP_ETA]) {
      return returnVal[IrropsModalFieldType.IRROP_ETA];
    }
    if (irropETA.isUpdateWithBlockMinutes && stations.isDirty) {
      let blockMinutes = await getServerBlockMinutes();
      if (blockMinutes == null || isNullOrNotNumber(blockMinutes.irrop)) {
        return getReturnWithDateSet(referenceStartDateTime, irropETA.hhmm);
      }

      let irropETAVal = dayjs.utc(referenceStartDateTime).add(blockMinutes.irrop, 'minutes');
      return {
        value: formatDateTime(irropETAVal, 'YYYY-MM-DDTHH:mm:ssZ'),
        isError: false,
        error: '',
        hhmm: irropETAVal.format('HH:mm'),
      };
    }
    return getReturnWithDateSet(referenceStartDateTime, irropETA.hhmm);
  };

  const getContETDValue = async () => {
    if (returnVal[IrropsModalFieldType.CONTINUATION_ETD]) {
      return returnVal[IrropsModalFieldType.CONTINUATION_ETD];
    }

    let irropETAValue = await getIrropETAValue();

    if (irropETAValue == null || isNullOrWhitespace(irropETAValue.value)) {
      return null;
    }

    if (isContETDDirty) {
      let turnTime = await getUsableTurnTime();
      if (continuationETD == null || (isNullOrWhitespace(continuationETD.hhmm) && !continuationETD.isDirty)) {
        if (isNullOrNotNumber(turnTime)) {
          return null;
        } else {
          let contETDVal = dayjs.utc(irropETAValue.value).add(turnTime, 'minutes');
          return {
            value: formatDateTime(contETDVal, 'YYYY-MM-DDTHH:mm:ssZ'),
            isError: false,
            error: '',
            hhmm: contETDVal.format('HH:mm'),
          };
        }
      }
      if (continuationETD.isDirty) {
        if (isNullOrWhitespace(continuationETD.hhmm)) {
          return null;
        }
        return getReturnWithDateSet(irropETAValue.value, continuationETD.hhmm, 'ETD');
      }
      if (!irropETA.isDirty && !stations.isDirty && !turnTimeMinutes.isDirty) {
        return getReturnWithDateSet(irropETAValue.value, continuationETD.hhmm, 'ETD');
      }
      if (isNullOrNotNumber(turnTime)) {
        return getReturnWithDateSet(irropETAValue.value, continuationETD.hhmm, 'ETD');
      } else {
        let contETDVal = dayjs.utc(irropETAValue.value).add(turnTime, 'minutes');
        return {
          value: formatDateTime(contETDVal, 'YYYY-MM-DDTHH:mm:ssZ'),
          isError: false,
          error: '',
          hhmm: contETDVal.format('HH:mm'),
        };
      }
    }
    return getReturnWithDateSet(irropETAValue.value, continuationETD.hhmm, 'ETD');
  };

  const getContETAValue = async () => {
    let contETDValue = await getContETDValue();
    if (contETDValue == null || isNullOrWhitespace(contETDValue.value)) {
      return null;
    }

    if (isContETADirty) {
      let blockMinutes = await getServerBlockMinutes();
      if (continuationETA == null || (isNullOrWhitespace(continuationETA.hhmm) && !continuationETA.isDirty)) {
        if (blockMinutes == null || isNullOrNotNumber(blockMinutes.continuation)) {
          return null;
        } else {
          let contETAVal = dayjs.utc(contETDValue.value).add(blockMinutes.continuation, 'minutes');
          return {
            value: formatDateTime(contETAVal, 'YYYY-MM-DDTHH:mm:ssZ'),
            isError: false,
            error: '',
            hhmm: contETAVal.format('HH:mm'),
          };
        }
      }
      if (continuationETA.isDirty) {
        if (isNullOrWhitespace(continuationETA.hhmm)) {
          return null;
        }
        return getReturnWithDateSet(contETDValue.value, continuationETA.hhmm);
      }
      if (blockMinutes == null || isNullOrNotNumber(blockMinutes.continuation)) {
        return getReturnWithDateSet(contETDValue.value, continuationETA.hhmm);
      } else {
        let contETAVal = dayjs.utc(contETDValue.value).add(blockMinutes.continuation, 'minutes');
        return {
          value: formatDateTime(contETAVal, 'YYYY-MM-DDTHH:mm:ssZ'),
          isError: false,
          error: '',
          hhmm: contETAVal.format('HH:mm'),
        };
      }
    }
    return getReturnWithDateSet(contETDValue.value, continuationETA.hhmm);
  };

  const setTurnTimeMinutesDirty = () => {
    if (continuationFlightChecked.checked && turnTimeMinutes != null) {
      isTurnTimeDirty = true;
    }
  };
  const setIrropETADirty = () => {
    if (irropETA.isUpdateWithBlockMinutes) {
      isIrropETADirty = true;
    }
  };
  const setContinuationETDDirty = () => {
    if (continuationFlightChecked.checked) {
      isContETDDirty = true;
    }
  };
  const setContinuationETADirty = () => {
    if (continuationFlightChecked.checked) {
      isContETADirty = true;
    }
  };
  /*
  Calculation helper methods above
  */

  if (
    isNullOrWhitespace(operatingAirline) ||
    isNullOrWhitespace(aircraftRegistration) ||
    isNullOrWhitespace(referenceStartDateTime)
  ) {
    return returnVal;
  }

  if (isStationDirty) {
    if (isContStationsPresent) {
      const blockMinutesResult = getServerBlockMinutes();
      const tt = irropETA.shouldFetchServerTurnTimeOnIrropETAChange
        ? stations.contTurnTimeMinutes
        : await getServerTurnTime(false); // false = don't force api call
      let bm = await blockMinutesResult;
      setReturnElement(
        {
          station: stations.irropStation,
          contTurnTimeMinutes: tt,
          irropBlockMinutes: bm.irrop,
          contBlockMinutes: bm.continuation,
        },
        IrropsModalFieldType.STATIONS,
      );
    } else {
      setReturnElement(
        { station: stations.irropStation, contTurnTimeMinutes: null, irropBlockMinutes: null, contBlockMinutes: null },
        IrropsModalFieldType.STATIONS,
      );
    }
    setIrropETADirty();
    setTurnTimeMinutesDirty();
    setContinuationETDDirty();
    setContinuationETADirty();
  }

  if (isIrropETADirty) {
    let irropETAValue = await getIrropETAValue();
    if (irropETA.shouldFetchServerTurnTimeOnIrropETAChange) {
      const turnTimeResult = irropETAValue?.value
        ? await getServerTurnTime(true, dayjs(irropETAValue.value).toISOString())
        : null; // true = force api call

      if (turnTimeResult) {
        if (returnVal[IrropsModalFieldType.STATIONS]) {
          let stationsRetVal = returnVal[IrropsModalFieldType.STATIONS];
          setReturnElement(
            {
              station: stationsRetVal.station,
              contTurnTimeMinutes: turnTimeResult ?? stationsRetVal.contTurnTimeMinutes,
              irropBlockMinutes: returnVal[IrropsModalFieldType.STATIONS].irropBlockMinutes,
              contBlockMinutes: returnVal[IrropsModalFieldType.STATIONS].contBlockMinutes,
            },
            IrropsModalFieldType.STATIONS,
          );
        } else {
          setReturnElement(
            {
              station: stations.irropStation,
              contTurnTimeMinutes: turnTimeResult ?? stations.contTurnTimeMinutes,
              irropBlockMinutes: stations.irropBlockMinutes,
              contBlockMinutes: stations.contBlockMinutes,
            },
            IrropsModalFieldType.STATIONS,
          );
        }
      }
    }
    setReturnElement(irropETAValue, IrropsModalFieldType.IRROP_ETA);
    setTurnTimeMinutesDirty();
    setContinuationETDDirty();
    setContinuationETADirty();
  }

  if (isTurnTimeDirty) {
    let serverTurnTime = await getServerTurnTime();
    if (serverTurnTime) {
      if (turnTimeMinutes != null && !isNullOrWhitespace(turnTimeMinutes.value)) {
        let isTurnTimeError = parseInt(turnTimeMinutes.value) < parseInt(serverTurnTime);
        if (turnTimeMinutes.isDirty || isTurnTimeError) {
          setReturnElement(
            {
              value: turnTimeMinutes.value,
              isError: isTurnTimeError,
              error: `${isTurnTimeError ? 'Warning: Short Turn Time' : ''}`,
            },
            IrropsModalFieldType.CONTINUATION_TURNTIME,
          );
        }
      } else {
        if (isContStationsPresent) {
          setReturnElement(
            { value: serverTurnTime, isError: false, error: '' },
            IrropsModalFieldType.CONTINUATION_TURNTIME,
          );
        }
      }
    }
    setContinuationETDDirty();
    setContinuationETADirty();
  }

  if (isContETDDirty) {
    let contETDValue = await getContETDValue();
    if (contETDValue) {
      setReturnElement(contETDValue, IrropsModalFieldType.CONTINUATION_ETD);
      setContinuationETADirty();
    }
  }

  if (isContETADirty) {
    let contETAValue = await getContETAValue();
    if (contETAValue) {
      setReturnElement(contETAValue, IrropsModalFieldType.CONTINUATION_ETA);
    }
  }

  return returnVal;
};
updateIrropDataEstimates.propTypes = {
  operatingAirline: PropTypes.string.isRequired,
  flightLegKey: PropTypes.string.isRequired,
  aircraftRegistration: PropTypes.string.isRequired,
  referenceStartDateTime: PropTypes.string.isRequired,
  irropETA: PropTypes.exact({
    hhmm: PropTypes.string,
    isDirty: PropTypes.bool,
    isUpdateWithBlockMinutes: PropTypes.bool,
  }),
  continuationFlightChecked: PropTypes.exact({
    checked: PropTypes.bool,
    isDirty: PropTypes.bool,
  }),
  stations: PropTypes.exact({
    origin: PropTypes.string,
    irropStation: PropTypes.string,
    destination: PropTypes.string,
    isDirty: PropTypes.bool,
    irropBlockMinutes: PropTypes.string,
    contTurnTimeMinutes: PropTypes.string,
    contBlockMinutes: PropTypes.string,
  }),
  turnTimeMinutes: PropTypes.exact({
    value: PropTypes.string,
    isDirty: PropTypes.bool,
  }),
  continuationETD: PropTypes.exact({
    hhmm: PropTypes.string,
    isDirty: PropTypes.bool,
  }),
  continuationETA: PropTypes.exact({
    hhmm: PropTypes.string,
    isDirty: PropTypes.bool,
  }),
};

/**
 *
 * @param {string} operatingAirlineCode
 * @param {string} aircraftRegistration
 * @param {number} flightNumber
 * @param {string} origin
 * @param {string} scheduledDestination
 * @param {string} destination
 * @param {any} scheduledOperatingDateUTC
 * @param {any} irropETA
 * @param {stirng} userComments
 * @param {string} reasonText
 * @param {number} departureCount
 * @param {any} continuationETD
 * @param {any} continuationETA
 * @param {bit} isEtopsFlight
 * @param {string} sessionId
 * @returns
 */
export const getAirTurnBackFlightSubmitBody = (
  operatingAirlineCode,
  aircraftRegistration,
  flightNumber,
  origin,
  scheduledDestination,
  destination,
  scheduledOperatingDateUTC,
  irropETA,
  userComments,
  reasonText,
  departureCount,
  continuationETD,
  continuationETA,
  isEtopsFlight,
  sessionId,
) => {
  let response = {};

  response.airturnbackFlightRequestBody = getBodyWithSessionId(
    {
      operatingAirlineCode: operatingAirlineCode,
      flightNumber: flightNumber.toString(),
      opsType: IrropsCode.AIR_TURN_BACK,
      actualOrigin: origin,
      actualDestination: origin,
      scheduledOrigin: origin,
      scheduledDestination: scheduledDestination,
      scheduledOperatingDateUTC: scheduledOperatingDateUTC,
      estimatedIn: `${formatDateTime(irropETA, 'YYYY-MM-DDTHH:mm:ssZ')}`,
      legNumber: '', //legNumber,
      comments: userComments,
      opsReason: reasonText,
      departureCount,
    },
    sessionId,
  );

  response.addContinueFlightRequestBody = getBodyWithSessionId(
    {
      operatingAirlineCode: operatingAirlineCode,
      flightNumber: flightNumber.toString(),
      opsType: IrropsCode.ADD_FLIGHT,
      actualOrigin: origin,
      actualDestination: destination,
      scheduledOrigin: origin,
      scheduledDestination: destination,
      scheduledOperatingDateUTC: scheduledOperatingDateUTC,
      aircraftRegistration: aircraftRegistration,
      scheduledOut: continuationETD,
      scheduledIn: continuationETA,
      estimatedOut: continuationETD,
      estimatedIn: continuationETA,
      isEtopsFlight: isEtopsFlight,
      legNumber: '', //legNumber,
      comments: userComments,
      opsReason: reasonText,
      // Changeing DepartureCount value from 0 to 1. because 1 is most likely the value used by Api
      // for incoming ContinuationFlight Irrops
      departureCount: departureCount + 1,
    },
    sessionId,
  );

  return response;
};

/**
 *
 * @param {string} scheduledOrigin
 * @param {string} scheduledDestination
 * @param {string} actualOrigin
 * @param {string} actualDestination
 * @param {string} flightNumber
 * @param {string} legNumber
 * @param {string} operatingAirline
 * @param {string} scheduledOperatingDateUTC
 * @param {string} comments
 * @param {string} reason
 * @param {number} departureCount
 * @param {string} sessionId
 * @returns
 */
export const getCancelFlightSubmitBody = (
  scheduledOrigin,
  scheduledDestination,
  actualOrigin,
  actualDestination,
  flightNumber,
  legNumber,
  operatingAirline,
  scheduledOperatingDateUTC,
  comments,
  reason,
  departureCount,
  sessionId,
) => {
  return getBodyWithSessionId(
    {
      scheduledOrigin: scheduledOrigin,
      scheduledDestination: scheduledDestination,
      actualOrigin: actualOrigin,
      actualDestination: actualDestination,
      flightNumber: flightNumber.toString(),
      legNumber: legNumber,
      operatingAirlineCode: operatingAirline,
      opsType: IrropsCode.CANCELLED_FLIGHT,
      scheduledOperatingDateUTC: scheduledOperatingDateUTC,
      comments: comments,
      opsReason: reason,
      departureCount: departureCount,
    },
    sessionId,
  );
};

/**
 * @param {string} body
 * @param {string} sessionId
 * @returns only body when sessionId is null and return body with sessionId when both parameters are passed
 */
export const getBodyWithSessionId = (body, sessionId) => {
  if (isNullOrWhitespace(sessionId)) {
    return body;
  }
  return { sessionId, ...body };
};

/**
 * @param {*} flightLine
 * @param {*} flightNumber
 * @param {*} destination
 * @returns Departure count for a given flight number and destination in a flight line
 */
export const getDepartureCount = (flightLine, flightNumber, orig, scheduledOperatingDateUTC) => {
  return flightLine
    .filter(
      (puck) =>
        puck.flightNumber === flightNumber &&
        puck.orig === orig &&
        formatDateTime(puck.departureDate, 'YYYY-MM-DD') === scheduledOperatingDateUTC,
    )
    .reduce((max, current) => Math.max(max, current.departureCount), 0);
};

/**
 *
 * @param {string} irropsCode
 * @param {string} operatingAirlineCode
 * @param {string} aircraftRegistration
 * @param {number} flightNumber
 * @param {string} origin
 * @param {string} irropStation
 * @param {string} scheduledDestination
 * @param {string} actualDestination
 * @param {any} scheduledOperatingDateUTC
 * @param {any} irropETA
 * @param {string} userComments
 * @param {string} reasonText
 * @param {number} departureCount
 * @param {any} continuationETD
 * @param {any} continuationETA
 * @param {bit} isEtopsFlight
 * @param {bit} checkedCancelFlight
 * @param {object} canceledFlightData
 * @param {bit} checkedContFlightNeeded
 * @param {string} sessionId
 * @returns a response object with irrops, cancelled and continuation api call body
 */
export const getIrropsFlightSubmitBody = (
  irropsCode,
  operatingAirlineCode,
  aircraftRegistration,
  flightNumber,
  origin,
  irropStation,
  scheduledDestination,
  actualDestination,
  scheduledOperatingDateUTC,
  irropETA,
  userComments,
  reasonText,
  departureCount,
  continuationETD,
  continuationETA,
  isEtopsFlight,
  checkedCancelFlight,
  canceledFlightData,
  checkedContFlightNeeded,
  sessionId,
) => {
  let response = {};

  response.irropFlightRequestBody = getBodyWithSessionId(
    {
      operatingAirlineCode: operatingAirlineCode,
      flightNumber: flightNumber.toString(),
      opsType: irropsCode,
      actualOrigin: origin,
      actualDestination: irropStation,
      scheduledOrigin: origin,
      scheduledDestination: scheduledDestination,
      scheduledOperatingDateUTC: scheduledOperatingDateUTC,
      estimatedIn: irropETA,
      legNumber: '', //legNumber,
      comments: userComments,
      opsReason: reasonText,
      departureCount,
    },
    sessionId,
  );

  response.canceledRequestBody = checkedCancelFlight
    ? getCancelFlightSubmitBody(
        canceledFlightData.canceledFlightScheduledOrigin,
        canceledFlightData.canceledFlightScheduledDestination,
        canceledFlightData.actualOrigin,
        canceledFlightData.actualDestination,
        canceledFlightData.flightNumber,
        '',
        canceledFlightData.operatingAirlineCode,
        canceledFlightData.scheduledOperatingDateUTC,
        userComments,
        reasonText,
        canceledFlightData.departureCount,
        sessionId,
      )
    : null;

  response.addContinueFlightRequestBody = checkedContFlightNeeded
    ? getBodyWithSessionId(
        {
          operatingAirlineCode: operatingAirlineCode,
          flightNumber: flightNumber.toString(),
          opsType: IrropsCode.ADD_FLIGHT,
          actualOrigin: irropStation,
          actualDestination: actualDestination,
          scheduledOrigin: irropStation,
          scheduledDestination: actualDestination,
          scheduledOperatingDateUTC: scheduledOperatingDateUTC,
          aircraftRegistration: aircraftRegistration,
          scheduledOut: continuationETD,
          scheduledIn: continuationETA,
          estimatedOut: continuationETD,
          estimatedIn: continuationETA,
          isEtopsFlight: isEtopsFlight,
          legNumber: '', //legNumber,
          comments: userComments,
          opsReason: reasonText,
          departureCount,
        },
        sessionId,
      )
    : null;

  return response;
};
