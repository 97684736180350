import { TimeZones } from './constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function rearrangeTrips(trips) {
  if (!trips) {
    return { yesterday: [], today: [], tomorrow: [] };
  }

  const timeZone = TimeZones.PDT;

  const nowUTC = dayjs().utc();

  // Convert 'now' to PST
  const nowPST = nowUTC.tz(timeZone);

  // Start of 'today' in PST
  const today = nowPST.startOf('day');

  // 'yesterday' and 'tomorrow' in PST
  const yesterday = today.subtract(1, 'day');
  const tomorrow = today.add(1, 'day');

  const newTrips = { yesterday: [], today: [], tomorrow: [] };

  function addFlightDetailToTrip(tripArray, trip, flightDetail) {
    if (!trip?.tripNumber || !flightDetail) return;

    const existingTrip = tripArray.find((t) => t.tripNumber === trip.tripNumber);
    if (existingTrip) {
      existingTrip.tripFlightDetails.push(flightDetail);
      existingTrip.tripFlightDetails.sort(
        (a, b) => dayjs(a.departureTime).valueOf() - dayjs(b.departureTime).valueOf(),
      );
    } else {
      tripArray.push({
        tripDate: trip.tripDate,
        tripNumber: trip.tripNumber,
        tripFlightDetails: [flightDetail],
      });
    }
  }

  function processTripArray(tripArray = [], currentNewTrips = []) {
    if (!Array.isArray(tripArray)) return;

    for (const trip of tripArray) {
      if (!trip?.tripFlightDetails) continue;

      if (trip.tripFlightDetails.length === 0) {
        currentNewTrips.push({
          tripDate: trip.tripDate,
          tripNumber: trip.tripNumber,
          tripFlightDetails: [],
        });
        continue;
      }

      for (const flightDetail of trip.tripFlightDetails) {
        if (!flightDetail?.departureTime) continue;

        try {
          const departureTimePST = dayjs.utc(flightDetail.departureTime).tz(timeZone);

          if (!departureTimePST.isValid()) {
            console.error('Invalid departure time:', flightDetail.departureTime);
            continue;
          }

          if (departureTimePST.isSame(yesterday, 'day')) {
            addFlightDetailToTrip(newTrips.yesterday, trip, flightDetail);
          } else if (departureTimePST.isSame(today, 'day')) {
            addFlightDetailToTrip(newTrips.today, trip, flightDetail);
          } else if (departureTimePST.isSame(tomorrow, 'day')) {
            addFlightDetailToTrip(newTrips.tomorrow, trip, flightDetail);
          }
        } catch (error) {
          console.error('Error processing departure time:', error);
        }
      }
    }
  }

  // Process each day's trips
  processTripArray(trips.yesterday, newTrips.yesterday);
  processTripArray(trips.today, newTrips.today);
  processTripArray(trips.tomorrow, newTrips.tomorrow);

  // Clean up empty trips
  const cleanUpEmptyTrips = (tripArray) => {
    return (tripArray || []).filter((trip) => trip?.tripFlightDetails?.length > 0);
  };

  newTrips.yesterday = cleanUpEmptyTrips(newTrips.yesterday);
  newTrips.today = cleanUpEmptyTrips(newTrips.today);
  newTrips.tomorrow = cleanUpEmptyTrips(newTrips.tomorrow);

  return newTrips;
}

export const getCrewErrorDataKey = (TripNumber, TripDate) => {
  return [TripNumber, TripDate];
};

/**
 * When the client side fails to contact AirTrakManager we want to construct the errors array like
 * AirTrakManager would normally when an internal errors happens within AirTrakManager
 * Error messages are not being consumed by the UI
 * @param {array} errConstants array of strings ApiErrorName
 * @returns {array} { errorName: ApiErrorName, message: '' }
 */
export const createErrListForClientSideFailures = (errConstants) => {
  return errConstants.reduce((acc, key) => {
    acc.push({
      errorName: key,
      message: '',
    });

    return acc;
  }, []);
};
