import {
  IrropsCode,
  PhaseOfFlight,
  PuckType,
  GroundEventType,
  OutOfServiceState,
  PuckSize,
  DateFormat,
  TimeZones,
} from '../../../lib/constants';
import {
  getPhaseOfFlight,
  getUniqueAircraftList,
  getSortedAircraftList,
  filterNotesTag,
  getInfoByPucks,
  getFlightPhaseStyle,
} from '../../../lib/utils';
import { formatDateTime, getDatetimeUtc, getTimeDifference, datetimeIsBefore } from '../../../lib/dateTimeUtils';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * @description - Maps a flattened flight leg into a puck config with default rowLevel 1
 * @param {Object} flight - a flattened flight leg
 * @param {Array} operationalNotes - list of operational notes
 * @returns - puck config object with structure:
 *   {
 *     puckType: PuckType.FLIGHT | PuckType.FLIGHT_CANCELLED,
 *     rowLevel: 1,
 *     data: {flightLegData}
 *   }
 */
const mapFlattenedFlightLegToPuckConfig = (flight, operationalNotes = []) => {
  // Set isWatchFlight and isInfoBy properties
  let isWatchFlight = false;
  let watchFlightOperationalNoteKey = '';
  let isInfoBy = false;
  let isEquipmentChange = false;
  let equipmentChangeOperationalNoteKey = '';
  let isEquipmentChangeAcknowledgment = false;
  let equipmentChangeAcknowledgmentOperationalNoteKey = '';

  if (operationalNotes.length !== 0) {
    const flightLegKey = flight.flightLegKey;
    let filterWatchFlightTag = filterNotesTag(operationalNotes, flightLegKey, 'WatchFlight');
    if (filterWatchFlightTag.length > 0) {
      isWatchFlight = true;
      watchFlightOperationalNoteKey = filterWatchFlightTag[0].operationalNoteKey;
    }
    if (!isWatchFlight) {
      const infoByPuck = getInfoByPucks(operationalNotes, flightLegKey);
      if (infoByPuck) {
        isInfoBy = infoByPuck?.isInfoBy;
      }
    }

    let filterEquipmentChangeTag = filterNotesTag(operationalNotes, flightLegKey, 'EquipmentChange');
    if (filterEquipmentChangeTag.length > 0) {
      isEquipmentChange = true;
      equipmentChangeOperationalNoteKey = filterEquipmentChangeTag[0].operationalNoteKey;
    }

    let filterEquipmentChangeAcknowledgementTag = filterNotesTag(
      operationalNotes,
      flightLegKey,
      'EquipmentChangeAcknowledgement',
    );
    if (filterEquipmentChangeAcknowledgementTag.length > 0) {
      isEquipmentChangeAcknowledgment = true;
      equipmentChangeAcknowledgmentOperationalNoteKey = filterEquipmentChangeAcknowledgementTag[0].operationalNoteKey;
    }
  }

  return {
    puckType: flight.irropsCode === IrropsCode.CANCELLED_FLIGHT ? PuckType.FLIGHT_CANCELLED : PuckType.FLIGHT,
    rowLevel: 1,
    flightPuckData: {
      flightLegKey: flight.flightLegKey,
      flightNumber: flight.flightNumber,
      orig: flight.orig,
      dest: flight.dest,
      scheduledOrigin: flight.scheduledOrigin,
      scheduledDestination: flight.scheduledDestination,
      departure: !!flight.atd ? flight.atd : flight.etd,
      arrival: !!flight.ata ? flight.ata : flight.eta,
      atd: flight.atd,
      off: flight.off,
      etoff: flight.etoff,
      on: flight.on,
      eton: flight.eton,
      ata: flight.ata,
      eta: flight.eta,
      etd: flight.etd,
      std: flight.std,
      sta: flight.sta,
      departureDate: flight.departureDate,
      aircraft: flight.aircraft,
      airline: flight.airline,
      irropsCode: flight.irropsCode,
      notifications: flight.notifications,
      isEtopsFlight: flight.isEtopsFlight,
      departureCount: flight.departureCount,
      projectedDestination: flight.projectedDestination,
      isWatchFlight: isWatchFlight,
      watchFlightOperationalNoteKey: watchFlightOperationalNoteKey,
      isInfoBy: isInfoBy,
      isEquipmentChange,
      equipmentChangeOperationalNoteKey,
      isEquipmentChangeAcknowledgment,
      equipmentChangeAcknowledgmentOperationalNoteKey,
    },
    groundPuckData: null,
  };
};

/**
 * @description - Maps a ground event into a puck config with default rowLevel 1
 * @param {Object} groundEvent - dictionary of aircraft keys to list of ground events
 * @returns - puck config object with structure:
 *   {
 *     puckType: PuckType.GROUND_OTS,
 *     rowLevel: 1,
 *     data: {groundEventData}
 *   }
 */
const mapGroundEventToPuckConfig = (groundEvent, aircraft) => {
  // Set PuckType of ground event
  let puckType = null;
  switch (groundEvent.groundEventType) {
    case GroundEventType.STANDBY:
      puckType = PuckType.GROUND_STANDBY;
      break;
    case GroundEventType.SCHEDULED_OTS:
      puckType = PuckType.GROUND_OTS;
      break;
    case GroundEventType.UNSCHEDULED_OTS:
      puckType = PuckType.GROUND_OTS;
      break;
    default:
      return puckType;
  }

  // scheduledUTCTime represents the OutOfService time
  // Updated to ScheduledUTCTime in Endtimes which reperesents Out odf Service
  let startTime = groundEvent.endTimes.scheduledUTCTime;

  let endTime, state;
  let endTimes = groundEvent.endTimes;
  if (endTimes.actualUTCTime) {
    endTime = endTimes.actualUTCTime;
    if (endTimes.estimatedUTCTime) {
      state = OutOfServiceState.COMPLETED_ETR;
    } else {
      state = OutOfServiceState.COMPLETED;
    }
    // actualUTCTime == work is complete
  } else if (endTimes.projectedUTCTime) {
    // projectedUTCTime == ADV
    endTime = endTimes.projectedUTCTime;
    state = OutOfServiceState.ADVISORY;
  } else if (endTimes.estimatedUTCTime) {
    // estimatedUTCTime == ETR
    endTime = endTimes.estimatedUTCTime;
    state = OutOfServiceState.ESTIMATED_TIME_READY;
  } else {
    // this case really should not happen b/c
    // estimatedUTCTime column is not null
    endTime = endTimes.scheduledUTCTime;
    state = OutOfServiceState.UNKNOWN;
  }

  return {
    puckType: puckType,
    rowLevel: 1,
    groundPuckData: {
      groundEventKey: groundEvent.groundEventKey,
      groundEventStation: groundEvent.groundEventStation,
      groundEventType: groundEvent.groundEventType,
      start: startTime,
      end: endTime,
      airline: groundEvent.operatingAirline,
      aircraft: aircraft,
      state: state,
    },
    flightPuckData: null,
  };
};

/**
 * @description - Given flight legs and ground events, merges and returns a config object
 *   for rendering the data in the gantt chart.
 *   - Aircrafts are sorted
 *   - Each aircraft contains the total rows the line of flight will take up
 *   - Each aircraft contains a list of scheduled and canceled pucks to display.
 *   - Pucks are ordered and assigned with the appropriate row level (for overlapping pucks)
 * @param {Array} flightLegs - list of flattened flight legs
 * @param {Object} groundEventsData - dictionary of aircraft keys to list of ground events
 * @param {Array} sortedFleetType - list of sorted subfleets based on sorting priority
 * @param {string} operatingAirlineOfRole - operating airline mapped to the role
 * @returns - gantt config object with structure:
 *  {
 *    'N015QE': {
 *       totalRows: 1,
 *       scheduled: [{flightPuckConfig, rowLevel: 1} | {groundPuckConfig, rowLevel: 2}, ...],
 *       canceled: [{flightPuckConfig, rowLevel: 1}, ...],
 *    }
 *  }
 */
const getLegacyGanttConfig = (
  flightLegs = [],
  groundEventsData = {},
  sortedFleetType = [],
  operatingAirlineOfRole = '',
  operationalNotes = [],
) => {
  const ganttConfig = {};

  // Get list of unique aircrafts sorted by airline, fleettype, and aircraft
  const uniqueAircrafts = getUniqueAircraftList(flightLegs, groundEventsData);
  const sortedUniqueAircrafts = getSortedAircraftList(uniqueAircrafts, sortedFleetType, operatingAirlineOfRole);

  // initialize each aircraft object into the gantt config
  sortedUniqueAircrafts.forEach((uniqueAircraft) => {
    ganttConfig[uniqueAircraft.aircraft] = {
      aircraft: uniqueAircraft.aircraft,
      airline: uniqueAircraft.airline,
      // TODO actually refers to subfleet type, need to rename
      fleetType: uniqueAircraft.fleetType,
      totalRows: 1, // total rows in this aircraft's line of flight
      scheduled: [], // list of scheduled puck configs in the line of flight
      canceled: [], // list of canceled puck configs in the line of flight
    };
  });

  // map flight legs to puck configs
  flightLegs.forEach((flight) => {
    const puckConfig = mapFlattenedFlightLegToPuckConfig(flight, operationalNotes);

    const aircraftConfig = ganttConfig[flight.aircraft];
    flight.irropsCode === IrropsCode.CANCELLED_FLIGHT
      ? aircraftConfig.canceled.push(puckConfig)
      : aircraftConfig.scheduled.push(puckConfig);
  });

  // map ground events to puck configs
  Object.keys(groundEventsData).forEach((aircraft) => {
    const aircraftConfig = ganttConfig[aircraft];
    groundEventsData[aircraft].forEach((ge) => {
      const puckConfig = mapGroundEventToPuckConfig(ge, aircraft);
      if (puckConfig !== null) {
        aircraftConfig.scheduled.push(puckConfig);
      }
    });
  });

  // row level calculations
  sortedUniqueAircrafts.forEach((uniqueAircraft) => {
    const lineOfFlight = ganttConfig[uniqueAircraft.aircraft];
    setLineOfFlightRowLevels(lineOfFlight);
  });

  return ganttConfig;
};

/**
 * Updates the lineOfFlight.totalRows and each flight puck's rowLevel property
 * that's used for rendering multi-level lines of flight when pucks have overlap
 * @param {*} lineOfFlight
 */
export const setLineOfFlightRowLevels = (lineOfFlight) => {
  const scheduledPucks = lineOfFlight.scheduled;
  const canceledPucks = lineOfFlight.canceled;

  // sort each scheduled puck config by departure or start
  scheduledPucks.sort((a, b) => {
    const time1 =
      a.puckType === PuckType.FLIGHT
        ? getDatetimeUtc(a.flightPuckData.departure)
        : getDatetimeUtc(a.groundPuckData.start);
    const time2 =
      b.puckType === PuckType.FLIGHT
        ? getDatetimeUtc(b.flightPuckData.departure)
        : getDatetimeUtc(b.groundPuckData.start);
    if (time1.isBefore(time2)) return -1;
    if (time1.isSame(time2)) return 0;
    return 1;
  });

  // sort each canceled puck config
  canceledPucks.sort((a, b) => {
    const time1 = getDatetimeUtc(a.flightPuckData.departure);
    const time2 = getDatetimeUtc(b.flightPuckData.departure);
    if (time1.isBefore(time2)) return -1;
    if (time1.isSame(time2)) return 0;
    return 1;
  });

  // row calculations for scheduled pucks
  let rowLevel = 1;
  let prev = null;
  scheduledPucks.forEach((puck) => {
    if (prev && checkFlightOverlap(getPuckData(prev), getPuckData(puck))) {
      // this puck overlaps and goes to the next row level
      rowLevel++;
    }
    puck.rowLevel = rowLevel;
    prev = puck;
  });

  // row calculations for canceled pucks
  if (scheduledPucks.length && canceledPucks.length) {
    // start a new row level for canceled pucks to be below scheduled pucks
    rowLevel++;
  }
  prev = null;
  canceledPucks.forEach((puck) => {
    if (prev && checkFlightOverlap(getPuckData(prev), getPuckData(puck))) {
      // this puck overlaps and goes to the next row level
      rowLevel++;
    }
    puck.rowLevel = rowLevel;
    prev = puck;
  });

  // set the total rows for this line of flight
  lineOfFlight.totalRows = rowLevel;
};

export const getPuckDuration = (flightLeg) => {
  const departure = getDatetimeUtc(flightLeg.departure);
  const arrival = getDatetimeUtc(flightLeg.arrival);
  return { departure, arrival, puckDuration: getTimeDifference(departure, arrival, 'minutes') };
};

/**
 * @description Gets the block separation details to be rendered in the puck.
 * @param {Array} flightLeg - The flight leg object
 * @returns {Object}
 */
const getPuckRenderStat = (flightLeg) => {
  const { departure, arrival, puckDuration } = getPuckDuration(flightLeg);

  const renderStat = {
    durationBetweenDepartureAndOff: 0,
    durationBetweenOnAndArrival: 0,
    puckDuration: puckDuration,
    leftBlockStyle: '',
    rightBlockStyle: '',
    depIndicatorLength: 0,
    arrIndicatorLength: 0,
  };

  if (
    flightLeg.irropsCode &&
    (flightLeg.irropsCode === IrropsCode.RETURN_TO_GATE || flightLeg.irropsCode === IrropsCode.CANCELLED_FLIGHT)
  ) {
    return renderStat;
  }

  const off = flightLeg.off === null ? getDatetimeUtc(flightLeg.etoff) : getDatetimeUtc(flightLeg.off);
  const on = flightLeg.on === null ? getDatetimeUtc(flightLeg.eton) : getDatetimeUtc(flightLeg.on);

  //Get phase of flight
  const flightPhase = getPhaseOfFlight(flightLeg);

  //width calculation of left block of the puck
  //If the Departure time is greater than OFF time, then that is data error. So, in this case, we do not display block.
  const offMinusDeparture = getTimeDifference(departure, off, 'minutes');
  const durationBetweenDepartureAndOff = offMinusDeparture > 0 ? offMinusDeparture : 0;

  //width  calculation of right block of the puck
  //If the ON time is greater than Arrival time, then that is data error. So, in this case, we do not display block.
  const arrivalMinusOn = getTimeDifference(on, arrival, 'minutes');
  const durationBetweenOnAndArrival = arrivalMinusOn > 0 ? arrivalMinusOn : 0;

  const leftBlockStyle = getLeftBlockStyle(flightPhase);
  const rightBlockStyle = getRightBlockStyle(flightPhase);

  renderStat.durationBetweenDepartureAndOff = durationBetweenDepartureAndOff;
  renderStat.durationBetweenOnAndArrival = durationBetweenOnAndArrival;
  renderStat.leftBlockStyle = durationBetweenDepartureAndOff > 0 ? leftBlockStyle : '';
  renderStat.rightBlockStyle = durationBetweenOnAndArrival > 0 ? rightBlockStyle : '';

  return renderStat;
};

/**
 * @description Gets the length of early/late arrival/departure lines
 * @param {Object} flightLeg - The flight leg object
 * @returns {Object}
 */
export const getPuckArrivalDepartureLength = (flightLeg) => {
  const departure = getDatetimeUtc(flightLeg.departure);
  const arrival = getDatetimeUtc(flightLeg.arrival);

  const lengths = {};

  // Do not display early/delay indicator for 'air turn back' flights
  if (
    flightLeg.irropsCode &&
    (flightLeg.irropsCode === IrropsCode.AIR_TURN_BACK ||
      flightLeg.irropsCode === IrropsCode.RETURN_TO_GATE ||
      flightLeg.irropsCode === IrropsCode.CANCELLED_FLIGHT)
  ) {
    return { depIndicatorLength: 0, arrIndicatorLength: 0 };
  }

  /*Calculate early/delay time difference in minutes on arrival & departure. Difference < 0 indicates early, > 0 indicates delay*/
  //Deviation of ETD/ATD from STD
  lengths.depIndicatorLength = flightLeg.std ? getTimeDifference(flightLeg.std, departure, 'minutes') : 0;
  //Deviation of ETA/ATA from STA
  lengths.arrIndicatorLength = flightLeg.sta ? getTimeDifference(flightLeg.sta, arrival, 'minutes') : 0;
  return lengths;
};

/**
 * @param {*} puckConfig
 * @returns gets data value based on if we are using AirtrakManagerApi or not
 */
export const getPuckData = (puckConfig) => {
  if (puckConfig.flightPuckData === null) {
    return puckConfig.groundPuckData;
  }
  return puckConfig.flightPuckData;
};

/**
 * @description Check for an overlap in flight times between arrival of the previous flight leg and departure of the next flight leg.
 * @param {object} previousFlightLeg - The arriving flight.
 * @param {object} nextFlightLeg - The departing flight.
 */
const checkFlightOverlap = (previousFlightLeg, nextFlightLeg) => {
  // Check for null/undefined parameters
  if (!previousFlightLeg || !nextFlightLeg) return false;

  let prevFlightArrivalTimeStamp = previousFlightLeg.hasOwnProperty('flightLegKey')
    ? getDatetimeUtc(previousFlightLeg.arrival)
    : getDatetimeUtc(previousFlightLeg.end);

  let nextFlightDepartureTimeStamp = nextFlightLeg.hasOwnProperty('flightLegKey')
    ? getDatetimeUtc(nextFlightLeg.departure)
    : getDatetimeUtc(nextFlightLeg.start);

  return nextFlightDepartureTimeStamp.format('X') < prevFlightArrivalTimeStamp.format('X');
};

export {
  checkFlightOverlap,
  getPuckRenderStat,
  getLegacyGanttConfig,
  mapFlattenedFlightLegToPuckConfig,
  mapGroundEventToPuckConfig,
};

/**
 * Returns the left block style string for a given flightPhase enum
 * @param {flightPhase} flightPhase
 * NOTE: type casting the flightPhase to fulfill sonarqube "equality" bug check
 */
export const getLeftBlockStyle = (flightPhase) => {
  switch (Number(flightPhase)) {
    case Number(PhaseOfFlight.PRE_FLIGHT):
    case Number(PhaseOfFlight.IN):
    case Number(PhaseOfFlight.OFF_ONLY):
    case Number(PhaseOfFlight.ON_ONLY):
    case Number(PhaseOfFlight.IN_ONLY):
    case Number(PhaseOfFlight.OFF_ON_ONLY):
    case Number(PhaseOfFlight.ON_IN_ONLY):
      return 'left-block default';
    default:
      return 'left-block highlight';
  }
};

/**
 * Returns the right block style string for a given flightPhase enum
 * @param {flightPhase} flightPhase
 * NOTE: type casting the flightPhase to fulfill sonarqube "equality" bug check
 */
export const getRightBlockStyle = (flightPhase) => {
  switch (Number(flightPhase)) {
    case Number(PhaseOfFlight.ON):
    case Number(PhaseOfFlight.ON_ONLY):
    case Number(PhaseOfFlight.OFF_ON_ONLY):
    case Number(PhaseOfFlight.OUT_ON_ONLY):
      return 'right-block highlight';
    default:
      return 'right-block default';
  }
};

/**
 * @description Provides the CSS Class Name for the selected Gantt scale settings
 * @param {string} - scaleValue, PuckSize enum
 * */
export const getGanttScaleCssClassName = (scaleValue) => {
  // CSS class name prefix value.
  const ganttScaleCssClassNamePrefix = 'gantt-scale-';
  // Default scale to use if we don't know what to use.
  const defaultGanttScaleValue = PuckSize.X_LARGE;

  // Use default if we don't have a proper value.
  if (scaleValue == null || typeof scaleValue === 'undefined') {
    scaleValue = defaultGanttScaleValue;
  }

  return `${ganttScaleCssClassNamePrefix}${scaleValue}`;
};

/**
 * @description Returns a HEX color code for aircraft label based on the business rules styles as defined in the aircraftLabelStyle parameter
 * @param {object} aircraftInfo - Aircraft object with infos - aircraft, fleet type and operating airline
 * @param {object} aircraftLabelStyle - Style object with default color, fleet type color and specific aircraft color
 */
export const getAircraftLabelColor = (aircraftInfo, aircraftLabelStyle) => {
  if (!aircraftInfo || !aircraftLabelStyle || !aircraftLabelStyle.aircraftColors || !aircraftLabelStyle.fleetTypeColors)
    return null;

  let specificAircraft = aircraftLabelStyle.aircraftColors.find(
    (a) => a.aircraftRegistration && a.aircraftRegistration === aircraftInfo.aircraft,
  );

  if (specificAircraft && specificAircraft.color) {
    return specificAircraft.color;
  }

  if (aircraftInfo.fleetType && aircraftInfo.airline) {
    let fleet = aircraftLabelStyle.fleetTypeColors.find(
      (f) =>
        f.fleetType &&
        f.operatingAirline &&
        f.fleetType === aircraftInfo.fleetType &&
        f.operatingAirline === aircraftInfo.airline,
    );

    if (fleet && fleet.color) {
      return fleet.color;
    }
  }

  if (aircraftLabelStyle.defaultColor) {
    return aircraftLabelStyle.defaultColor;
  }

  return null;
};

/**
 * @description Returns a HEX color code for station label based on the business rules styles as defined in the stationLabelStyle parameter
 * @param {string} station - station name
 * @param {object} stationLabelStyle - Style object with default and specific station color
 */
export const getStationLabelColor = (station, stationLabelStyle) => {
  if (!station || !stationLabelStyle || !stationLabelStyle.hubAirportColors) return null;

  let hubAirportColor = stationLabelStyle.hubAirportColors.find((a) => a.airport && a.airport === station);

  if (hubAirportColor && hubAirportColor.color) {
    return hubAirportColor.color;
  }

  if (stationLabelStyle.defaultColor) {
    return stationLabelStyle.defaultColor;
  }

  return null;
};

/**
 * Returns the datetime string that the gantt timeline should start at
 * while also considering the flight with the earliest departure.
 * Also pads 1 extra hour to accomodate a station label to the left of the first puck.
 *
 * The date time is either:
 * - The initialStartDate at 00:00 w/ 1 hour padding => initialStartDate-1 at 23:00
 * - The earliest departure hour w/o minutes (so 22:50 => 22:00) w/ 1 hour padding => 21:00
 * @param {string} initialStartDate - initial start date for timeline
 * @param {Object} ganttConfig - gantt config object returned from getLegacyGanttConfig
 * @returns - ISO formatted date string of when the timeline starts
 */
export const getTimelineStartDateTime = (initialStartDate, ganttConfig) => {
  let timelineStartDateTime = formatDateTime(dayjs.tz(initialStartDate, TimeZones.PDT));

  for (const aircraftKey in ganttConfig) {
    const lineOfFlight = ganttConfig[aircraftKey];

    if (lineOfFlight.scheduled.length) {
      // Pucks are sorted by departure, so we only need to check the first puck
      const lastPuck = lineOfFlight.scheduled[0];
      const puckData = getPuckData(lastPuck);

      if (lastPuck.puckType === PuckType.FLIGHT) {
        // Only extend the gantt for flights, not ground pucks
        const departure = formatDateTime(puckData.departure);
        if (datetimeIsBefore(departure, timelineStartDateTime)) {
          timelineStartDateTime = departure;
        }
      }
    }

    if ((lineOfFlight.canceled || []).length) {
      const lastPuck = lineOfFlight.canceled[0];
      const puckData = getPuckData(lastPuck);
      const departure = formatDateTime(puckData.departure);
      if (datetimeIsBefore(departure, timelineStartDateTime)) {
        timelineStartDateTime = departure;
      }
    }
  }

  const dayJsDateTime = dayjs.utc(timelineStartDateTime).startOf('hour').subtract(1, 'hour');
  timelineStartDateTime = formatDateTime(dayJsDateTime, DateFormat.ISO);

  return timelineStartDateTime;
};

/**
 * Returns the datetime string that the gantt timeline should end at
 * while also considering the flight with the latest arrival.
 * Also pads 1 extra hour to accomodate a station label to the right of the last puck.
 *
 * The date time is either:
 * - The initialEndDate+1 at 02:00
 *   - Returns next day to capture full 24-hr timespan
 *     e.g. If start and end filter = 11/09, the end datetime should be 11/10
 * - The latest flight arrival hour rounded up (so 22:50 => 23:00) w/ 1 hour padding => 00:00
 * @param {string} initialEndDate - initial end date for timeline
 * @param {Object} ganttConfig - gantt config object returned from getLegacyGanttConfig
 * @returns - ISO formatted date string of when the timeline ends
 */
export const getTimelineEndDateTime = (initialEndDate, ganttConfig) => {
  // Set to +1 UTC day at 00:00 since it needs to be the end of the date.
  let timelineEndDateTime = dayjs.tz(initialEndDate, TimeZones.PDT).startOf('day').subtract(1, 'minute');

  for (const aircraftKey in ganttConfig) {
    const lineOfFlight = ganttConfig[aircraftKey];

    if (lineOfFlight.scheduled.length) {
      // Pucks are sorted by departure, so we only need to check the last puck
      const lastPuck = lineOfFlight.scheduled[lineOfFlight.scheduled.length - 1];
      const puckData = getPuckData(lastPuck);
      if (lastPuck.puckType === PuckType.FLIGHT) {
        // Only extend the gantt for flights, not ground pucks
        const arrival = formatDateTime(puckData.arrival);
        if (datetimeIsBefore(timelineEndDateTime, arrival)) {
          timelineEndDateTime = arrival;
        }
      }
    }
    if ((lineOfFlight.canceled || []).length) {
      const lastPuck = lineOfFlight.canceled[0];
      const puckData = getPuckData(lastPuck);
      const arrival = formatDateTime(puckData.arrival);
      if (datetimeIsBefore(timelineEndDateTime, arrival)) {
        timelineEndDateTime = arrival;
      }
    }
  }

  // math.ceiling the end time
  // add 1 hour for extra padding of the station label needed
  // i.e. latest arrival time = 23:50
  //      +1 hour to round to capture end of the puck = 00:00
  //      +1 hour to capture station label = 01:00
  const dayJsDateTime = dayjs.utc(timelineEndDateTime).startOf('hour').add(2, 'hour');
  timelineEndDateTime = formatDateTime(dayJsDateTime, DateFormat.ISO);

  return timelineEndDateTime;
};

/**
 * Returns list of class names for this flight puck
 * @param {Array} classNames initial classNames for puck
 * @param {Object} data flight leg data
 * @param {boolean} selected flight is selected
 * @param {boolean} isPaneOpen pane is open
 * @returns list of class names
 */
export const getFlightPuckClassNames = (
  classNames,
  data,
  selected,
  isPaneOpen,
  isDoubleClicked = false,
  isShortTurnFlag = false,
  equipmentChangeFlag = false,
) => {
  let flightPuckClassNames = classNames;

  const isCancelled = data.irropsCode === IrropsCode.CANCELLED_FLIGHT;
  const isShortTurnPresent = isShortTurnFlag && data.hasShortTurnAlert;

  if (isCancelled) {
    flightPuckClassNames.push('cancelled');
  }

  if (isPaneOpen) {
    flightPuckClassNames.push('selectable');
    if (selected) {
      flightPuckClassNames.push('selected');
    }
  }

  if (isDoubleClicked && !isCancelled) {
    flightPuckClassNames.push('selected-group');
  }

  if (data.isSwapFlightLeg) {
    flightPuckClassNames.push('swap-flight-puck');
  } else {
    let phaseClass = getFlightPhaseStyle(data, true);
    flightPuckClassNames.push(phaseClass);
  }

  // Puck is Red when departure is delayed...should be overridden by watch or shortturn
  if (
    isDepartureDelayStatus(
      isCancelled,
      data.isInfoBy,
      data.isWatchFlight,
      isShortTurnPresent,
      datetimeIsBefore(data.std, data.etd),
    )
  ) {
    flightPuckClassNames.push('departure-delay');
  }
  // Puck is Purple when short turn alert is present...should be overridden by watch or infoby
  if (isShortTurnStatus(isCancelled, data.isInfoBy, data.isWatchFlight, isShortTurnPresent)) {
    flightPuckClassNames.push('shortturn-flight');
  }
  // Puck is Pink when watch is present
  if (isWatchFlightStatus(isCancelled, data.isWatchFlight)) {
    flightPuckClassNames.push('watch-flight');
  }
  // Puck is Mustard when delay info by is present
  if (isInfoByStatus(data.isWatchFlight, data.isInfoBy)) {
    flightPuckClassNames.push('infoby-flight');
  }
  // Puck is Orange when equipment change is present
  if (
    isEquipmentChangeStatus(
      isShortTurnPresent,
      equipmentChangeFlag,
      data.isEquipmentChange,
      data.isEquipmentChangeAcknowledgment,
    )
  ) {
    flightPuckClassNames.push('equipmentchange-flight');
  }
  // Puck is Lime Green when tailbreak is present
  if (isTailBreakStatus(isShortTurnPresent, data.hasTailBreak)) {
    flightPuckClassNames.push('tailbreak-flight');
  }

  return flightPuckClassNames.join(' ');
};

/**
 * Checks if delay infoby status should be applied
 * @param {Boolean} isWatchPresent
 * @param {Boolean} isInfoByPresent
 * @returns Boolean
 */
export const isInfoByStatus = (isWatchPresent, isInfoByPresent) => {
  return !isWatchPresent && isInfoByPresent;
};

/**
 * Checks if tailbreak status should be applied
 * @param {Boolean} isShortTurnPresent
 * @param {Boolean} hasTailBreak
 * @returns Boolean
 */
export const isTailBreakStatus = (isShortTurnPresent, hasTailBreak) => {
  return !isShortTurnPresent && hasTailBreak;
};

/**
 * Checks if equipment change status should be applied
 * @param {Boolean} isShortTurnPresent
 * @param {Boolean} equipmentChangeFlag
 * @param {Boolean} isEquipmentChange
 * @param {Boolean} isEquipmentChangeAcknowledgment
 * @returns Boolean
 */
export const isEquipmentChangeStatus = (
  isShortTurnPresent,
  equipmentChangeFlag,
  isEquipmentChange,
  isEquipmentChangeAcknowledgment,
) => {
  return !isShortTurnPresent && equipmentChangeFlag && isEquipmentChange && !isEquipmentChangeAcknowledgment;
};

/**
 * Checks if departure delay status should be applied
 * @param {Boolean} isCancelled
 * @param {Boolean} isInfoByPresent
 * @param {Boolean} isWatchPresent
 * @param {Boolean} isShortTurnPresent
 * @param {Boolean} isDateTimeBefore
 * @returns Boolean
 */
export const isDepartureDelayStatus = (
  isCancelled,
  isInfoByPresent,
  isWatchPresent,
  isShortTurnPresent,
  isDateTimeBefore,
) => {
  return !isCancelled && !isInfoByPresent && !isWatchPresent && !isShortTurnPresent && isDateTimeBefore;
};

/**
 * Checks if shortturn status should be applied
 * @param {Boolean} isCancelled
 * @param {Boolean} isInfoByPresent
 * @param {Boolean} isWatchPresent
 * @param {Boolean} isShortTurnPresent
 * @returns Boolean
 */
export const isShortTurnStatus = (isCancelled, isInfoByPresent, isWatchPresent, isShortTurnPresent) => {
  return !isCancelled && !isInfoByPresent && !isWatchPresent && isShortTurnPresent;
};

/**
 * Checks if watch flight status should be applied
 * @param {Boolean} isCancelled
 * @param {Boolean} isWatchPresent
 * @returns Boolean
 */
export const isWatchFlightStatus = (isCancelled, isWatchPresent) => {
  return !isCancelled && isWatchPresent;
};

/**
 * Intersection Observer function that passes data from entry to onDateTimeChanged
 * @param {Array} entries - Array of entry objects passed from observer
 * @param {Function} onDateTimeChanged - Function to call, passing in date and type from observer
 */
export const onTimeChanged = (entries, onDateTimeChanged, containerRef) => {
  entries.forEach((entry) => {
    const date = entry.target.dataset.date;
    if (date) {
      if (entry.isIntersecting && entry.intersectionRect.left < entry.rootBounds.left + 30) {
        onDateTimeChanged({ type: 'start', value: dayjs.utc(date).subtract(1, 'hour') });
      } else if (entry.isIntersecting && entry.intersectionRect.right > entry.rootBounds.right - 30) {
        onDateTimeChanged({ type: 'end', value: dayjs.utc(date).add(1, 'hour') });
      }
    }
  });
};

/**
 * Returns list of start and end times for each row for each aircraft in ganttConfig
 * @param {Object} ganttConfig - gantt config with puck data
 * @param {string} startDate - start date of gantt timeline
 * @param {string} endDate - end date of gantt timeline
 * @returns list of start and end times for each row for each aircraft in ganttConfig
 */
export const getRowTimes = (ganttConfig, startDate, endDate) => {
  const updatedRowTimes = {};
  Object.keys(ganttConfig).forEach((aircraft) => {
    const lineOfFlightConfig = ganttConfig[aircraft];
    const scheduledPucks = lineOfFlightConfig.scheduled;
    const canceledPucks = lineOfFlightConfig.canceled;
    const totalRows = lineOfFlightConfig.totalRows;
    if (totalRows === 1) {
      updatedRowTimes[aircraft] = [
        {
          startTime: startDate,
          endTime: endDate,
        },
      ];
      return;
    }
    for (let i = 1; i <= totalRows; i++) {
      const firstPuckIndex = scheduledPucks.findIndex((puck) => puck.rowLevel === i);
      let lastPuckIndex = -1;
      for (let j = scheduledPucks.length - 1; j > -1; j--) {
        if (scheduledPucks[j].rowLevel === i) {
          lastPuckIndex = j;
          break;
        }
      }
      const firstCanceledIndex = canceledPucks.findIndex((puck) => {
        return puck.rowLevel === i;
      });
      let lastCanceledIndex = -1;
      for (let j = canceledPucks.length - 1; j > -1; j--) {
        if (canceledPucks[j].rowLevel === i) {
          lastCanceledIndex = j;
          break;
        }
      }
      let firstPuck;
      if (firstPuckIndex === -1 && firstCanceledIndex === -1) {
        return;
      } else if (firstPuckIndex === -1 && firstCanceledIndex !== -1) {
        firstPuck = canceledPucks[firstCanceledIndex];
      } else if (firstPuckIndex !== -1 && firstCanceledIndex === -1) {
        firstPuck = scheduledPucks[firstPuckIndex];
      } else {
        firstPuck =
          firstPuckIndex < firstCanceledIndex ? scheduledPucks[firstPuckIndex] : canceledPucks[firstCanceledIndex];
      }

      let lastPuck;
      if (lastPuckIndex === -1 && lastCanceledIndex === -1) {
        return;
      } else if (lastPuckIndex === -1 && lastCanceledIndex !== -1) {
        lastPuck = canceledPucks[lastCanceledIndex];
      } else if (lastPuckIndex !== -1 && lastCanceledIndex === -1) {
        lastPuck = scheduledPucks[lastPuckIndex];
      } else {
        lastPuck = lastPuckIndex > lastCanceledIndex ? scheduledPucks[lastPuckIndex] : canceledPucks[lastCanceledIndex];
      }
      if (firstPuck && lastPuck) {
        let startTime = firstPuck.flightPuckData ? firstPuck.flightPuckData.departure : firstPuck.groundPuckData.start;
        if (startTime[startTime.length - 1] !== 'Z') {
          startTime += 'Z';
        }
        let endTime = lastPuck.flightPuckData ? lastPuck.flightPuckData.arrival : lastPuck.groundPuckData.end;
        if (endTime[endTime.length - 1] !== 'Z') {
          endTime += 'Z';
        }
        const rowTime = {
          startTime: startTime,
          endTime: endTime,
        };
        updatedRowTimes[aircraft] = [...(updatedRowTimes[aircraft] ? updatedRowTimes[aircraft] : []), rowTime];
      }
    }
  });
  return updatedRowTimes;
};

/**
 * Gets line height for a Line of Flight
 * @param {array} showRows - array of booleans representing rows to show
 * @param {object} ganttConfig - Current aircrafts gantt config
 * @param {boolean} collapsingRowsFlag - Whether or not collapsing rows is enabled
 * @returns {int} - number of rows in Line of Flight
 */
export const getLineHeight = (showRows, ganttConfig, collapsingRowsFlag) => {
  if (collapsingRowsFlag && showRows) {
    const totalRows = showRows.reduce((acc, val) => (val ? acc + 1 : acc), 0);
    return totalRows > 0 ? totalRows : 1;
  } else {
    return ganttConfig?.totalRows;
  }
};

/**
 * Checks if the x and y coordinates are within the bounds of the gantt chart
 * @param {int} x - x position of mouse click
 * @param {int} y - y position of mouse click
 * @param {Element} container - gantt chart container
 * @returns {boolean} - true if the x and y coordinates are within the bounds of the gantt chart
 */
export const checkWithinBounds = (x, y, container) => {
  if (container) {
    const aircraftAxisWidth = container.querySelector('.aircraft-axis')?.clientWidth || 76;
    const { left, top } = container.getBoundingClientRect();
    const { clientWidth: containerWidth, clientHeight: containerHeight } = container;
    return x >= left + aircraftAxisWidth && x <= left + containerWidth && y >= top && y <= top + containerHeight;
  }
  return false;
};
