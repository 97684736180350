import appSettings from '../../appSettings';
import { getBaseUrl } from '../../lib/utils';
import { getApiErrorMessage, logError } from '../../lib/appInsightsUtils';
import { UserManager, Log, WebStorageStateStore } from 'oidc-client-ts';
import {
  accessTokenExpiredCallback,
  accessTokenExpiringCallback,
  silentRefreshErrorCallback,
} from './authenticationServiceCallbacks';

const moduleName = 'authenticationService';

const baseUrl = getBaseUrl();
const IDENTITY_CONFIG = {
  authority: appSettings.OIDC_PROVIDER,
  client_id: appSettings.OIDC_CLIENT_ID,
  redirect_uri: `${baseUrl}${appSettings.OIDC_REDIRECT_ROUTE}`,
  post_logout_redirect_uri: `${baseUrl}${appSettings.OIDC_REDIRECT_ROUTE}`,
  popup_redirect_uri: `${baseUrl}${appSettings.OIDC_REDIRECT_ROUTE}`,
  response_type: 'code',
  scope: 'openid profile email',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTimeInSeconds: 300,
  validateSubOnSilentRenew: false,
  includeIdTokenInSilentRenew: true,
  monitorSession: true,
  loadUserInfo: true,
};

// Configure logger to using window console and set minimum level.
Log.setLogger(console);
Log.setLevel(Log?.WARN);

// Set up UserManager
const userManager = new UserManager(IDENTITY_CONFIG);

// Register functions for these events.
userManager.events.addAccessTokenExpiring(accessTokenExpiringCallback);
userManager.events.addSilentRenewError(silentRefreshErrorCallback);

export const signinRedirectCallback = async () => {
  return await userManager.signinRedirectCallback();
};

export const signOut = async () => {
  try {
    const metadataService = userManager?._client.metadataService;
    const metadata = await metadataService.getMetadata();
    if (!metadata.end_session_endpoint) {
      // pingfed metadata doesn't return an end_session_endpoint
      userManager._client.metadataService._metadata = {
        end_session_endpoint: appSettings.OIDC_END_SESSION_ENDPOINT,
        ...metadata,
      };
    }

    await userManager.signoutRedirect();
  } catch (error) {
    // the message we will use for this event.
    userManager.getUser().then((user) => {
      logError(getApiErrorMessage(error), moduleName, 'signOut', {
        Email: user?.profile?.email,
      });
    });
    console.error(error);
  }
};

export const accessTokenExpiredSignInCallback = async () => {
  accessTokenExpiredCallback(userManager);
  localStorage.clear();
  await signinRedirect();
};

// Register function for AccessTokenExpired event, to signout user.
userManager.events.addAccessTokenExpired(accessTokenExpiredSignInCallback);

export const isAuthenticated = async () => {
  const user = await userManager.getUser();
  return user && !user.expired;
};

export const signinRedirect = async () => {
  await userManager.signinRedirect();
};

export const getUser = async () => {
  return await userManager.getUser();
};

export const getAccessToken = async () => {
  const user = await userManager.getUser();
  return user?.access_token;
};
