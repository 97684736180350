import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '../../Shared/Button/Button';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import './AddFlightModal.css';
import Modal from '../../Shared/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextAreaInput from '../../Shared/Inputs/TextAreaInput/TextAreaInput';
import BrickContainer from '../../Shared/BrickContainer/BrickContainer';
import AirportCodeInput from '../../Shared/AirportCodeInput/AirportCodeInput';
import AircraftSearchableDropdown from '../../Shared/Inputs/AircraftSearchableDropdown/AircraftSearchableDropdown';
import TimeInput from '../../Shared/Inputs/TimeInput/TimeInput';
import { useAirportsQuery } from '../../../hooks/useAirportsQuery/useAirportsQuery';
import { useAircraftBySubfleetTypeQuery } from '../../../hooks/useAircraftBySubfleetType/useAircraftBySubfleetTypeQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faChevronDown, faChevronUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IrropsReasons from '../../Shared/IrropsReasons/IrropsReasons';
import SearchableDropdown from '../../Shared/Inputs/SearchableDropdown/SearchableDropdown';
import { focusNextElement, smartSearch, uuidv4 } from '../../../lib/utils';
import { headCheckFlightExists, getScheduledOperatingDateUTC } from '../../../services/apiClient/flightsApi/flightsApi';
import {
  getTurnTimesV2,
  getBlockMinutes,
} from '../../../services/apiClient/flightReferenceTimesApi/flightReferenceTimesApi';
import { useRoleAssignmentContext } from '../../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import dayjs from 'dayjs';
import {
  getFleetTypes,
  getAircraftDetails,
  getIsTailNumberAvailable,
} from '../../../services/apiClient/aircraftInformationApi/aircraftInformationApi';
import {
  IrropsCode,
  WarningTypes,
  TimeZonePreference,
  KeyCodes,
  SubFleetFilter,
  Treatment,
  EntitlementNames,
} from '../../../lib/constants';
import { irregularOps } from '../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import { isNullOrWhitespace, isNullOrNotNumber, getBodyWithSessionId } from '../../../lib/irropUtils';
import { deleteUserAssignments, postUserAssignments } from '../../../services/apiClient/assignmentsApi/assignmentsApi';
import {
  isStationStr,
  canCheckFlightNumberAvailability,
  updateLegsProperties,
  ChangedField,
  updateStateValues,
  WarningMessages,
  getUpdatedETDValue,
  getUpdatedETAValue,
  getScheduledOperatingDate,
  getStationsBlockMinutes,
  getCurretEtdZoneTime,
  getTimeDiffHours,
} from './AddFlightUtils';
import { useAirportsContext } from '../../../contexts/AirportsContext/AirportsContext';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useAircraftList } from '../../../hooks/useAircraftList/useAircraftList';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { useFilterSearchDatesStore } from '../../../hooks/useFilterStore/useFilterStore';
import useAuthorizationAccess from '../../../hooks/useAuthorizationAccess/useAuthorizationAccess';
import { getAirportsV2 } from '../../../services/apiClient/airportsApi/aiportsApi';
import DatePicker from '../../Shared/Inputs/DatePicker/DatePicker';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

const useStyles = makeStyles((_theme) => ({
  root: {
    flexGrow: 1,
  },
  sectionHeader: {
    fontSize: '1.4rem',
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  sectionHeaderFirst: {
    fontSize: '1.4rem',
    fontWeight: '800',
    color: 'var(--page-container-text)',
    marginTop: '1.2rem',
  },
  sectionHeaderGrid: {
    paddingBottom: '.4rem !important',
    paddingTop: '.5rem !important',
  },
  brickLabel: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: '.3rem',
  },
  brickWarningLabel: {
    color: 'var(--warning-color)',
    fontSize: '1.2rem',
    fontWeight: 'normal',
  },
  brickErrorLabel: {
    color: 'var(--error-color)',
    fontSize: '1.2rem',
    fontWeight: 'normal',
  },
  requiredStar: {
    color: 'var(--error-color)',
  },
  allControlStyles: {
    '& ': {
      height: '4.4rem',
      border: '2px solid var(--secondary-border-color) !important',
      borderRadius: '.4rem',
      fontSize: '1.4rem',
    },
    '&:hover': {
      border: '2px solid var(--secondary-hover-color) !important',
    },
    '&$focused': {
      border: '2px solid var(--secondary-hover-color) !important',
    },
  },
  controlErrorStyles: {
    '& ': {
      height: '4.4rem',
      border: '2px solid var(--error-color) !important',
      borderRadius: '.3rem',
      background: 'transparent',
    },
    '&:hover': {
      border: '2px solid var(--error-color) !important',
    },
    '&$focused': {
      border: '2px solid var(--error-color) !important',
    },
  },
  controlWarningStyles: {
    '& ': {
      height: '4.4rem',
      border: '2px solid var(--warning-color) !important',
      borderRadius: '.3rem',
      background: 'transparent',
    },
    '&:hover': {
      border: '2px solid var(--warning-color) !important',
    },
    '&$focused': {
      border: '2px solid var(--warning-color) !important',
    },
  },
  blueLink: {
    color: 'var(--interaction-link-color)',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginTop: '1rem',
    paddingBottom: '.7rem !important',
  },
  addLegLink: {
    cursor: 'pointer',
    width: '10rem',
  },
  deleteLegLink: {
    color: '#df0b37',
    fontSize: '1.4rem',
    float: 'right',
    cursor: 'pointer',
  },
  verticalMarginAuto: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  horizontalRule: {
    borderTop: '2px solid var(--secondary-border-color)',
    marginTop: '1.6rem',
    marginBottom: '.4rem',
  },
  addedFlightLeg: {
    position: 'relative',
  },
  deleteGrid: {
    position: 'absolute',
    right: '0px',
    bottom: '-4.3rem',
  },
  pbZero: {
    paddingBottom: '0px !important',
  },
  rightArrow: {
    color: 'var(--gray)',
  },
  localStationLabel: {
    fontSize: '1.2rem',
    fontWeight: '800',
    color: 'var(--secondary-text-color)',
    marginTop: '1.2rem',
  },
}));

const allPlaceholderText = 'All';
/**
 * The AddFlightModal component is build the add flight form.
 * It also provides the validations add more legs.
 * @param {Object} props -
 *  onCommit - callback func when commit add flight form.
 *  onClose - callback func when close the form.
 *  showModal - show and hide form.
 * @returns AddFlightModal component
 */

const AddFlightModalV3 = ({ onCommit, onClose, showModal = false, tzPreference = TimeZonePreference.UTC }) => {
  const formatDateOnly = 'YYYY-MM-DD';

  const classes = useStyles();

  // feature flag
  const { showFeature } = useFeatureFlag();

  // irropsRefreshFlag controls if false the page has to wait 3 sec. before refreshing Gantt or
  // if true we actively check if Irrop finished by calling headCheckFlightExists and refresh the page
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);
  const datePickerMaxDateFlag = showFeature(Treatment.DATE_PICKER_MAXDATE);

  const { roleAssignments } = useRoleAssignmentContext();
  const { trackEvent } = useAppInsightsContext();

  // useAuthorizationAccess hook
  const hasAddflightEntitlement = useAuthorizationAccess(null, [EntitlementNames.ADDFLIGHT]);

  //getScheduledOperatingDate method
  const getScheduledOperatingDateFormat = getScheduledOperatingDate();
  // #region metadata memory
  // const [scheduledOperatingDateUtc, setScheduledOperatingDateUtc] = useState(dayjs.utc().format(formatDateOnly));
  const [scheduledOperatingDate, setScheduledOperatingDate] = useState(getScheduledOperatingDateFormat);
  const { isLoading: isLoadingAirportsData, data: airportsData, error: airportsDataError } = useAirportsQuery();
  const { isLoading: isLoadingAirportDetail, getAirportDetail } = useAirportsContext();
  let operatingAirline = roleAssignments.role.operatingAirline;

  // subfleet related memory
  const [selectedFleetType, setSelectedFleetType] = useState({ key: '', text: '' });
  const [filterFleetTypes, setFilterFleetTypes] = useState([]);
  const [fleetType, setFleetType] = useState([]);
  // aircraft tail related memory
  const { startDate, endDate } = useFilterSearchDatesStore();
  const aircraftList = useAircraftList(startDate, endDate, operatingAirline);
  const aircraftListBySubfleetType = useAircraftBySubfleetTypeQuery(startDate, endDate, operatingAirline);

  const [, setShowAircraftDropdown] = useState(false);
  const [showAircraftList, setShowAircraftList] = useState(false);

  const [aircraftTailNo, setAircraftTailNo] = useState({
    value: '',
    isWarning: false,
    message: '',
    warningType: WarningTypes.NONE,
  });
  const [, setIsWarning] = useState(false);
  const [isValidTailNo, setIsValidTailNo] = useState(true);

  // flightnumber related memory
  const [flightNumber, setFlightNumber] = useState({
    value: '',
    display: '',
    isAvailable: true,
    isWarning: false,
    message: '',
  });
  // documentation related memory
  const [selectedReason, setSelectedReason] = useState({
    text: '',
    key: null,
    template: '',
    isCommentsRequired: false,
  });
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);
  const [usercomment, setUserComments] = useState('');
  // #endregion
  // #region constants
  const [minScheduledOperatingDate, setMinScheduledOperatingDate] = useState(dayjs().format(formatDateOnly));
  const maxScheduledOperatingDate = dayjs(minScheduledOperatingDate).add(6, 'day').format(formatDateOnly);
  const maxDispatchDeskValueCharacters = 3;
  const maxFlightLegs = 5;
  const maxFlightNumberCharacters = 4;
  const maxAircraftNumberCharacters = 6;
  const regexAlphaNumeric = new RegExp('^(?=[A-Za-z0-9]{0,3}$)');
  const regexFourDigitNumber = new RegExp('^(?=[1-9][0-9]{0,4}$)');
  const regexAlphaNumericAircraftTail = new RegExp('^[N](?=[A-Za-z0-9]{5}$)');
  const QX_AIRLINE = 'QX';
  const QX_DEFAULT_DISPATCH_DESK = '4';
  const maxDateProps = {};

  const [legs, setLegs] = useState([
    {
      legNum: 0,
      blockMinutes: null,
      origin: { station: '', tz: '', turnTime: null },
      destination: { station: '', tz: '', turnTime: null },
      etd: { utcValue: '', hhmm: '', tzDisplay: '', warningType: WarningTypes.NONE, message: '' },
      eta: { utcValue: '', hhmm: '', tzDisplay: '', warningType: WarningTypes.NONE, message: '' },
      dispatchDesk: operatingAirline === QX_AIRLINE ? QX_DEFAULT_DISPATCH_DESK : null,
      isExpanded: true,
    },
  ]);

  //Check feature flag for datePickerMaxDate & set propos datePicker
  if (!datePickerMaxDateFlag) {
    maxDateProps.maxDate = maxScheduledOperatingDate;
  }
  // #endregion

  // #region common methods
  /**
   * @description This method is used by Dispatch Desk field which is alphanumeric
   * @returns true or false
   */
  const checkRegexInput = (regexVal, str) => {
    return regexVal.test(str);
  };

  /**
   * @description get the leg number suffix for leg titile
   * @param {int} index leg index
   * @returns leg suffix
   */
  const getLegNumberSuffix = (i) => {
    switch (i % 10) {
      case 1:
        return 'ST';
      case 2:
        return 'ND';
      case 3:
        return 'RD';
      case 4:
        return 'TH';
      default:
        return 'TH';
    }
  };

  /**
   * @description handling aircraft dropdown close after click on ouside
   * @returns set the setShowAircraftDropdown state.
   */
  const handleClickOutside = () => {
    return setShowAircraftDropdown(false);
  };

  /**
   * @description get field value by removing the extra and special charactor.
   * @param {string} value new dispatch value
   * @param {int} maxCharAllowed max int count to allow
   * @returns new dispatch field value.
   */
  const getInputValue = (value, maxCharAllowed) => {
    const removedSpChars = value.replace(/[\W_]+/g, '');
    return removedSpChars.substring(0, maxCharAllowed);
  };
  const isTimeWarning = (estimate) => {
    return !(estimate?.warningType === WarningTypes.NONE);
  };
  // #endregion
  // #region common Calculations

  /**
   * @description gets stations timezone
   * @param {string} station
   * @returns
   */
  const getTimeZone = async (station) => {
    if (!isLoadingAirportDetail) {
      let stationConsidered = station;
      if (
        tzPreference !== TimeZonePreference.UTC &&
        tzPreference !== TimeZonePreference.STATION_LOCAL &&
        tzPreference.length === 3
      ) {
        stationConsidered = tzPreference;
      }
      const airportObject = await getAirportDetail(stationConsidered);
      if (airportObject != null) {
        return airportObject.airportTimeZone;
      }
    }
  };
  /**
   * @description gets station turntime from api call
   * @param {string} origin
   * @param {string} aircraftTailNumber
   * @param {string} inboundAirport
   * @param {string} destination
   * @returns
   */
  const getStationTurnTime = async (
    origin,
    inboundAirport,
    destination,
    aircraftTailNumber = null,
    inboundArrivalTimeUTC = null,
  ) => {
    let aircraftTail = aircraftTailNumber == null ? aircraftTailNo.value : aircraftTailNumber;
    if (isStationStr(origin) && aircraftTail != null && isStationStr(destination) && isStationStr(inboundAirport)) {
      // inboundFlightLegKey is 0 for ADD flight scenario.
      let turnTime = await getTurnTimesV2(
        operatingAirline,
        origin,
        aircraftTail,
        0,
        inboundAirport,
        destination,
        inboundArrivalTimeUTC,
      );
      if (!isNullOrNotNumber(turnTime)) {
        return turnTime;
      }
    }
    return null;
  };
  /**
   * @description gets station details including turntime and time zone of station
   * @param {int} index
   * @param {string} station
   * @param {string} aircraftTailNumber
   * @param {boolean} turnTimeNeeded
   * @returns
   */
  const getStationDetails = async (
    index,
    station,
    aircraftTailNumber = null,
    turnTimeNeeded = true,
    inboundArrivalTimeUTC = null,
  ) => {
    let turnTime = null;
    const formattedInboundArrivalTimeUTC = isNullOrWhitespace(inboundArrivalTimeUTC)
      ? null
      : dayjs(inboundArrivalTimeUTC).toISOString();

    if (turnTimeNeeded) {
      const previouLeg = legs[index > 0 ? index - 1 : index];
      turnTime = await getStationTurnTime(
        previouLeg.destination.station,
        previouLeg.origin.station,
        station,
        aircraftTailNumber,
        formattedInboundArrivalTimeUTC,
      );
    }
    let tz = await getTimeZone(station);

    return { station: station, tz: tz ?? null, turnTime: turnTime };
  };
  /**
   * @description updates isExpanded value on legs state
   * @param {number} index
   * @param {boolean} isExpanded
   */
  const setIsExpanded = (index, isExpanded = true) => {
    updateLegsState('isExpanded', index, isExpanded);
  };

  // #endregion
  // #region Input Date
  /**
   * @description handle on flight date input change.
   */
  const handleDateInput = async (dateValue) => {
    if (!dayjs.isDayjs(dateValue)) {
      return;
    }
    let localDate = dayjs(dateValue).format(formatDateOnly);
    setScheduledOperatingDate(localDate);
    if (!isNullOrWhitespace(legs[0].etd.utcValue)) {
      await updateTimeValues(0, localDate, ChangedField.ETD, legs[0].etd.hhmm, null, null);
    }
  };

  /**
   * @description update the add legs array state by property.
   * @param {string} key key name for update the add legs state.
   * @param {int} index index that object should update.
   * @param {string} value updating value.
   */
  const updateLegsState = (key, index, value) => {
    let tempLegs = updateLegsProperties(legs, key, index, value);
    setLegs(tempLegs);
  };

  // #endregion
  // #region AirportCode
  /**
   * @description handle Airport origin change
   * @param {string} values
   * @param {*} leg
   */
  const handleAirportCodeOriginChange = async (value, leg, event, target) => {
    const index = 0;
    if (value == null || value?.length !== 3) {
      let oTyping = { station: value ?? '', tz: '', turnTime: null };
      updateLegsState('origin', index, oTyping);
      updateLegsState('etd', index, {
        ...legs[index].etd,
        utcValue: '',
        tzDisplay: '',
        warningType: WarningTypes.NONE,
        message: '',
      });
      return;
    }
    let origin = await getStationDetails(index, value, null, false);
    updateLegsState('origin', index, origin);

    let blockTime = getStationsBlockMinutes(operatingAirline, origin.station, legs[index].destination?.station);
    let etd = await getUpdatedETDValue(index, legs, scheduledOperatingDate, null, origin.tz, null, null, tzPreference);
    if (etd != null) {
      let etdTzTime = getCurretEtdZoneTime(etd.utcValue, legs);
      updateLegsState('etd', index, {
        ...etd,
        warningType: etdTzTime.isETDWarning ? WarningTypes.WARNING : WarningTypes.NONE,
        message: etdTzTime.isETDWarning
          ? WarningMessages.SHOULD_BEAFTER_WARNING.replace('$time', etdTzTime.tzCurretTime)
          : '',
      });
    }
    let bt = await blockTime;
    if (bt != null) updateLegsState('blockMinutes', index, blockTime);
    let eta = await getUpdatedETAValue(index, legs, scheduledOperatingDate, etd.utcValue, null, null, bt, tzPreference);
    if (eta != null) updateLegsState('eta', index, { ...eta, warningType: WarningTypes.NONE, message: '' });
    let originStationCurrentDate = dayjs(getScheduledOperatingDateFormat.UTC).tz(origin.tz);
    if (originStationCurrentDate != minScheduledOperatingDate)
      setMinScheduledOperatingDate(originStationCurrentDate.format(formatDateOnly));
    if (event.keyCode === KeyCodes.ENTER || event.type === 'click') {
      focusNextElement(event, target, 'addflight');
    }
  };
  /**
   * Check flight available & set flight number hook
   * @param {string} scheduledOperatingDate
   * @param {number} flightNumber
   * @param {string} display
   * @param {string} legStation
   * @returns
   */
  const checkIsFlightAvailable = async (scheduledOperatingDate, flightNumber, display, legStation) => {
    let startDateUtc = `${dayjs.utc(scheduledOperatingDate).format('YYYY-MM-DD')}`;
    if (tzPreference === TimeZonePreference.STATION_LOCAL && !isNullOrWhitespace(legs[0].etd.utcValue)) {
      startDateUtc = dayjs.utc(legs[0].etd.utcValue).format('YYYY-MM-DD');
    }
    if (canCheckFlightNumberAvailability(scheduledOperatingDate, flightNumber)) {
      let isFlightAvailable = false;
      let isFlightWarning = false;
      let isFlightExists = [];

      //defoult trigger call check flight exists show warning message
      const headCallWithoutOrigin = headCheckFlightExists(
        flightNumber,
        startDateUtc,
        null,
        null,
        null,
        operatingAirline,
        null,
        false,
        false,
      );
      isFlightExists.push(headCallWithoutOrigin);

      //Origin Station not empty then trigger head call check flight exists with origin show warning message
      if (!isNullOrWhitespace(legStation)) {
        const headCallWithOrigin = headCheckFlightExists(
          flightNumber,
          startDateUtc,
          legStation,
          null,
          null,
          operatingAirline,
          null,
          false,
          false,
        );
        isFlightExists.push(headCallWithOrigin);
      }

      const flightInfoResult = await Promise.all(isFlightExists);
      for (let i = 0; i < flightInfoResult.length; i++) {
        if (flightInfoResult[i] == null || flightInfoResult[i]?.status !== 200) {
          isFlightAvailable = true;
        } else if (flightInfoResult[i]?.status == 200 && flightInfoResult.length == 1) {
          isFlightAvailable = true;
          isFlightWarning = true;
        } else {
          isFlightWarning = true;
        }
      }
      const isAvailable = isFlightAvailable && isFlightWarning == false;
      const isWarning = isFlightWarning && isFlightAvailable;
      setFlightNumber({
        value: flightNumber,
        display: display,
        isAvailable: isAvailable,
        isWarning: isWarning,
        message: WarningMessages.FLIGHT_WARNING,
      });
    }
  };

  // Destination code change
  /**
   * handles update Airport destination change
   * @param {string} value
   * @param {*} leg
   * @returns
   */
  const handleAirportCodeDestinationChange = async (value, leg, event, target) => {
    const index = getLegIndex(leg);
    let oTyping = { station: value ?? '', tz: '', turnTime: null };
    updateLegsState('destination', index, oTyping);
    updateLegsState('blockMinutes', index, null);
    updateLegsState('eta', index, {
      hhmm: '',
      utcValue: '',
      tzDisplay: '',
      warningType: WarningTypes.NONE,
      message: '',
    });
    if (value?.length < 3) {
      return;
    }
    await updateTimeValues(index, null, ChangedField.DESTINATION, null, value, null);
    focusNextElement(event, target, 'addflight');
    checkIsFlightAvailable(
      scheduledOperatingDate,
      flightNumber.value,
      flightNumber.display,
      legs[index].origin.station,
    );
    await aircraftPostValidations(aircraftTailNo.value, false);
  };
  // #endregion

  // #region ETD

  /**
   * get updated state updated based on a changed field. returns updated states updating all legs on the state based on what chagned now.
   * @param {number} index
   * @param {string} refTimeUtc
   * @param {string} changedField
   * @param {string} hhmm
   * @param {string} station
   * @param {number} addTimeMinutes
   */
  const updateTimeValues = async (
    index,
    refTimeUtc,
    changedField = ChangedField.NONE,
    hhmm = null,
    station = null,
    addTimeMinutes = null,
  ) => {
    let newLegs = await updateStateValues(
      legs,
      updateLegsProperties,
      operatingAirline,
      scheduledOperatingDate,
      index,
      refTimeUtc,
      changedField,
      hhmm,
      station,
      addTimeMinutes,
      tzPreference,
      getStationDetails,
    );
    setLegs(newLegs);
  };

  //When origin station changes, the chosen date will become the date of origin local. which changes UTC value of ETD and hence updates ETA and others
  const handleETDChange = async (value, leg) => {
    const index = getLegIndex(leg);
    updateLegsState('etd', index, {
      ...legs[index].etd,
      utcValue: '',
      hhmm: value,
      warningType: WarningTypes.NONE,
      message: '',
    });
    if (value.length < 5) {
      return;
    }
    await updateTimeValues(index, null, ChangedField.ETD, value, null, null);
    await aircraftPostValidations(aircraftTailNo.value, false);
  };
  // #endregion

  // #region ETA
  /**
   * handle ETA field change event
   * @param {string} value
   * @param {*} leg
   * @returns
   */
  const handleETAChange = async (value, leg) => {
    const index = getLegIndex(leg);
    updateLegsState('eta', index, {
      ...legs[index].eta,
      utcValue: '',
      hhmm: value,
      warningType: WarningTypes.NONE,
      message: '',
    });
    if (value.length < 5) {
      return;
    }
    await updateTimeValues(index, null, ChangedField.ETA, value, null, null);
    await aircraftPostValidations(aircraftTailNo.value, false);
  };

  // #endregion
  /**
   * update station details and ge turn time on aircraft update
   * @param {string} aircraftTailNumber
   * @returns
   */
  const aircraftChanged = async (aircraftTailNumber) => {
    if (aircraftTailNumber == null) {
      return null;
    }
    let destination = await getStationDetails(0, legs[0].destination.station, aircraftTailNumber, false);
    updateLegsState('destination', 0, { ...destination });
  };
  // #region subfleet filter
  /**
   * @description useCallback wrapper for the getFleetTypes method.
   */
  const fetchFleetTypeData = useCallback(async () => {
    let result = [];
    let fleetsArray = [];
    let fleetItems = [];
    const requiredFleet = [{ value: SubFleetFilter.ALL, name: allPlaceholderText }];
    result = await getFleetTypes(operatingAirline);
    if (result != null) {
      result.forEach((fleets) => {
        fleetsArray.push({ value: fleets.fleetType, name: `${fleets.fleetType}` });
      });
      fleetItems = [...requiredFleet, ...fleetsArray];

      setFleetType(fleetItems);
      setFilterFleetTypes(fleetItems);
    }
  }, [operatingAirline]);

  /**
   * @description add click Event Listener and remove when destory
   */
  useEffect(() => {
    fetchFleetTypeData();
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [operatingAirline, fetchFleetTypeData]);

  /**
   * @description Seleting fleettype from dropdown and autopopuate the aircraft tail numbers and sets warnings.
   * @param {string} fleetTypeKey fleetTypeKey value
   * @param {string} _fleetType fleetType value.
   */
  const handleSubfleetInputChange = async (fleetTypeKey, _fleetType, event, target) => {
    setSelectedFleetType({ key: fleetTypeKey, text: _fleetType });
    setAircraftTailNo({ ...aircraftTailNo, isWarning: false, message: '', value: '', warningType: WarningTypes.NONE });
    setShowAircraftList(true);

    focusNextElement(event, target, 'addflight');
  };

  /**
   * @description handle on change of sub fleet dropdown for filter.
   * @param {string} value search value.
   */
  const handleSubfleetOnChange = (value) => {
    const filteredItems = smartSearch(value.trim(), fleetType);
    setFilterFleetTypes(filteredItems);
    if (filteredItems.length === 1) {
      setSelectedFleetType({ key: filteredItems[0].name, text: value });
    } else {
      setSelectedFleetType({ key: '', text: value });
    }
  };
  // #endregion

  // #region Aircraft Tail Enhancement

  /**
   * @description validates the format of the Aircraft Tail no.
   * @param {string} aircraftTailNo input value in the textbox.
   * @returns true if valid else false.
   */
  const isAircraftValid = (aircraftTailNo) => {
    if (!isNullOrWhitespace(aircraftTailNo) && checkRegexInput(regexAlphaNumericAircraftTail, aircraftTailNo) === true)
      return true;
    return false;
  };

  /**
   * @description returns the list of aircraft tails based on subfleet and search criteria
   * @param {string} textInput input given by the user in the textbox
   */
  const getFilteredAircrafts = useCallback(
    (textInput) => {
      let aircraftTailList = [];
      if (selectedFleetType.key === SubFleetFilter.ALL || selectedFleetType.key === '') {
        aircraftTailList = aircraftList.map((aircraft) => {
          return {
            value: aircraft,
            name: `${aircraft}`,
          };
        });
      } else if (!isNullOrWhitespace(selectedFleetType.key)) {
        const fleetTypeFilter = aircraftListBySubfleetType[selectedFleetType.key];
        if (fleetTypeFilter !== undefined) {
          aircraftTailList = fleetTypeFilter.map((aircraft) => {
            return {
              value: aircraft,
              name: `${aircraft}`,
            };
          });
        }
      }

      if (aircraftTailList.length > 0 && textInput !== '') {
        return smartSearch(textInput, aircraftTailList);
      } else {
        return aircraftTailList;
      }
    },
    [aircraftList, aircraftListBySubfleetType, selectedFleetType.key],
  );

  /**
   * @desciption holds the aircraft tails to be displayed in the dropdown
   */

  const filteredAircrafts = useMemo(() => {
    return getFilteredAircrafts(aircraftTailNo.value);
  }, [aircraftTailNo.value, getFilteredAircrafts]);

  /**
   * @description fire all database validations on aircraft update
   * @param {string} aircraftTail
   */
  const aircraftPostValidations = async (aircraftTail, hasEtdWarning = true) => {
    //Autpopulate the fleettype in dropdown if tail no entered by user matches with api response
    // updating turntime because turn time is dependent on Aircraft Tail
    aircraftChanged(aircraftTail);
    let result = [];
    let aircraftFleetType = null;
    if (!isNullOrWhitespace(operatingAirline) && !isNullOrWhitespace(aircraftTail)) {
      result = await getAircraftDetails(operatingAirline, aircraftTail);
      if (result !== null && result.length > 0 && result[0].aircraft !== undefined) {
        aircraftFleetType = result[0].aircraft.fleetType;
        if (selectedFleetType.text !== aircraftFleetType) {
          const matchedFleetType = fleetType.find((element) => element.value === aircraftFleetType);
          // (matchedFleetType != null || matchedFleetType != "")
          !isNullOrWhitespace(matchedFleetType)
            ? setSelectedFleetType({ key: aircraftFleetType, text: aircraftFleetType })
            : setSelectedFleetType({ key: SubFleetFilter.ALL, text: allPlaceholderText });
        }
        const isExist = await isTailNuberAvailable(aircraftTail);
        setWarningsAfterTailNumberUpdate(isExist, aircraftTail, hasEtdWarning);
      } else {
        setIsValidTailNo(false);
        setAircraftTailNo({
          ...aircraftTailNo,
          value: aircraftTail,
          isWarning: true,
          message: WarningMessages.AIRCRAFT_INVALID_WARNING,
          warningType: WarningTypes.ERROR,
        });

        let hoursDiff = getTimeDiffHours(legs[0].eta.utcValue, legs[0].etd.utcValue);
        let isBlockTimeWarning = legs[0].eta.message === WarningMessages.BLOCK_TIME_WARNING.replace('$time', hoursDiff);
        updateLegsState('eta', 0, {
          ...legs[0].eta,
          warningType: isBlockTimeWarning ? WarningTypes.WARNING : WarningTypes.NONE,
          message: isBlockTimeWarning ? WarningMessages.BLOCK_TIME_WARNING.replace('$time', hoursDiff) : '',
        });
        updateLegsState('etd', 0, { ...legs[0].etd, warningType: WarningTypes.NONE, message: '' });
      }
    }
  };

  /**
   * @description handle on change of Aircraft dropdown for filter.
   * @param {string} textInput search value.
   */
  const handleAircraftTailOnChange = (textInput) => {
    let val = textInput;
    if (val != null && val.length === 5 && !/^[Nn]/.test(val)) {
      val = 'N' + val;
    }

    if (isNullOrWhitespace(val)) {
      setIsValidTailNo(true);
      setAircraftTailNo({
        ...aircraftTailNo,
        isWarning: false,
        message: '',
        value: '',
        warningType: WarningTypes.NONE,
      });
    } else {
      if (isAircraftValid(val)) {
        setIsValidTailNo(true);
        setAircraftTailNo({
          ...aircraftTailNo,
          isWarning: false,
          message: '',
          value: val,
          warningType: WarningTypes.NONE,
        });
        aircraftPostValidations(val);
      } else {
        setIsValidTailNo(false);
        setAircraftTailNo({
          ...aircraftTailNo,
          value: val,
          isWarning: true,
          message: WarningMessages.AIRCRAFT_INVALID_WARNING,
          warningType: WarningTypes.ERROR,
        });
      }
    }
  };

  /**
   * @description Handle OnSelect event of the Aircraft Dropdown.
   * @param {string} textInput Value selected
   *
   */
  const handleAircraftOnSelect = async (textInput, textValue, event, target) => {
    setShowAircraftList(false);

    let val = textInput;
    if (val.length > 0 && !/^[Nn]/.test(val)) {
      val = 'N' + val;
    }
    const aircraftTail = getInputValue(val, maxAircraftNumberCharacters);
    if (isAircraftValid(aircraftTail)) {
      setIsValidTailNo(true);
      setAircraftTailNo({
        ...aircraftTailNo,
        isWarning: false,
        message: '',
        value: aircraftTail,
        warningType: WarningTypes.NONE,
      });
    } else {
      setIsValidTailNo(false);
      setAircraftTailNo({
        ...aircraftTailNo,
        value: aircraftTail,
        isWarning: true,
        message: WarningMessages.AIRCRAFT_INVALID_WARNING,
        warningType: WarningTypes.ERROR,
      });
    }
    aircraftPostValidations(aircraftTail);
    focusNextElement(event, target, 'addflight');
  };

  // #endregion

  /**
   *
   * @param {string} aircraft
   * @returns boolean of aircraft availability
   */
  const isTailNuberAvailable = async (aircraft) => {
    let etd = legs[0].etd;
    let eta = legs[legs.length - 1].eta;
    const flightDateUtc = `${dayjs.utc(etd.utcValue, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')}`;
    const estimateOut = `${dayjs.utc(etd.utcValue, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')}`;
    const estimateIn = `${dayjs.utc(eta.utcValue, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')}`;
    const checkTailNumberExist = await getIsTailNumberAvailable(aircraft, flightDateUtc, estimateOut, estimateIn);
    return checkTailNumberExist?.isTailNumberAvailable || false;
  };

  /**
   * @description updates warning messages on fields
   * @param {boolean} hasWarning
   */
  const setWarningsAfterTailNumberUpdate = (hasWarning, textInput, hasEtdWarning = true) => {
    let hoursDiff = getTimeDiffHours(legs[0].eta.utcValue, legs[0].etd.utcValue);
    let isBlockTimeWarning = legs[0].eta.message === WarningMessages.BLOCK_TIME_WARNING.replace('$time', hoursDiff);
    setIsValidTailNo(true);
    if (hasWarning) {
      //Other ETA warnings take precedence over the estimates warning
      //since the estimates warning will also be displayed in other places
      if (!isTimeWarning(legs[0].eta) && isBlockTimeWarning) {
        updateLegsState('eta', 0, {
          ...legs[0].eta,
          warningType: WarningTypes.WARNING,
          message: WarningMessages.ESTIMATES_WARNING,
        });
      } else if (hasEtdWarning) {
        updateLegsState('etd', 0, {
          ...legs[0].etd,
          warningType: WarningTypes.WARNING,
          message: WarningMessages.ESTIMATES_WARNING,
        });
      }
      setAircraftTailNo({
        ...aircraftTailNo,
        value: textInput,
        isWarning: true,
        message: WarningMessages.AIRCRAFT_WARNING,
        warningType: WarningTypes.WARNING,
      });
      setIsWarning(true);
    } else {
      updateLegsState('eta', 0, {
        ...legs[0].eta,
        warningType: isBlockTimeWarning ? WarningTypes.WARNING : WarningTypes.NONE,
        message: isBlockTimeWarning ? WarningMessages.BLOCK_TIME_WARNING.replace('$time', hoursDiff) : '',
      });

      let etdTzTime = getCurretEtdZoneTime(legs[0].etd.utcValue, legs);
      updateLegsState('etd', 0, {
        ...legs[0].etd,
        warningType: etdTzTime.isETDWarning ? WarningTypes.WARNING : WarningTypes.NONE,
        message: etdTzTime.isETDWarning
          ? WarningMessages.SHOULD_BEAFTER_WARNING.replace('$time', etdTzTime.tzCurretTime)
          : '',
      });
      setAircraftTailNo({
        ...aircraftTailNo,
        value: textInput,
        isWarning: false,
        message: '',
        warningType: WarningTypes.NONE,
      });
      setIsWarning(false);
    }
  };
  // #endregion

  // #region flight number

  const handleFlightNumberKeyDown = async (e) => {
    focusNextElement(e, null, 'addflight');
  };

  /**
   * @description handles flight number change event
   * @param {*} e
   * @returns
   */
  const handleFlightNumberChange = async (e) => {
    if (checkRegexInput(regexFourDigitNumber, e.target.value.toString()) === true) {
      const newValue = getInputValue(e.target.value, maxFlightNumberCharacters);
      setFlightNumber({ value: newValue, display: newValue, isAvailable: true, isWarning: false, message: '' });
    } else if (isNullOrWhitespace(e?.target?.value)) {
      setFlightNumber({ value: '', display: '', isAvailable: true, isWarning: false, message: '' });
    }
  };
  /**
   * @description handles flight number blue event
   * @param {*} e
   */
  const handleFlightNumberBlur = async (e) => {
    checkIsFlightAvailable(scheduledOperatingDate, e.target.value, e.target.display, legs[0].origin.station);
  };

  // #endregion

  // #region dispatchdesk
  const handleDispatchDeskChange = (e, leg) => {
    let index = getLegIndex(leg);
    if (checkRegexInput(regexAlphaNumeric, e.target.value.toString()) === true) {
      const newValue = getInputValue(e.target.value.toUpperCase(), maxDispatchDeskValueCharacters);
      updateLegsState('dispatch', index, newValue);
    }
  };

  const handleDispatchDeskKeyDown = (e) => {
    focusNextElement(e, null, 'addflight');
  };
  // #endregion

  // #region documentation
  /**
   * @description handle on select reason in documentation section.
   */
  const handleOnSelectReason = (reason, reasonKey, commentsTemplate, commentsRequired, event, target) => {
    setSelectedReason({
      text: reason,
      key: reasonKey,
      template: commentsTemplate,
      isCommentsRequired: commentsRequired,
    });
    focusNextElement(event, target, 'addflight');
  };
  /**
   * @description handle add flight Commens input field change
   */
  const handleCommentsTextInputChange = (value) => {
    setUserComments(value);
  };
  // #endregion

  // #region form click events
  /**
   * @description get index of specific leg from add legs array.
   * @returns int (index of the leg)
   */
  const getLegIndex = (addLeg) => {
    return legs.findIndex((x) => x.legNum === addLeg.legNum);
  };
  /**
   * @description Click on expand and collapse chevron icon to expand/collapse add leg row
   * @param {object} event event object.
   */
  const handleLegExpanndCollapseClick = (e) => {
    let arrIndex = parseInt(e.target.getAttribute('arrindex'));
    let chevronType = e.target.getAttribute('chevrontype');
    let newArr = [...legs];
    switch (chevronType) {
      case 'down':
        newArr[arrIndex] = { ...newArr[arrIndex], isExpanded: false };
        break;
      case 'up':
        newArr[arrIndex] = { ...newArr[arrIndex], isExpanded: true };
        break;
      default:
        return;
    }
    setLegs(newArr);
  };
  /**
   * @description User can click on Add Leg max 5 times so restricting addLegs array index upto 4
   */
  const handleAddFlightLeg = () => {
    const legsCount = legs.length;
    const newOrigin = legs[legsCount - 1].destination;
    const currentDesk = legs[legsCount - 1].dispatchDesk;
    if (legs.length <= maxFlightLegs - 1) {
      let newlegs = [
        ...legs,
        {
          legNum: legsCount + 1,
          blockMinutes: null,
          origin: { ...newOrigin },
          destination: { station: '', tz: '', turnTime: null },
          etd: { utcValue: '', hhmm: '', tzDisplay: '', warningType: WarningTypes.NONE, message: '' },
          eta: { utcValue: '', hhmm: '', tzDisplay: '', warningType: WarningTypes.NONE, message: '' },
          dispatchDesk: currentDesk,
          isExpanded: true,
        },
      ];
      setLegs(newlegs);
    }
  };

  /**
   * @description handle delete legs and delete middle leg with refresh leg details.
   * @param {object} event  event object on delete leg
   * @param {object} leg leg object from added legs.
   * @returns
   */
  const handleDeleteLeg = async (_e, leg) => {
    let tempLegs = [...legs];
    const index = getLegIndex(leg);
    if (index === 0 || index >= tempLegs.length) {
      return;
    } else if (index === tempLegs.length - 1) {
      tempLegs = legs.filter((_item, i) => i !== index);
      tempLegs.forEach((item, i) => {
        item.legNum = i;
      });
      setLegs(tempLegs);
    } else if (index > 0) {
      let nextLegOrigin = { ...legs[index - 1].destination };
      let nextLegDestination = { ...legs[index + 1].destination };
      tempLegs[index + 1].origin = nextLegOrigin;
      let blockMinutes = await getBlockMinutes(operatingAirline, nextLegOrigin.station, nextLegDestination.station);
      updateLegsState('blockMinutes', index, blockMinutes);
      tempLegs = tempLegs.filter((_item, i) => i !== index);
      tempLegs.forEach((item, i) => {
        item.legNum = i;
      });
      let newLegs = await updateStateValues(
        tempLegs,
        updateLegsProperties,
        operatingAirline,
        scheduledOperatingDate,
        index,
        null,
        ChangedField.ORIGIN,
        null,
        nextLegOrigin.station,
        null,
        tzPreference,
        getStationDetails,
      );
      setLegs(newLegs);
    }
  };

  // #endregion

  // #region submit flight

  /**
   * @description Submit the Add Flight modal data entered by user to IrropsQueuePublisher API
   * @returns
   */
  const submitAddFlight = async () => {
    await submitAddFlightV2();
  };

  const isEtopsRequiredFromAirports = (etopsRequiredAirports, leg) => {
    return etopsRequiredAirports != null
      ? etopsRequiredAirports?.some(
          (item) => item.airportCode == leg.origin.station || item.airportCode == leg.destination.station,
        )
      : false;
  };

  const getAirportCodes = (legs) => {
    let airportCodes = [];

    for (let i = 0; i < legs.length; i++) {
      let leg = legs[i];
      if (!airportCodes.includes(leg.origin.station)) {
        airportCodes.push(leg.origin.station);
      }
      if (!airportCodes.includes(leg.destination.station)) {
        airportCodes.push(leg.destination.station);
      }
    }
    return airportCodes;
  };
  /**
   * @description Submit the Add Flight modal data entered by user to IrropsQueuePublisher API when IrropRefreshEnhancement is true
   * @returns
   */
  const submitAddFlightV2 = async () => {
    trackEvent(`Add Flight - Submit modal for flight number : ${flightNumber.value.toString()}`);

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    let result = true;
    let addFlightResult, addFlightRequestBody, addLegFlightRequestBody;
    let scheduledOperatingDateString = dayjs.utc(scheduledOperatingDate).format('YYYY-MM-DD');
    if (tzPreference === TimeZonePreference.STATION_LOCAL) {
      scheduledOperatingDateString = dayjs.utc(legs[0].etd.utcValue).format('YYYY-MM-DD');
    }

    let airportCodes = getAirportCodes(legs);

    const etopsRequiredAirports = await getAirportsV2(airportCodes, ['Is Etops Required'], true);

    let isEtopsRequired = isEtopsRequiredFromAirports(etopsRequiredAirports, legs[0]);

    let scheduledOperatingDateData = await getScheduledOperatingDateUTC(
      flightNumber.value.toString(),
      dayjs.utc(legs[0].etd.utcValue).format('YYYY-MM-DDTHH:mm:ss'),
      operatingAirline,
      aircraftTailNo.value,
    );

    let usableScheduledOperatingDateUTC = scheduledOperatingDateString;
    if (!isNullOrWhitespace(scheduledOperatingDateData?.scheduledOperatingDateUTC)) {
      usableScheduledOperatingDateUTC = dayjs
        .utc(scheduledOperatingDateData.scheduledOperatingDateUTC)
        .format('YYYY-MM-DD');
    }

    // Restructure add flight form data for post to API.
    addFlightRequestBody = getBodyWithSessionId(
      {
        operatingAirlineCode: operatingAirline,
        flightNumber: flightNumber.value.toString(),
        opsType: IrropsCode.ADD_FLIGHT,
        actualOrigin: legs[0].origin.station,
        actualDestination: legs[0].destination.station,
        scheduledOrigin: legs[0].origin.station,
        scheduledDestination: legs[0].destination.station,
        scheduledOperatingDateUTC: usableScheduledOperatingDateUTC,
        aircraftRegistration: aircraftTailNo.value,
        scheduledOut: dayjs.utc(legs[0].etd.utcValue).format('YYYY-MM-DDTHH:mm:ssZ'),
        scheduledIn: dayjs.utc(legs[0].eta.utcValue).format('YYYY-MM-DDTHH:mm:ssZ'),
        estimatedOut: dayjs.utc(legs[0].etd.utcValue).format('YYYY-MM-DDTHH:mm:ssZ'),
        estimatedIn: dayjs.utc(legs[0].eta.utcValue).format('YYYY-MM-DDTHH:mm:ssZ'),
        legNumber: '',
        comments: usercomment,
        opsReason: selectedReason.text,
        desk:
          operatingAirline === QX_AIRLINE && isNullOrWhitespace(legs[0].dispatchDesk)
            ? QX_DEFAULT_DISPATCH_DESK
            : legs[0].dispatchDesk,
        isEtopsFlight: isEtopsRequired,
        departureCount: 1,
      },
      sessionId,
    );

    // Call the IrropsQueuePublisher  API .
    addFlightResult = await irregularOps(addFlightRequestBody);
    trackEvent(
      `Add Flight - API call to submit add flight details is complete. Status:${
        addFlightResult?.status
      }, Flight number : ${flightNumber.value.toString()}`,
    );
    let headCallsArr = [];

    result = result && addFlightResult !== null && addFlightResult?.status === 'SUCCESS';
    if (result) {
      let firstHeadAddFlight = headCheckFlightExists(
        flightNumber.value.toString(),
        usableScheduledOperatingDateUTC,
        legs[0].origin.station,
        legs[0].destination.station,
        1,
        operatingAirline,
        IrropsCode.ADD_FLIGHT,
      );
      headCallsArr.push(firstHeadAddFlight);
    }

    for (let i = 1; i < legs.length; i++) {
      let leg = legs[i];
      isEtopsRequired = isEtopsRequiredFromAirports(etopsRequiredAirports, leg);

      let newLegDepartureCount = 1;
      for (let j = 0; j < i; j++) {
        if (legs[j].origin.station === leg.origin.station) {
          newLegDepartureCount++;
        }
      }

      // Restructure add leg flight form data for post to API.
      addLegFlightRequestBody = getBodyWithSessionId(
        {
          operatingAirlineCode: operatingAirline,
          flightNumber: flightNumber.value.toString(),
          opsType: IrropsCode.ADD_FLIGHT,
          actualOrigin: leg.origin.station,
          actualDestination: leg.destination.station,
          scheduledOrigin: leg.origin.station,
          scheduledDestination: leg.destination.station,
          scheduledOperatingDateUTC: usableScheduledOperatingDateUTC,
          aircraftRegistration: aircraftTailNo.value,
          scheduledOut: dayjs.utc(leg.etd.utcValue).format('YYYY-MM-DDTHH:mm:ssZ'),
          scheduledIn: dayjs.utc(leg.eta.utcValue).format('YYYY-MM-DDTHH:mm:ssZ'),
          estimatedOut: dayjs.utc(leg.etd.utcValue).format('YYYY-MM-DDTHH:mm:ssZ'),
          estimatedIn: dayjs.utc(leg.eta.utcValue).format('YYYY-MM-DDTHH:mm:ssZ'),
          legNumber: '',
          comments: usercomment,
          opsReason: selectedReason.text,
          desk:
            operatingAirline === QX_AIRLINE && isNullOrWhitespace(leg.dispatchDesk)
              ? QX_DEFAULT_DISPATCH_DESK
              : leg.dispatchDesk,
          isEtopsFlight: isEtopsRequired,
          departureCount: newLegDepartureCount,
        },
        sessionId,
      );

      // Call the IrropsQueuePublisher API .
      addFlightResult = await irregularOps(addLegFlightRequestBody);
      trackEvent(
        `Add Flight - API call to submit add flight details is complete. Status:${
          addFlightResult?.status
        }, Flight number : ${flightNumber.value.toString()}`,
      );

      result = result && addFlightResult !== null && addFlightResult?.status === 'SUCCESS';
      if (result) {
        let headThisFlightCall = headCheckFlightExists(
          flightNumber.value.toString(),
          usableScheduledOperatingDateUTC,
          leg.origin.station,
          leg.destination.station,
          newLegDepartureCount,
          operatingAirline,
          IrropsCode.ADD_FLIGHT,
        );
        headCallsArr.push(headThisFlightCall);
      }
    }

    if (result) {
      await updateUserAssignments();
      onCommit(true, 'Add', flightNumber.value, headCallsArr);
    } else {
      onCommit(false, 'Add', flightNumber.value, []);
    }
  };

  /**
   * @description update user assignments for the flight
   */
  const updateUserAssignments = async () => {
    const role = roleAssignments.role;
    const assignments = roleAssignments.assignments;

    if (role && assignments?.length) {
      await deleteUserAssignments();
      await postUserAssignments(role.roleID, assignments);
    }
  };
  // #endregion
  /**
   * @description handle on commit button click and call submit flight method.
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);
    await submitAddFlight();
  };

  const handleCommitButtonKeyDown = async (e) => {
    if (e.keyCode === KeyCodes.ENTER && isCommitEnabled()) {
      setCommitButtonClicked(true);
      await submitAddFlight();
    } else if (e.keyCode === KeyCodes.TAB) {
      focusNextElement(e, e.target.firstChild, 'addflight');
    }
  };

  /**
   * @description CLOSE THE ADD MODAL
   */
  const handleOnHideAddModal = () => {
    onClose();
  };

  /**
   * @description checks commit button enable and disabled by checking required fields values.
   * @returns true or false based on values.
   */
  const isCommitEnabled = () => {
    let isFlightDetailsValid =
      !isNullOrWhitespace(flightNumber.value) &&
      (flightNumber.isAvailable || flightNumber.isWarning) &&
      !isNullOrWhitespace(selectedReason.text) &&
      !isNullOrWhitespace(selectedReason.key) &&
      isValidTailNo &&
      !isNullOrWhitespace(aircraftTailNo.value) &&
      ((selectedReason.isCommentsRequired && !isNullOrWhitespace(usercomment)) ||
        //isCommitEnabled return true value as !null = !false = true. Added the below code to handle the same.
        selectedReason.isCommentsRequired === false);
    let isAddLegValid = isAddLegDataValid(legs);

    return isFlightDetailsValid && isAddLegValid && hasAddflightEntitlement && !commitButtonClicked;
  };
  /**
   * @description checks if leg is valid as a helper function for isCommitEnabled function
   * @param {*} value
   * @returns
   */
  const isAddLegDataValid = (value) => {
    var success = false;
    value.forEach((leg) => {
      if (
        !isNullOrWhitespace(leg.origin.station) &&
        !isNullOrWhitespace(leg.destination.station) &&
        !isNullOrWhitespace(leg.eta.hhmm) &&
        !isNullOrWhitespace(leg.etd.hhmm)
      ) {
        success = true;
      } else success = false;
    });
    return success;
  };

  // new modal body for add flight modal.
  const formHTML = (
    <>
      <div className="addflight-modal">
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeaderFirst}>
              {legs.length > 0 ? (
                <>
                  {1}
                  {getLegNumberSuffix(1)} FLIGHT DETAILS
                </>
              ) : (
                <>NEW FLIGHT DETAILS</>
              )}
              {legs.length > 0 ? (
                legs[0].isExpanded === true ? (
                  <FontAwesomeIcon
                    className="collapse-chevron"
                    onClick={(_e) => setIsExpanded(0, false)}
                    icon={faChevronDown}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="collapse-chevron"
                    onClick={(_e) => setIsExpanded(0, true)}
                    icon={faChevronUp}
                  />
                )
              ) : (
                <></>
              )}
            </div>
            <div className={classes.localStationLabel}>
              <i className="station-local-label">All times are station local</i>
            </div>
          </Grid>
          {legs[0].isExpanded ? (
            <>
              <Grid item md={3} sm={6} xs={6}>
                <BrickContainer title="Date" isRequired={true}>
                  <DatePicker
                    dataCyTag="addflight-date"
                    value={scheduledOperatingDate}
                    format="MMMM DD, YYYY"
                    className="addflight addflight-date"
                    onChange={handleDateInput}
                    isDisabled={false}
                    isDisablePast={false}
                    isAutoFocus={true}
                    {...maxDateProps} //maxDate Props
                    //maxDate={maxDate}
                    minDate={minScheduledOperatingDate}
                  />
                </BrickContainer>
              </Grid>

              <Grid item md={3} sm={6} xs={6}>
                <div className="flex-container-row">
                  <BrickContainer title="Origin" isRequired={true}>
                    <AirportCodeInput
                      airports={
                        isLoadingAirportsData || airportsDataError || !airportsData?.data?.length
                          ? []
                          : airportsData.data
                      }
                      onChange={(value, event, target) => handleAirportCodeOriginChange(value, legs[0], event, target)}
                      testId="addflight-FirstLeg-Origin"
                      inputVal={legs[0].origin.station}
                      className="addflight"
                    />
                  </BrickContainer>
                  <div className="vert-margin-auto arrow-right">
                    <FontAwesomeIcon className="div-arrowright-centered" icon={faArrowRight} />
                  </div>
                  <BrickContainer title="Destination" isRequired={true}>
                    <AirportCodeInput
                      airports={
                        isLoadingAirportsData || airportsDataError || !airportsData?.data?.length
                          ? []
                          : airportsData.data
                      }
                      onChange={(value, event, target) =>
                        handleAirportCodeDestinationChange(value, legs[0], event, target)
                      }
                      testId="addflight-FirstLeg-Destination"
                      inputVal={legs[0].destination.station}
                      className="addflight"
                    />
                  </BrickContainer>
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={6}>
                <TimeInput
                  label="ETD"
                  value={legs[0].etd.hhmm}
                  className="addflight"
                  tzIndicator={legs[0].etd.tzDisplay}
                  isRequired={true}
                  onChange={(value) => handleETDChange(value, legs[0])}
                  warningType={legs[0].etd.warningType}
                  warningMessage={legs[0].etd.message}
                  dataCyTag="addflight-FirstLeg-etd"
                  isAutoFocus={false}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={6}>
                <TimeInput
                  label="ETA"
                  value={legs[0].eta.hhmm}
                  className="addflight"
                  tzIndicator={legs[0].eta.tzDisplay}
                  isRequired={true}
                  onChange={(value) => handleETAChange(value, legs[0])}
                  warningType={legs[0].eta.warningType}
                  warningMessage={legs[0].eta.message}
                  dataCyTag="addflight-FirstLeg-eta"
                  isAutoFocus={false}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={6} data-cy="addflight-fleetType-dropdown">
                <BrickContainer title="Subfleet">
                  <SearchableDropdown
                    textInput={selectedFleetType.text}
                    isDisabled={false}
                    onSelect={handleSubfleetInputChange}
                    onChange={handleSubfleetOnChange}
                    displayList={filterFleetTypes}
                    dataCyTag={'addflight-subfleet'}
                    isRequired={false}
                    className="addflight"
                  ></SearchableDropdown>
                </BrickContainer>
              </Grid>
              <Grid item md={3} sm={6} xs={6} data-cy="aircraft-dropdown">
                <BrickContainer title="Aircraft" isRequired={true}>
                  <AircraftSearchableDropdown
                    textInput={aircraftTailNo.value}
                    isDisabled={false}
                    onSelect={handleAircraftOnSelect}
                    onChange={handleAircraftTailOnChange}
                    displayList={filteredAircrafts}
                    dataCyTag={'addflight-aircraft'}
                    isRequired={true}
                    warningType={aircraftTailNo.warningType}
                    className="addflight"
                    maxLengthValue={maxAircraftNumberCharacters}
                    isOpenList={showAircraftList}
                  ></AircraftSearchableDropdown>
                </BrickContainer>
                {aircraftTailNo.isWarning && (
                  <div
                    data-cy="addflight-aircraft-warning"
                    className={
                      aircraftTailNo.warningType === WarningTypes.ERROR
                        ? classes.brickErrorLabel
                        : classes.brickWarningLabel
                    }
                  >
                    {aircraftTailNo.message}
                  </div>
                )}
              </Grid>
              <Grid item md={3} sm={6} xs={6}>
                <BrickContainer title="Flight" isRequired={true}>
                  <OutlinedInput
                    value={flightNumber.value}
                    onChange={handleFlightNumberChange}
                    onBlur={handleFlightNumberBlur}
                    onKeyDown={handleFlightNumberKeyDown}
                    className={
                      flightNumber.isAvailable
                        ? classes.allControlStyles
                        : flightNumber.isWarning
                        ? classes.controlWarningStyles
                        : classes.controlErrorStyles
                    }
                    id={'addflight-flight'}
                    inputProps={{
                      autoComplete: 'off',
                      'aria-label': 'Flight',
                      'data-cy': 'addflight-flight',
                      className: 'addflight',
                    }}
                    labelWidth={0}
                  />
                </BrickContainer>
                {!flightNumber.isAvailable || flightNumber.isWarning ? (
                  <div
                    data-cy="flight-number-warning"
                    className={flightNumber.isWarning ? classes.brickWarningLabel : classes.brickErrorLabel}
                  >
                    {' '}
                    {flightNumber.message}{' '}
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item md={3} sm={6} xs={6}>
                <BrickContainer title="Dispatch Desk">
                  <OutlinedInput
                    value={legs[0].dispatchDesk}
                    onChange={(value) => handleDispatchDeskChange(value, legs[0])}
                    onKeyDown={handleDispatchDeskKeyDown}
                    className={classes.allControlStyles}
                    id={'addflight-dispatchDesk'}
                    inputProps={{
                      autoComplete: 'off',
                      'aria-label': 'DD',
                      'data-cy': 'addflight-dispatchDesk',
                      className: 'addflight',
                    }}
                    labelWidth={0}
                  />
                </BrickContainer>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>
        <Grid container spacing={3}>
          {legs.length > 1 && (
            <Grid item xs={12} className={classes.pbZero}>
              {legs.map((e, i) =>
                i === 0 ? (
                  <></>
                ) : (
                  <>
                    <Grid container spacing={3} key={`flightLeg-${i}`} className={classes.addedFlightLeg}>
                      <Grid item xs={12}>
                        <div className={classes.horizontalRule}></div>
                      </Grid>
                      <Grid item xs={12} className={classes.sectionHeaderGrid}>
                        <div className={classes.sectionHeader}>
                          {i + 1}
                          {getLegNumberSuffix(i + 1)} FLIGHT DETAILS
                          {e.isExpanded === true ? (
                            <FontAwesomeIcon
                              data-cy="chevrontype-down"
                              className="collapse-chevron"
                              arrindex={i}
                              chevrontype="down"
                              onClick={handleLegExpanndCollapseClick}
                              icon={faChevronDown}
                            />
                          ) : (
                            <FontAwesomeIcon
                              data-cy="chevrontype-up"
                              className="collapse-chevron"
                              arrindex={i}
                              chevrontype="up"
                              onClick={handleLegExpanndCollapseClick}
                              icon={faChevronUp}
                            />
                          )}
                        </div>
                      </Grid>
                      {e.isExpanded ? (
                        <>
                          <Grid item md={3} sm={6} xs={6}>
                            <BrickContainer title="Flight Leg" isRequired={true}>
                              <div className="flex-container-row">
                                <div className="vert-margin-auto addflight-text" data-cy="next-leg-origin">
                                  {e.origin.station === '' ? (
                                    <div className="station-control">---</div>
                                  ) : (
                                    e.origin.station
                                  )}
                                </div>
                                <div className="vert-margin-auto">
                                  <FontAwesomeIcon className="div-arrowright-centered" icon={faArrowRight} />
                                </div>
                                <AirportCodeInput
                                  airports={
                                    isLoadingAirportsData || airportsDataError || !airportsData?.data?.length
                                      ? []
                                      : airportsData.data
                                  }
                                  onChange={(value, event, target) =>
                                    handleAirportCodeDestinationChange(value, e, event, target)
                                  }
                                  testId="next-leg-destination"
                                  inputVal={e.destination.station}
                                  className="addflight"
                                />
                              </div>
                            </BrickContainer>
                          </Grid>
                          <Grid item md={3} sm={6} xs={6}>
                            <TimeInput
                              label="ETD"
                              value={e.etd.hhmm}
                              isRequired={true}
                              onChange={(value) => handleETDChange(value, e)}
                              warningType={e.etd.warningType}
                              warningMessage={e.etd.message}
                              dataCyTag="next-leg-Etd"
                              tzIndicator={e.etd.tzDisplay}
                              isAutoFocus={false}
                              className="addflight"
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={6}>
                            <TimeInput
                              label="ETA"
                              value={e.eta.hhmm}
                              isRequired={true}
                              onChange={(value) => handleETAChange(value, e)}
                              warningType={e.eta.warningType}
                              warningMessage={e.eta.message}
                              dataCyTag="next-leg-Eta"
                              tzIndicator={e.eta.tzDisplay}
                              className="addflight"
                              isAutoFocus={false}
                            />
                          </Grid>
                          <Grid item md={3} sm={6} xs={6}>
                            <BrickContainer title="Dispatch Desk">
                              <OutlinedInput
                                value={e.dispatchDesk}
                                onChange={(event) => handleDispatchDeskChange(event, e)}
                                className={classes.allControlStyles}
                                data-cy="addflight-dd"
                                id={'next-leg-dispatchDesk'}
                                inputProps={{
                                  autoComplete: 'off',
                                  'aria-label': 'DD',
                                  'data-cy': 'next-leg-dispatchDesk',
                                  className: 'addflight',
                                }}
                                labelWidth={0}
                              />
                            </BrickContainer>
                          </Grid>
                          <Grid item xs={12} className={i === legs.length - 1 ? classes.deleteGrid : ''}>
                            <div
                              onClick={(event) => handleDeleteLeg(event, e)}
                              data-cy="delete-leg-btn"
                              arrindex={i}
                              className={classes.deleteLegLink}
                            >
                              <FontAwesomeIcon icon={faTrash} /> Delete
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </>
                ),
              )}
            </Grid>
          )}
          <Grid item xs={12} className={classes.blueLink}>
            <div onClick={handleAddFlightLeg} data-cy="add-newLeg-btn" className={classes.addLegLink}>
              <FontAwesomeIcon icon={faPlus} /> Add Leg
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} className={'marginBZero'}>
          <Grid item xs={12}>
            <div className={classes.horizontalRule}></div>
          </Grid>
          <Grid item xs={12} className={classes.sectionHeaderGrid}>
            <div className={classes.sectionHeader}>DOCUMENTATION</div>
          </Grid>
          <Grid item md={6} sm={12}>
            <BrickContainer title="Reason" isRequired={true}>
              <IrropsReasons
                onSelect={handleOnSelectReason}
                airlineCode={operatingAirline}
                irropsCode={IrropsCode.ADD_FLIGHT}
                selectedReason={selectedReason.text}
                isRequired={true}
                className="addflight"
              />
            </BrickContainer>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <BrickContainer title="Comment" isRequired={selectedReason.isCommentsRequired}>
              <TextAreaInput
                value={usercomment}
                placeholder={selectedReason.template}
                rows={4}
                onChange={handleCommentsTextInputChange}
                dataCyTag="Irrops-flight-form-comments-textarea"
                className="addflight"
              />
            </BrickContainer>
          </Grid>
        </Grid>
      </div>
    </>
  );

  // add flight modal footer buttons.

  const formButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="Irrops-add-flight-form-save-button"
      isDisabled={!isCommitEnabled()}
      onClick={handleCommitButtonClick}
      onKeyDown={handleCommitButtonKeyDown}
      // onKeyPress={handleKeypress}
      className="btnDivCommit addflight"
    >
      {commitButtonClicked ? 'Submitting...' : 'Commit'}
    </Button>,
  ];

  return (
    <div>
      <Modal
        focusId="focusId"
        show={showModal}
        title="Add Flight"
        body={formHTML}
        footerButtons={formButtons}
        onHide={handleOnHideAddModal}
        customCSSTag="irrop-addflight"
      />
    </div>
  );
};

AddFlightModalV3.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(AddFlightModalV3);
