import React, { createContext, useContext, useEffect, useReducer } from 'react';
import userPreferencesReducer from './userPreferencesReducer';
import { updateUserPreferences as updateUserPreferencesApi } from '../../services/apiClient/preferencesApi/preferencesApi';
import { UserPreferenceState, PreferenceType } from '../types';
import { usePreferencesQuery } from '../../hooks/usePreferencesQuery/usePreferencesQuery';
import { formatDateTime } from '../../lib/dateTimeUtils';
import { SessionStorageKeys } from '../../lib/constants';
import { getSessionStorageItem, setSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';

const UserPreferencesContext = createContext(null);
const UserPreferencesFunctionsContext = createContext(null);
const UserPreferencesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userPreferencesReducer, {
    roleAssignments: null,
    theme: null,
    puckSize: null,
    timelineHours: null,
    ganttViewScaling: null,
    loading: true,
  });

  const {
    isLoading: isLoadingPreferences,
    data: preferencesData,
    dataUpdatedAt: preferencesUpdatedAt,
    hasError: error,
  } = usePreferencesQuery();

  /** * Output the latest time preferences data was successfully retrieved */
  useEffect(() => {
    if (preferencesUpdatedAt) {
      const time = formatDateTime(preferencesUpdatedAt, 'YYYY-MM-DD HH:mm:ss');
      console.log(`Preferences API data refreshed or fetched at ${time}z`);
    }
  }, [preferencesUpdatedAt]);

  const updatePresistedRoleAssignments = (roleAssignments) => {
    setSessionStorageItem(SessionStorageKeys.ROLE_ASSIGNMENTS, roleAssignments);
  };

  useEffect(() => {
    let storedRoleAssignments = getSessionStorageItem(SessionStorageKeys.ROLE_ASSIGNMENTS);
    if (state.roleAssignments && storedRoleAssignments === null) {
      updatePresistedRoleAssignments(state.roleAssignments);
    }
  }, [state.roleAssignments]);

  useEffect(() => {
    if (!isLoadingPreferences) {
      if (error) {
        dispatch({ type: UserPreferenceState.ERROR });
      } else if (
        preferencesData === null ||
        preferencesData === undefined ||
        preferencesData.userPreferences === null ||
        preferencesData.userPreferences === undefined
      ) {
        dispatch({ type: UserPreferenceState.NONE });
      } else {
        dispatch({ type: UserPreferenceState.RETRIEVED, payload: preferencesData });
      }
    } else {
      dispatch({ type: UserPreferenceState.RETRIEVING });
    }
  }, [preferencesData, isLoadingPreferences, error]);

  /**
   * Updates the theme user preference
   * @param {string} theme - ThemeMode enum
   */
  const updateThemePreference = async (theme) => {
    try {
      dispatch({ type: UserPreferenceState.UPDATING });
      const requestBody = [
        {
          preferenceType: PreferenceType.THEME,
          userPreferenceContent: JSON.stringify({ theme: theme }),
        },
      ];
      await updateUserPreferencesApi(requestBody);
    } catch (e) {
      console.error('Update of user preferences has failed', e);
    }
    dispatch({ type: UserPreferenceState.UPDATED, payload: { ...state, theme: { theme: theme } } });
  };

  /**
   * Updates the role assignments user preference
   * TODO double check types
   * @param {string} role - the role name
   * @param {array} assignments - list of assignments
   */
  const updateRoleAssignmentsPreference = async (role, assignments) => {
    const roleAssignments = { role: role, assignments: assignments };
    try {
      dispatch({ type: UserPreferenceState.UPDATING });
      const requestBody = [
        {
          preferenceType: PreferenceType.ROLE_ASSIGNMENTS,
          userPreferenceContent: JSON.stringify(roleAssignments),
        },
      ];
      updatePresistedRoleAssignments(roleAssignments);
      await updateUserPreferencesApi(requestBody);
    } catch (e) {
      console.error('Update of user preferences has failed', e);
    }
    dispatch({ type: UserPreferenceState.UPDATED, payload: { ...state, roleAssignments: roleAssignments } });
  };
  /**
   * Updates the gantt scaling user preference
   * @param {string} puckSize - the role name
   */
  const updatePuckSizePreference = async (puckSize) => {
    try {
      dispatch({ type: UserPreferenceState.UPDATING_SILENT });
      const requestBody = [
        {
          preferenceType: PreferenceType.PUCK_SIZE,
          userPreferenceContent: JSON.stringify({ puckSize: puckSize }),
        },
      ];
      await updateUserPreferencesApi(requestBody);
    } catch (e) {
      console.error('Update of PuckSize user preferences has failed', e);
    }
    dispatch({ type: UserPreferenceState.UPDATED, payload: { puckSize: { puckSize: puckSize } } });
  };

  /**
   * Updates the gantt scaling user preference
   * @param {string} hoursBefore - hours to show before Now line
   * @param {string} hoursAfter - hours to show after Now line
   * @param {string} numberOfAircrafts - number of aircrafts to be displayed on gantt
   */
  const updateGanttViewScalingPreference = async (hoursBefore, hoursAfter, numberOfAircrafts) => {
    try {
      // {"numberOfAircrafts": "20", "hoursAfter": "32", "hoursBefore": "4"} Example default values
      dispatch({ type: UserPreferenceState.UPDATING_SILENT });
      const requestBody = [
        {
          preferenceType: PreferenceType.GANTT_VIEW_SCALING,
          userPreferenceContent: JSON.stringify({
            numberOfAircrafts: numberOfAircrafts,
            hoursAfter: hoursAfter,
            hoursBefore: hoursBefore,
          }),
        },
      ];
      await updateUserPreferencesApi(requestBody);
    } catch (e) {
      console.error('Update of GanttViewScaling user preferences has failed', e);
    }
    dispatch({
      type: UserPreferenceState.UPDATED,
      payload: {
        ...state,
        ganttViewScaling: { numberOfAircrafts: numberOfAircrafts, hoursAfter: hoursAfter, hoursBefore: hoursBefore },
      },
    });
  };

  /**
   * Updates the gantt scaling user preference
   * @param {string} timelineHours - timeline hours preference (12, 24, 36,... 96)
   */
  const updateTimelineHourPreference = async (timelineHours) => {
    try {
      dispatch({ type: UserPreferenceState.UPDATING_SILENT });
      const requestBody = [
        {
          preferenceType: PreferenceType.TIMELINE_HOURS,
          userPreferenceContent: JSON.stringify({ timelineHours: timelineHours }),
        },
      ];
      await updateUserPreferencesApi(requestBody);
    } catch (e) {
      console.error('Update of user preferences has failed', e);
    }
    dispatch({
      type: UserPreferenceState.UPDATED,
      payload: { ...state, timelineHours: { timelineHours: timelineHours } },
    });
  };

  return (
    <UserPreferencesContext.Provider value={{ state }}>
      <UserPreferencesFunctionsContext.Provider
        value={{
          updateThemePreference,
          updateRoleAssignmentsPreference,
          updatePuckSizePreference,
          updateTimelineHourPreference,
          updateGanttViewScalingPreference,
        }}
      >
        {children}
      </UserPreferencesFunctionsContext.Provider>
    </UserPreferencesContext.Provider>
  );
};

const useUserPreferencesData = () => {
  const context = useContext(UserPreferencesContext);
  if (!context) {
    throw new Error(
      'useUserPreferencesData must be used within a UserPreferences Provider. Wrap a parent component in <UserPreferencesProvider> to fix this error.',
    );
  }
  return context;
};

const useUserPreferencesFunctions = () => {
  const context = useContext(UserPreferencesFunctionsContext);
  if (!context) {
    throw new Error(
      'UserPreferencesFunctions must be used within a UserPreferences Provider. Wrap a parent component in <UserPreferencesProvider> to fix this error.',
    );
  }
  return context;
};
export { UserPreferencesProvider, useUserPreferencesData, useUserPreferencesFunctions };
