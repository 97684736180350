import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import { irregularOps } from '../../../services/apiClient/irregularOpsApi/irregularOpsApi';
import {
  IrropsCode,
  EntitlementNames,
  IrropsMenuItemAction,
  IrropFlightStatusErrorMessage,
  WarningTypes,
  IrropsModalFieldType,
  KeyCodes,
  Treatment,
} from '../../../lib/constants';
import { formatDateTime } from '../../../lib/dateTimeUtils';
import IrropsFlightModal from '../../Shared/IrropsFlightModal/IrropsFlightModal';
import AocAuthAccessCheck from '../../AocAuthAccessCheck/AocAuthAccessCheck';
import {
  isNullOrWhitespace,
  canPerformIrrop,
  getCalculatedIrropDataEstimates,
  isNullOrNotNumber,
  getDetailsBlocks,
  getIrropsFlightSubmitBody,
  getDepartureCount,
} from '../../../lib/irropUtils';
import { focusNextElement, uuidv4 } from '../../../lib/utils';
import {
  getFlightDetails,
  headCheckFlightExists,
  getFlightsFiltered,
} from '../../../services/apiClient/flightsApi/flightsApi';
import ConfirmationPopup from '../../Shared/ConfirmationPopup/ConfirmationPopup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useAppInsightsContext } from '../../../contexts/AppInsightsContext/AppInsightsContext';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import useAuthorizationAccess from '../../../hooks/useAuthorizationAccess/useAuthorizationAccess';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

/**
 * The Divert Flight component is build the Divert Flight form.
 * @param {Object} props -
 *  existingDivertFlight - existing Divert Flight object.
 *  onCommit - callback func when save the Divert flight form.
 *  onClose - callback func when close the Divert flight form.
 *  divertFlight - divertFlight object.
 *  nextFlightLeg - nextFlightLeg object if multi leg.
 * @returns DivertFlight component
 */

const DivertFlight = ({
  existingDivertFlight = null,
  onCommit,
  onClose,
  divertFlight,
  nextFlightLeg,
  getFlightLineForFlightPuck,
  ...props
}) => {
  const {
    operatingAirlineCode,
    flightNumber,
    origin,
    destination,
    scheduledDestination,
    flightDateUtc,
    outTime,
    eta,
    isEtopsFlight,
    scheduledOperatingDateUTC,
    aircraftRegistration,
    legNumber,
    departureCount,
  } = divertFlight;

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [irropStationVar, setIrropStationVar] = useState('');
  // turnTimeMinutes from contStation state comes from server.
  const [irropStation, setIrropStation] = useState({
    station: '',
    irropBlockMinutes: '',
    contTurnTimeMinutes: '',
    contBlockMinutes: '',
    isError: false,
    error: '',
  });
  const [irropETA, setIrropETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  // turnTimeMinutes state is user input.
  const [turnTimeMinutes, setTurnTimeMinutes] = useState({ value: '', isError: false, error: '' });
  const [contETD, setContETD] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [contETA, setContETA] = useState({ value: '', isError: false, error: '', hhmm: '' });
  const [userComments, setUserComments] = useState('');
  const [checkedContFlightNeeded, setCheckedContFlightNeeded] = useState(true);
  const [isMultiLegFlight, setIsMultiLegFlight] = useState(false);
  const [checkedCancelFlight, setCheckedCancelFlight] = useState(false);
  const [showCancelFlight, setShowCancelFlight] = useState(false);
  const [canceledFlightData, setCanceledFlightData] = useState({
    etd: '',
    eta: '',
    scheduledOrigin: '',
    scheduledDestination: '',
  });
  const [show, setShow] = useState(true);

  const [selectedReason, setSelectedReason] = useState({
    text: '',
    key: null,
    template: '',
    isCommentsRequired: false,
  });
  const [commitButtonClicked, setCommitButtonClicked] = useState(false);
  const { trackEvent } = useAppInsightsContext();

  // Authorization access hook
  const hasUpdateReasonEntitlement = useAuthorizationAccess(null, [EntitlementNames.DIVERT]);

  // Feature flag
  const { showFeature } = useFeatureFlag();
  const irropsSessionIDFlag = showFeature(Treatment.IRROPS_SESSIONID);

  // Warning Messages for destination
  const warningMessages = {
    DEST_SAME_AS_CURRENT: 'Destination same as current',
    DEST_SAME_AS_ORIGIN: 'Destination same as origin. Use ATB?',
  };

  /**
   * Clears all input variable state
   */
  const cleanInputs = () => {
    setIrropStationVar('');

    setIrropStation({ station: '', irropBlockMinutes: '', contTurnTimeMinutes: '', contBlockMinutes: '' });
    setIrropETA({ value: '', isError: false, error: '', hhmm: '' });
    setTurnTimeMinutes({ value: '', isError: false, error: '' });
    setContETD({ value: '', isError: false, error: '', hhmm: '' });
    setContETA({ value: '', isError: false, error: '', hhmm: '' });
  };

  /**
   * @description Handle on change of input field.
   * @param {string} - String value
   */
  const handleETAInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.IRROP_ETA, val);
    } else {
      setIrropETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };

  /**
   * Event handler when the divert airport code has changed.
   * @param {String} value - three-letter, capitalized airport code
   */
  const handleAirportCodeInputChange = (value, event, target) => {
    if (value === irropStationVar) return;
    if (value.length < 3) {
      cleanInputs();
      return;
    }
    setIrropStationVar(value);

    if (!isNullOrWhitespace(value) && value.length === 3) {
      updateWithEstimates(IrropsModalFieldType.STATIONS, value);
    }
    focusNextElement(event, target);
  };

  /**
   * Calls updateIrropDataEstimates from IrropUtils.js and updates localstate
   * @param {String} dirtyField IrropsModalFieldType type enum that indicates which field is currently dirty
   * @param {String} dirtyValue Value of the dirty field
   */
  const updateWithEstimates = async (dirtyField, dirtyValue) => {
    let result = await getCalculatedIrropDataEstimates(
      operatingAirlineCode,
      legNumber,
      aircraftRegistration,
      formatDateTime(outTime, 'YYYY-MM-DDTHH:mm:ssZ'),
      origin,
      destination,
      irropStation,
      irropETA,
      false,
      isContinuationFlightEnabled(),
      turnTimeMinutes,
      contETD,
      contETA,
      dirtyField,
      dirtyValue,
      true,
    );

    if (result == null) return null;
    if (result[IrropsModalFieldType.IRROP_ETA] != null) {
      setIrropETA(result[IrropsModalFieldType.IRROP_ETA]);
    }
    if (result[IrropsModalFieldType.STATIONS] != null) {
      let isError = false;
      let stations = result[IrropsModalFieldType.STATIONS];
      // If current flight destination matches entered dest, error
      if (destination === stations.station) {
        isError = true;
        stations.error = warningMessages.DEST_SAME_AS_CURRENT;
      } else if (origin === stations.station) {
        isError = true;
        stations.error = warningMessages.DEST_SAME_AS_ORIGIN;
      }
      stations.isError = isError;
      setIrropStation(stations);
    }

    if (result[IrropsModalFieldType.CONTINUATION_TURNTIME] != null) {
      setTurnTimeMinutes(result[IrropsModalFieldType.CONTINUATION_TURNTIME]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETD] != null) {
      setContETD(result[IrropsModalFieldType.CONTINUATION_ETD]);
    }
    if (result[IrropsModalFieldType.CONTINUATION_ETA] != null) {
      setContETA(result[IrropsModalFieldType.CONTINUATION_ETA]);
    }
  };
  /**
   * @description Handle on blur of input field.
   * @param {any} - event - event object.
   */
  const handleTurnTimeOnBlur = (e) => {
    updateWithEstimates(IrropsModalFieldType.CONTINUATION_TURNTIME, turnTimeMinutes.value);
  };

  /**
   * @description Handle on change of input field.
   * @param {string} - String value
   */
  const handleTurnTimeTextInputChange = (val) => {
    let inputNum = val.replace(/[^0-9]/g, '');
    if (inputNum.length > 3) {
      return;
    }
    setTurnTimeMinutes({ value: inputNum, isError: false, error: '' });
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETDTextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETD, val);
    } else {
      setContETD({ value: '', hhmm: val, isError: false, error: '' });
    }
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleCommentsTextInputChange = (val) => {
    setUserComments(val);
  };

  /**
   * @description handle on change of input field.
   * @param {string} - string value
   */
  const handleContinuationETATextInputChange = (val) => {
    if (val.length === 5) {
      updateWithEstimates(IrropsModalFieldType.CONTINUATION_ETA, val);
    } else {
      setContETA({ value: '', hhmm: val, isError: false, error: '' });
    }
  };

  const isCommitEnabled = () => {
    return (
      !isNullOrWhitespace(irropStationVar) &&
      irropStationVar.length === 3 &&
      !isNullOrWhitespace(irropETA.hhmm) &&
      !isNullOrWhitespace(irropETA.value) &&
      !irropStation.isError &&
      ((isContinuationFlightEnabled() &&
        !isNullOrNotNumber(turnTimeMinutes.value) &&
        !isNullOrWhitespace(contETD.hhmm) &&
        !isNullOrWhitespace(contETD.value) &&
        !isNullOrWhitespace(contETA.hhmm) &&
        !isNullOrWhitespace(contETA.value)) ||
        !isContinuationFlightEnabled()) &&
      !isNullOrWhitespace(selectedReason) &&
      !isNullOrWhitespace(selectedReason.key) &&
      ((selectedReason.isCommentsRequired && !!userComments) || //isCommitEnabled return true value as !null = !false = true. Added the below code to handle the same.
        selectedReason.isCommentsRequired === false) &&
      hasUpdateReasonEntitlement &&
      !commitButtonClicked
    );
  };

  /**
   * @description handle on continue flight checkbox event.
   */
  const handleChkContFlightNeeded = (e) => {
    const newContinueFlag = !checkedContFlightNeeded;
    setCheckedContFlightNeeded(newContinueFlag);
    updateWithEstimates(IrropsModalFieldType.CONTINUATION_CHECKED, newContinueFlag);
  };
  /**
   * @description handle the check cancel flight checkbox event.
   */
  const handleChkCancelFlight = (e) => {
    setCheckedCancelFlight(!checkedCancelFlight);
  };
  /**
   * @description handle the on selecting the reason dropdown and set the state.
   * @param {string} reason selected reason value.
   * @param {any} reasonKey reason key.
   * @param {any} commentsTemplate template/placeholder for comments.
   * @param {any} commentsRequired is comments required for reason.
   */
  const handleOnSelectReason = (reason, reasonKey, commentsTemplate, commentsRequired, event, target) => {
    setSelectedReason({
      text: reason,
      key: reasonKey,
      template: commentsTemplate,
      isCommentsRequired: commentsRequired,
    });
    focusNextElement(event, target);
  };

  /**
   * @description handle the on hide the DIVERT MODAL
   */
  const handleOnHideIrropsModal = () => {
    setShow(false);
    onClose(IrropsCode.DIVERT_FLIGHT);
  };

  /**
   * @description Handle the commit button click and show and hide the modal.
   */
  const handleCommitButtonClick = async () => {
    setCommitButtonClicked(true);
    if (checkedCancelFlight === true) {
      setShowConfirmPopup(true); // Show confirm popup
    } else {
      await submitDivert();
    }
    setShow(false); // Hide The Modal
  };

  useEffect(() => {
    // Check Next Flight Is Multileg
    const isMultiLegFlight = async () => {
      if (
        nextFlightLeg !== null &&
        nextFlightLeg.flightNumber === flightNumber.toString() &&
        nextFlightLeg.aircraft.aircraftRegistration === aircraftRegistration &&
        nextFlightLeg.scheduledOperatingDateUTC === scheduledOperatingDateUTC
      ) {
        // Is multi-leg
        setCanceledFlightData((prevState) => {
          return {
            ...prevState,
            canceledFlightETD: `${dayjs.utc(nextFlightLeg.times.find((t) => t.name === 'OUT')?.estimatedTimeUTC)}`,
            canceledFlightETA: `${dayjs.utc(nextFlightLeg.times.find((t) => t.name === 'IN')?.estimatedTimeUTC)}`,
            // Save properties needed to submit a cancel
            canceledFlightScheduledOrigin: `${nextFlightLeg.origin.scheduled}`,
            canceledFlightScheduledDestination: `${nextFlightLeg.destination.scheduled}`,
            actualOrigin: nextFlightLeg.origin.actual,
            actualDestination: nextFlightLeg.destination.actual,
            flightNumber: nextFlightLeg.flightNumber,
            operatingAirlineCode: nextFlightLeg.operatingAirline,
            scheduledOperatingDateUTC: nextFlightLeg.scheduledOperatingDateUTC,
            departureCount: nextFlightLeg.departureCount,
          };
        });
        setIsMultiLegFlight(true);
        setShowCancelFlight(true);
      }
    };
    isMultiLegFlight();
  }, [nextFlightLeg]);

  const isContinuationFlightEnabled = useCallback(() => {
    return (isMultiLegFlight && checkedContFlightNeeded) || !isMultiLegFlight;
  }, [isMultiLegFlight, checkedContFlightNeeded]);

  /**
   * Submits a DIV for the current flight V2 when Irrops Refresh Enhancement is turned on.
   * May submit an ADD if continuation flight is checked.
   * May submit a CXL if current flight is multi-leg and canceled flight is checked.
   * Handles the result of requested irrops by invoking callback onCommit.
   */
  const submitDivertFlightV2 = async () => {
    let isExistIrrops;

    trackEvent(`Divert - Submit modal for flight number : ${flightNumber}`);

    // Restructure divert flight form data for post to API.

    const sessionId = irropsSessionIDFlag ? uuidv4() : '';

    let submitBody = getIrropsFlightSubmitBody(
      IrropsCode.DIVERT_FLIGHT,
      operatingAirlineCode,
      aircraftRegistration,
      flightNumber,
      origin,
      irropStationVar,
      scheduledDestination,
      destination,
      scheduledOperatingDateUTC,
      irropETA.value,
      userComments,
      selectedReason.text,
      departureCount,
      contETD.value,
      contETA.value,
      isEtopsFlight,
      checkedCancelFlight,
      canceledFlightData,
      isContinuationFlightEnabled(), //checkedContFlightNeeded,
      sessionId,
    );

    const response = await getFlightDetails(
      flightNumber,
      scheduledOperatingDateUTC,
      origin,
      destination,
      departureCount,
      operatingAirlineCode,
    );
    if (response !== null) {
      isExistIrrops = canPerformIrrop(response, IrropsMenuItemAction.DIVERT_FLIGHT);
      if (isExistIrrops) {
        let canceledOperationCompleteCall = null,
          canceledLegResult = null,
          continuedLegResult = null,
          continuationOperationCompleteCall = null;

        let divertResult = await irregularOps(submitBody.irropFlightRequestBody);
        trackEvent(
          `Divert - API call to submit divert flight details is complete. Status:${divertResult?.status}, Flight number : ${flightNumber}`,
        );
        let divertOperationCompleteCall = headCheckFlightExists(
          flightNumber,
          submitBody.irropFlightRequestBody.scheduledOperatingDateUTC,
          submitBody.irropFlightRequestBody.actualOrigin,
          submitBody.irropFlightRequestBody.actualDestination,
          submitBody.irropFlightRequestBody.departureCount,
          submitBody.irropFlightRequestBody.operatingAirlineCode,
          submitBody.irropFlightRequestBody.opsType,
        );
        let headCallsArr = []; // Track all the headCheckFlightExists calls in an array. we will be able to send this array to FlightActionMenu commit callback.
        headCallsArr.push(divertOperationCompleteCall);

        // Make CXL or ADD API call if needed
        if (checkedCancelFlight) {
          // If canceled section is true, make API call. makeIrropsCall will return null if no need to call
          canceledLegResult = await irregularOps(submitBody.canceledRequestBody);
          trackEvent(
            `Divert - API call to submit canceled flight details is complete. Status:${canceledLegResult?.status}, Flight number : ${flightNumber}`,
          );
          canceledOperationCompleteCall = headCheckFlightExists(
            flightNumber,
            submitBody.canceledRequestBody.scheduledOperatingDateUTC,
            submitBody.canceledRequestBody.actualOrigin,
            submitBody.canceledRequestBody.actualDestination,
            submitBody.canceledRequestBody.departureCount,
            submitBody.canceledRequestBody.operatingAirlineCode,
            submitBody.canceledRequestBody.opsType,
          );
          headCallsArr.push(canceledOperationCompleteCall);
        }

        if (isContinuationFlightEnabled()) {
          // Continuation flight checkbox is checked

          let newDepartureCount = 0;
          let flightLegs = null;

          if (getFlightLineForFlightPuck != null) {
            // getFlightLineForFlightPuck is available when the modal is opened from Gant view.

            flightLegs = getFlightLineForFlightPuck(null, true);
          } else {
            // if the modal is opened from table view, we need to make a call to get flight legs to determine new departureCount

            let flightLegsData = await getFlightsFiltered(
              submitBody.addContinueFlightRequestBody.scheduledOperatingDateUTC,
              submitBody.addContinueFlightRequestBody.scheduledOperatingDateUTC,
              [],
              [flightNumber],
              [submitBody.addContinueFlightRequestBody.actualOrigin],
              [],
            );
            flightLegs = flightLegsData.flattenedFlightLegs;
          }
          newDepartureCount =
            getDepartureCount(
              flightLegs,
              flightNumber,
              submitBody.addContinueFlightRequestBody.actualOrigin,
              submitBody.addContinueFlightRequestBody.scheduledOperatingDateUTC,
            ) + 1;

          continuedLegResult = await irregularOps({
            ...submitBody.addContinueFlightRequestBody,
            departureCount: newDepartureCount,
          });

          trackEvent(
            `Divert - API call to submit continue flight details is complete. Status:${continuedLegResult?.status}, Flight number : ${flightNumber}`,
          );
          continuationOperationCompleteCall = headCheckFlightExists(
            flightNumber,
            submitBody.addContinueFlightRequestBody.scheduledOperatingDateUTC,
            submitBody.addContinueFlightRequestBody.actualOrigin,
            submitBody.addContinueFlightRequestBody.actualDestination,
            newDepartureCount,
            submitBody.addContinueFlightRequestBody.operatingAirlineCode,
            submitBody.addContinueFlightRequestBody.opsType,
          );
          headCallsArr.push(continuationOperationCompleteCall);
        }

        // Handle result
        const divertResultIsSuccess = divertResult?.status === 'SUCCESS';
        const continuedLegResultIsSuccess = !isContinuationFlightEnabled() || continuedLegResult?.status === 'SUCCESS';
        const canceledLegResultIsSuccess = !checkedCancelFlight || canceledLegResult?.status === 'SUCCESS';

        if (divertResultIsSuccess && continuedLegResultIsSuccess && canceledLegResultIsSuccess) {
          onCommit(true, 'Divert', '', headCallsArr); //handle toast notifaction SUCCESS
        } else {
          onCommit(false, 'Divert', '', []); //handle toast notifaction ERROR
        }
      } else {
        trackEvent(`Divert - ${IrropFlightStatusErrorMessage.ERROR} Flight number : ${flightNumber}`);
        onCommit(false, 'Divert', IrropFlightStatusErrorMessage.ERROR, []);
      }
    } else {
      trackEvent(`Divert - Failed to get flight details for flight number : ${flightNumber}`);
      onCommit(false, 'Divert', '', []);
    }
  };

  /**
   * Submits a DIV for the current flight.
   * May submit an ADD if continuation flight is checked.
   * May submit a CXL if current flight is multi-leg and canceled flight is checked.
   * Handles the result of requested irrops by invoking callback onCommit.
   */

  /**
   * @description Build the current flight details in array format.
   * @returns Current flight detail blocks in array
   */
  const getCurrentFlightDetailBlocks = () => {
    return getDetailsBlocks(
      IrropsCode.DIVERT_FLIGHT,
      true,
      true,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      origin,
      destination,
      outTime,
      null,
      eta,
    );
  };

  /**
   * @description Build the canceled flight details in array format.
   * @returns Canceled flight detail blocks in array
   */
  const getCanceledFlightDetailBlocks = () => {
    let isActive = !checkedCancelFlight;
    return getDetailsBlocks(
      IrropsCode.DIVERT_FLIGHT,
      false,
      isActive,
      flightDateUtc,
      flightNumber,
      aircraftRegistration,
      canceledFlightData.canceledFlightScheduledOrigin,
      canceledFlightData.canceledFlightScheduledDestination,
      null,
      canceledFlightData.canceledFlightETD,
      formatDateTime(canceledFlightData.canceledFlightETA, 'HH:mm'),
    );
  };

  // Handle Confirm Divert Popup modal close.
  const hideHandleConfirmDivert = () => {
    setShowConfirmPopup(false); // hide confirm popup
    onClose(IrropsCode.DIVERT_FLIGHT);
  };

  /**
   * @description Handle popup container text to show confirmation message
   * @returns Cancelling flight message
   */
  const popupContainerText = () => {
    if (
      canceledFlightData.canceledFlightScheduledOrigin != null &&
      canceledFlightData.canceledFlightScheduledDestination != null
    ) {
      return `Cancelling flight ${canceledFlightData.flightNumber.toString()}, ${
        canceledFlightData.canceledFlightScheduledOrigin
      }-${canceledFlightData.canceledFlightScheduledDestination}?`;
    }
  };

  const submitDivert = async () => {
    await submitDivertFlightV2();
  };
  // Handle Confirm popup on click comment button
  const handleConfirmButtonClick = async () => {
    setShowConfirmPopup(false);
    await submitDivert();
  };

  // Triggers by pressing the enter key
  const handlehandleOnKeyPress = (e) => {
    if (e.charCode === KeyCodes.ENTER) {
      handleConfirmButtonClick();
    }
  };

  return (
    <AocAuthAccessCheck approvedEntitlements={[EntitlementNames.DIVERT, EntitlementNames.SUPPORTVALIDATION]}>
      <IrropsFlightModal
        showModal={show}
        modalTitle="Divert Flight"
        operatingAirlineCode={operatingAirlineCode}
        irropsCode={IrropsCode.DIVERT_FLIGHT}
        showFlightDetailsSection={true}
        flightDetailsTitle="CURRENT FLIGHT DETAILS"
        flightDetails={getCurrentFlightDetailBlocks()}
        showIrropSection={true}
        irropTitle="DIVERSION STATION"
        irropsAutoPopulateStationsLabel="Flight Leg"
        irropStations={{
          origin,
          destination,
          isDestinationEditable: true,
          onChangeDestination: (value, event, target) => handleAirportCodeInputChange(value, event, target),
          warningType: irropStation.isError ? WarningTypes.ERROR : WarningTypes.NONE,
          warningMessage: irropStation.error,
        }}
        irropETA={{
          value: irropETA.hhmm,
          onChange: handleETAInputChange,
          warningType: irropETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: irropETA.error,
        }}
        irropCheckbox={{
          show: isMultiLegFlight,
          label: 'Add continuation flight',
          value: checkedContFlightNeeded,
          onChange: handleChkContFlightNeeded,
        }}
        showFollowFlightSection={isContinuationFlightEnabled()} //{checkedContFlightNeeded}
        followFlightTitle="CONTINUATION FLIGHT"
        followFlightStations={{
          origin: isNullOrWhitespace(irropStation.station) ? '---' : irropStation.station,
          destination,
        }}
        followFlightTurnTime={{
          value: turnTimeMinutes.value,
          onChange: handleTurnTimeTextInputChange,
          onBlur: handleTurnTimeOnBlur,
          warningType: turnTimeMinutes.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: turnTimeMinutes.error,
        }}
        followFlightETD={{
          value: contETD.hhmm,
          onChange: handleContinuationETDTextInputChange,
          warningType: contETD.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: contETD.error,
        }}
        followFlightETA={{
          value: contETA.hhmm,
          onChange: handleContinuationETATextInputChange,
          warningType: contETA.isError ? WarningTypes.WARNING : WarningTypes.NONE,
          errorMessage: contETA.error,
        }}
        showCanceledFlightSection={showCancelFlight}
        canceledFlightTitle="CANCEL  FLIGHT DETAILS"
        canceledFlightDetails={getCanceledFlightDetailBlocks()}
        canceledFlightCheckbox={{
          label: 'Cancel flight',
          value: checkedCancelFlight,
          onChange: handleChkCancelFlight,
        }}
        reason={{
          placeholderText: '',
          reasonText: selectedReason.text,
          commentsTemplate: selectedReason.template,
          isCommentsRequired: selectedReason.isCommentsRequired,
          onSelect: handleOnSelectReason,
        }}
        comments={{ value: userComments, onChange: handleCommentsTextInputChange }}
        isCommitEnabled={isCommitEnabled()}
        commitButtonClicked={commitButtonClicked}
        onCommit={handleCommitButtonClick}
        onClose={handleOnHideIrropsModal}
      />
      <ConfirmationPopup
        showConfirmPopup={showConfirmPopup}
        handleCommitClick={handleConfirmButtonClick}
        handleOnKeyPress={handlehandleOnKeyPress}
        handleHideClick={hideHandleConfirmDivert}
        dataCyTag="cancel-flight"
        title="Confirm Cancel"
        containerText={popupContainerText()}
      />
    </AocAuthAccessCheck>
  );
};

DivertFlight.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withAppInsightsTracking(DivertFlight);
