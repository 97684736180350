import { useDispatch } from 'react-redux';
import { HideFilterActions } from '../../redux/actionTypes';
import { useCallback } from 'react';
import { useSelector } from '../useMultiViewDataStore/useMultiViewDataStore';

/**
 * Custom hook for retrieving hideFilterReducer from the hide store
 * @returns selector hook for retrieving hideFilterReducer
 */
export const useHideStore = () => {
  return useSelector((state) => state.hideFilterReducer);
};

/**
 * Custom hook for dispatching actions to hide cancelled flights
 * @returns object of dispatch actions to interact with the hide store
 */
export const useHideFilterDispatch = () => {
  const dispatch = useDispatch();

  const updateHideCancelledFilter = useCallback(
    (filter) => {
      dispatch({ type: HideFilterActions.TOGGLE_HIDE_CANCELLED_FLIGHTS, payload: { ...filter } });
    },
    [dispatch],
  );

  const updateHideShortTurnAlertsFilter = useCallback(
    (filter) => {
      dispatch({ type: HideFilterActions.TOGGLE_HIDE_SHORT_TURN_ALERTS, payload: { ...filter } });
    },
    [dispatch],
  );

  const updateHideMxMessagesFilter = useCallback(
    (filter) => {
      dispatch({ type: HideFilterActions.TOGGLE_HIDE_MX_MESSAGES, payload: { ...filter } });
    },
    [dispatch],
  );

  return { updateHideCancelledFilter, updateHideShortTurnAlertsFilter, updateHideMxMessagesFilter };
};
