import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { ApiErrorName, DateFormat, PuckType } from '../../../../../lib/constants';
import { formatDateTime } from '../../../../../lib/dateTimeUtils';
import FlightTabSummary from './FlightTabSummary/FlightTabSummary';
import FlightTabStations from './FlightTabStations/FlightTabStations';
import TabSkeleton from '../../../FlightDetailPane/TabSkeleton/TabSkeleton';
import { getRevenueScores } from '../../../../../services/apiClient/scoresApi/scoresApi';
import FlightTabBlockTimesContainer from './FlightTabBlockTimesContainer/FlightTabBlockTimesContainer';
import { useSelectedItemStore } from '../../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import { useFlightSummaryDetails } from '../../../../../hooks/useAirTrakManagerQuery/useAirTrakManagerQuery';

import './SummaryFlightTab.css';
//** SummaryFlightTab component to display the summary details of the selected flight leg
const SummaryFlightTab = () => {
  const [scoreDetails, setScoreDetails] = useState(null);
  const [scoreLoading, setScoreLoading] = useState(true);

  // get the selected flight leg from the store
  const selectedSummaryFlightTab = useSelectedItemStore();
  let summaryFlightLeg = selectedSummaryFlightTab.data;

  // check if the flight panel is open and the flight leg is not null
  let fetchSummaryFlightData = selectedSummaryFlightTab.isFlightPanelOpen && summaryFlightLeg !== null;

  // check if the selected flight needs to query for flight details
  // utilized for flight summary details and revenue API calls
  const shouldRetrieveData = ![PuckType.GROUND_STANDBY, PuckType.GROUND_OTS].includes(summaryFlightLeg?.puckType);

  // get flight details from the API
  const { isLoading: loading, data: flightDetailsData } = useFlightSummaryDetails({
    flightNumber: summaryFlightLeg?.flightNumber,
    origin: summaryFlightLeg?.orig,
    destination: summaryFlightLeg?.dest,
    departureCount: summaryFlightLeg?.departureCount,
    airline: summaryFlightLeg?.airline,
    scheduledOperatingDate: formatDateTime(summaryFlightLeg?.departureDate, DateFormat.DEFAULT),
    shouldRetrieveData,
  });

  // state to store the score details of the flight leg and the loading state of the score details API
  useEffect(() => {
    setScoreLoading(true);
    const fetchRevenueScores = async () => {
      let response = null;
      if (shouldRetrieveData) {
        response = await getRevenueScores([{ ...summaryFlightLeg }]);
      }
      setScoreDetails(response);
      setScoreLoading(false);
    };

    if (fetchSummaryFlightData) {
      fetchRevenueScores();
    }
  }, [summaryFlightLeg, fetchSummaryFlightData, shouldRetrieveData]);

  // check if the flight details are loaded and the flight panel is open
  let renderSummaryFlightTabLog = !loading && fetchSummaryFlightData && flightDetailsData !== null;
  let flightTabDetails = {};
  if (renderSummaryFlightTabLog) {
    flightTabDetails = flightDetailsData?.flightDetails;
  }

  let isSeatCountDataErr = false;
  let isDispatchDeskDataErr = false;
  let isGateErr = false;
  flightDetailsData?.errors?.forEach((err) => {
    switch (err?.errorName) {
      case ApiErrorName.SEAT_COUNT_DATA:
        isSeatCountDataErr = true;
        break;
      case ApiErrorName.DISPATCH_DESK_DATA:
        isDispatchDeskDataErr = true;
        break;
      case ApiErrorName.GATE_DATA:
        isGateErr = true;
        break;
      default:
        break;
    }
  });

  // get the scores of the flight leg from the score details API  and the count of the scores
  let count = scoreDetails?.flightScores?.length;
  let scores = count > 0 ? scoreDetails.flightScores[0]?.scores : null;

  return (
    <div className="summary-flight-tab-container" data-cy="summary-flight-tab-container">
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
          {loading ? (
            <TabSkeleton />
          ) : (
            <FlightTabSummary
              flightDetails={flightTabDetails}
              isSeatCountDataErr={isSeatCountDataErr}
              isDispatchDeskDataErr={isDispatchDeskDataErr}
            />
          )}
        </Grid>
        <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
          {loading ? (
            <TabSkeleton />
          ) : (
            <FlightTabBlockTimesContainer
              blockTimesDetails={flightTabDetails?.blockTimes}
              scoreDetails={scores}
              scoreLoading={scoreLoading}
            />
          )}
        </Grid>
        <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
          {loading ? (
            <TabSkeleton />
          ) : (
            <FlightTabStations
              title="DEPARTURE"
              station={flightTabDetails?.departure?.station}
              event1={'OUT'}
              event2={'OFF'}
              times={flightTabDetails?.departure}
              gateInfo={isGateErr ? 'Err' : flightTabDetails?.departure?.gate ?? 'N/A'}
              flightDetails={flightTabDetails?.departure}
            />
          )}
        </Grid>
        <Grid item sm={12} xs={12} md={6} lg={3} xl={3}>
          {loading ? (
            <TabSkeleton />
          ) : (
            <FlightTabStations
              title="ARRIVAL"
              station={flightTabDetails?.arrival?.station ?? ''}
              event1={'ON'}
              event2={'IN'}
              times={flightTabDetails?.arrival}
              gateInfo={isGateErr ? 'Err' : flightTabDetails?.arrival?.gate ?? 'N/A'}
              flightDetails={flightTabDetails?.arrival}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

SummaryFlightTab.propTypes = {
  summaryFlightTabDetails: PropTypes.object,
};

export default withAppInsightsTracking(SummaryFlightTab);
