import { FlightView, RemInPixels, ViewWindowTitle } from '../../lib/constants';
import { WindowManagerAction } from '../actionTypes';
import { calculateViewWindows } from '../../lib/multipleViewUtils';

// Default viewWindow object
const defaultViewWindow = {
  id: 0,
  viewTitle: ViewWindowTitle.DEFAULT,
  viewType: FlightView.GANTT_VIEW,
  isViewFromConfig: false, // Flag to indicate if the view is from saved user config
  viewPosition: { x: 0, y: 0 },
  viewSize: { width: 800, height: 600 },
};

const getInitialState = () => {
  // Initialize viewWindows with values from sessionStorage if available
  const viewWindowsFromSessionStorage = JSON.parse(sessionStorage.getItem('windowManagerStore'));
  if (viewWindowsFromSessionStorage) {
    return viewWindowsFromSessionStorage;
  }
  return {
    viewWindows: [defaultViewWindow],
    viewOrder: [0],
    minimizedViews: [],
    maximizedViews: [],
    draggedMinimizedViews: [],
  };
};

// Helper function to get view container dimensions
const getViewContainerDimensions = () => {
  const viewContainerElement = document.getElementsByClassName('multiple-views-container');
  const viewContainerWidth = viewContainerElement ? parseInt(getComputedStyle(viewContainerElement[0]).width) : 0;
  const viewContainerHeight = viewContainerElement ? parseInt(getComputedStyle(viewContainerElement[0]).height) : 0;
  return { viewContainerWidth, viewContainerHeight };
};

/**
 * Handles the arrangement of more than 12 view windows.
 *
 * @param {Array} viewWindows - The array of view windows to be arranged.
 * @param {number} viewContainerWidth - The width of the container holding the view windows.
 * @param {number} viewContainerHeight - The height of the container holding the view windows.
 * @param {number} columns - The number of columns to arrange the view windows in.
 * @returns {Array} - The array of arranged view windows.
 */
const handleMoreThan12Windows = (viewWindows, viewContainerWidth, viewContainerHeight, columns) => {
  const { width: defaultWidth, height: defaultHeight } = defaultViewWindow.viewSize;
  const toolbarHeight = getToolbarHeight();
  const viewWindowsList_1 = calculateViewWindows(
    viewWindows.slice(0, 12),
    viewContainerWidth,
    viewContainerHeight,
    columns,
  );
  const viewWindowsListData_2 = viewWindows.slice(12);
  const viewWindowsList_2 = viewWindowsListData_2.map((window, index) => {
    const aircraftLabelWidth = getWindowOffset(
      false,
      viewWindowsListData_2.slice(0, index).map((window) => window.id),
    );
    return {
      ...window,
      viewPosition: { x: aircraftLabelWidth * index, y: index * toolbarHeight },
      viewSize: { width: defaultWidth, height: defaultHeight },
    };
  });
  return viewWindowsList_1.concat(viewWindowsList_2);
};

/**
 * Arranges view windows in a grid based on the number of windows and screen orientation.
 *
 * @param {Array} viewWindows - An array of view window objects to be arranged.
 * @param {string} screenOrientation - The orientation of the screen, either 'portrait-primary' or other.
 * @returns {Array} - An array of view window objects with updated positions and sizes.
 */
const arrangeViewsGridsHandler = (viewWindows, screenOrientation) => {
  const { viewContainerWidth, viewContainerHeight } = getViewContainerDimensions();

  if (screenOrientation === 'portrait-primary') {
    if (viewWindows.length === 2) {
      return calculateViewWindows(viewWindows, viewContainerWidth, viewContainerHeight / 2, 1);
    } else if (viewWindows.length >= 3 && viewWindows.length <= 4) {
      return calculateViewWindows(viewWindows, viewContainerWidth / 2, viewContainerHeight / 2, 2);
    } else if (viewWindows.length >= 5 && viewWindows.length <= 6) {
      return calculateViewWindows(viewWindows, viewContainerWidth / 2, viewContainerHeight / 3, 2);
    } else if (viewWindows.length >= 7 && viewWindows.length <= 8) {
      return calculateViewWindows(viewWindows, viewContainerWidth / 2, viewContainerHeight / 4, 2);
    } else if (viewWindows.length >= 9 && viewWindows.length <= 10) {
      return calculateViewWindows(viewWindows, viewContainerWidth / 2, viewContainerHeight / 5, 2);
    } else if (viewWindows.length >= 11 && viewWindows.length <= 12) {
      return calculateViewWindows(viewWindows, viewContainerWidth / 2, viewContainerHeight / 6, 2);
    } else if (viewWindows.length > 12) {
      // This is an extreme use case: if there are more than 12 view windows, apply a grid layout for the first 12, and arrange the remaining windows in cascade
      return handleMoreThan12Windows(viewWindows, viewContainerWidth / 2, viewContainerHeight / 6, 2);
    } else {
      return viewWindows;
    }
  }

  if (viewWindows.length === 2) {
    return calculateViewWindows(viewWindows, viewContainerWidth / 2, viewContainerHeight, 2);
  } else if (viewWindows.length >= 3 && viewWindows.length <= 4) {
    return calculateViewWindows(viewWindows, viewContainerWidth / 2, viewContainerHeight / 2, 2);
  } else if (viewWindows.length >= 5 && viewWindows.length <= 6) {
    return calculateViewWindows(viewWindows, viewContainerWidth / 3, viewContainerHeight / 2, 3);
  } else if (viewWindows.length >= 7 && viewWindows.length <= 8) {
    return calculateViewWindows(viewWindows, viewContainerWidth / 4, viewContainerHeight / 2, 4);
  } else if (viewWindows.length >= 9 && viewWindows.length <= 10) {
    return calculateViewWindows(viewWindows, viewContainerWidth / 5, viewContainerHeight / 2, 5);
  } else if (viewWindows.length >= 11 && viewWindows.length <= 12) {
    return calculateViewWindows(viewWindows, viewContainerWidth / 6, viewContainerHeight / 2, 6);
  } else if (viewWindows.length > 12) {
    // This is an extreme use case: if there are more than 12 view windows, apply a grid layout for the first 12, and arrange the remaining windows in cascade
    return handleMoreThan12Windows(viewWindows, viewContainerWidth / 6, viewContainerHeight / 2, 6);
  } else {
    return viewWindows;
  }
};

const getToolbarHeight = () => {
  const toolbarElement = document.getElementById('toolbar');
  return toolbarElement ? parseInt(getComputedStyle(toolbarElement).height) : 0;
};

const getWindowOffset = (offsetFirstView = false, ids = null) => {
  let viewWindowElements = [];
  if (ids) {
    ids.forEach((id) => {
      const viewWindowElement = document.getElementById(`view-window-${id}`);
      if (viewWindowElement) {
        viewWindowElements.push(viewWindowElement);
      }
    });
  } else {
    viewWindowElements = Array.from(document.getElementsByClassName(`view-window`));
  }
  const aircraftLabelWidth = viewWindowElements.reduce((acc, viewWindowElement, index) => {
    const isTailIndicatorsEnabled = viewWindowElement?.classList?.contains('tail-indicators-enabled');
    const aircraftLabelWidthRem = getComputedStyle(viewWindowElement).getPropertyValue(
      isTailIndicatorsEnabled ? '--aircraft-label-width-with-indicators' : '--aircraft-container-width',
    );
    let aircraftLabelWidth = parseFloat(aircraftLabelWidthRem) * RemInPixels.ONE_REM_IN_PX;
    if (isTailIndicatorsEnabled) {
      aircraftLabelWidth += RemInPixels.ONE_REM_IN_PX + 4;
    }

    if (index === 0 && offsetFirstView) {
      aircraftLabelWidth += aircraftLabelWidth;
    }

    return acc + aircraftLabelWidth;
  }, 0);
  return aircraftLabelWidth;
};

// Reducer function
function windowManagerReducer(state = getInitialState(), action) {
  switch (action.type) {
    case WindowManagerAction.ADD_VIEW_WINDOW: {
      const newId = state.viewWindows.reduce((acc, curr) => (curr.id >= acc ? curr.id + 1 : acc), 0);
      const toolbarHeight = getToolbarHeight();
      const aircraftLabelWidth = getWindowOffset(true);
      const newViewTitle = `${ViewWindowTitle.DEFAULT} (${newId})`;
      const newXPosition = aircraftLabelWidth;
      const numberOfViews = state.viewWindows.length;
      const newYPosition = numberOfViews > 1 ? toolbarHeight * (numberOfViews + 1) : toolbarHeight * 2;

      const newViewWindow = {
        ...defaultViewWindow,
        viewPosition: { x: newXPosition, y: newYPosition },
        viewTitle: newViewTitle,
        id: newId,
      };
      return {
        ...state,
        viewWindows: [...state.viewWindows, newViewWindow],
        viewOrder: [newId, ...state.viewOrder],
        maximizedViews: state.viewWindows.length == 1 ? [state.viewWindows[0].id] : state.maximizedViews,
      };
    }

    case WindowManagerAction.REMOVE_VIEW_WINDOW: {
      const updatedViewWindows = state.viewWindows.filter((view) => view.id !== action.id);
      const updatedViewOrder = state.viewOrder.filter((id) => id !== action.id);
      const updatedMinimizedViews = state.minimizedViews.filter((id) => id !== action.id);
      let updatedMaximizedViews = state.maximizedViews.filter((id) => id !== action.id);
      const updatedDraggedMinimizedViews = state.draggedMinimizedViews.filter((id) => id !== action.id);
      return {
        ...state,
        viewWindows: updatedViewWindows,
        viewOrder: updatedViewOrder,
        minimizedViews: updatedMinimizedViews,
        maximizedViews: updatedMaximizedViews,
        draggedMinimizedViews: updatedDraggedMinimizedViews,
      };
    }

    case WindowManagerAction.UPDATE_VIEW_WINDOW: {
      const updatedWindows = state.viewWindows.map((window) =>
        window.id === action.id ? { ...window, ...action.payload } : window,
      );
      return {
        ...state,
        viewWindows: updatedWindows,
      };
    }

    case WindowManagerAction.SET_ACTIVE_VIEW: {
      const newViewOrder = state.viewOrder.filter((id) => id !== action.id);
      return {
        ...state,
        viewOrder: [action.id, ...newViewOrder],
      };
    }

    case WindowManagerAction.SET_MINIMIZED_VIEW:
      const newStateAfterSetMinimized = {
        ...state,
        minimizedViews: [...state.minimizedViews, action.id],
      };
      return newStateAfterSetMinimized;

    case WindowManagerAction.REMOVE_MINIMIZED_VIEW: {
      const newStateAfterRemoveMinimized = {
        ...state,
        minimizedViews: state.minimizedViews.filter((id) => id !== action.id),
        draggedMinimizedViews: state.draggedMinimizedViews.filter((id) => id !== action.id),
      };
      return newStateAfterRemoveMinimized;
    }

    case WindowManagerAction.SET_DRAGGED_MINIMIZED_VIEW: {
      const newStateAfterSetDraggedMinimized = {
        ...state,
        minimizedViews: state.minimizedViews.filter((id) => id !== action.id),
        draggedMinimizedViews: [...state.draggedMinimizedViews.filter((id) => id !== action.id), action.id],
      };
      return newStateAfterSetDraggedMinimized;
    }

    case WindowManagerAction.SET_MAXIMIZED_VIEW: {
      const newStateAfterSetMaximized = {
        ...state,
        maximizedViews: [...state.maximizedViews, action.id],
      };
      return newStateAfterSetMaximized;
    }

    case WindowManagerAction.REMOVE_MAXIMIZED_VIEW: {
      const newStateAfterRemoveMaximized = {
        ...state,
        maximizedViews: state.maximizedViews.filter((id) => id !== action.id),
      };
      return newStateAfterRemoveMaximized;
    }

    case WindowManagerAction.RENAME_VIEW_WINDOW: {
      const renameWindow = state.viewWindows.map((window) =>
        window.id === action.id ? { ...window, ...action.payload } : window,
      );
      return {
        ...state,
        viewWindows: renameWindow,
      };
    }

    case WindowManagerAction.ARRANGE_VIEWS_CASCADE: {
      const { width: defaultWidth, height: defaultHeight } = defaultViewWindow.viewSize;
      const sortedViewOrder = state.viewOrder.sort((a, b) => b - a);
      const toolbarHeight = getToolbarHeight();
      return {
        ...state,
        viewOrder: sortedViewOrder,
        minimizedViews: [],
        maximizedViews: [],
        draggedMinimizedViews: [],
        viewWindows: state.viewWindows.map((window, index) => {
          const aircraftLabelWidth = getWindowOffset(false, [...sortedViewOrder].reverse().slice(0, index));
          return {
            ...window,
            viewPosition: { x: aircraftLabelWidth, y: index * toolbarHeight },
            viewSize: { width: defaultWidth, height: defaultHeight },
          };
        }),
      };
    }

    case WindowManagerAction.ARRANGE_VIEWS_GRID: {
      return {
        ...state,
        viewOrder: state.viewOrder.sort((a, b) => b - a),
        minimizedViews: [],
        maximizedViews: [],
        draggedMinimizedViews: [],
        viewWindows: arrangeViewsGridsHandler(state.viewWindows, action.payload.screenOrientation),
      };
    }

    case WindowManagerAction.ARRANGE_VIEWS_COLUMNS: {
      const { viewContainerWidth, viewContainerHeight } = getViewContainerDimensions();
      return {
        ...state,
        viewOrder: state.viewOrder.sort((a, b) => b - a),
        minimizedViews: [],
        maximizedViews: [],
        draggedMinimizedViews: [],
        viewWindows: calculateViewWindows(
          state.viewWindows,
          viewContainerWidth / state.viewWindows.length,
          viewContainerHeight,
        ),
      };
    }

    case WindowManagerAction.ARRANGE_VIEWS_ROWS: {
      const { viewContainerWidth, viewContainerHeight } = getViewContainerDimensions();
      return {
        ...state,
        viewOrder: state.viewOrder.sort((a, b) => b - a),
        minimizedViews: [],
        maximizedViews: [],
        draggedMinimizedViews: [],
        viewWindows: calculateViewWindows(
          state.viewWindows,
          viewContainerWidth,
          viewContainerHeight / state.viewWindows.length, // Adjusted for rows
          1,
        ),
      };
    }

    case WindowManagerAction.SAVE_VIEWS_CONFIG: {
      return {
        ...state,
        viewWindows: state.viewWindows.map((viewWindow) => {
          return {
            ...viewWindow,
            isViewFromConfig: true,
          };
        }),
      };
    }

    default:
      return state;
  }
}

export default windowManagerReducer;
