import { ViewConfigurationAction } from '../actionTypes';
import { SessionStorageKeys } from '../../lib/constants';
import { getSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';
import { isNullOrUndefined } from '../../lib/utils';

// initial state values
export const initialViewConfigState = {
  defaultConfig: true,
  puckSize: null,
  timelineHours: 48,
  numberOfAircrafts: 25,
  hoursAfter: 32,
  hoursBefore: 4,
};

const getInitialState = (id) => {
  const savedViewConfigurationState = getSessionStorageItem(SessionStorageKeys.VIEW_CONFIGURATION);
  let savedViewConfiguration;
  if (
    !isNullOrUndefined(id) &&
    savedViewConfigurationState &&
    Object.keys(savedViewConfigurationState).includes(id.toString())
  ) {
    savedViewConfiguration = savedViewConfigurationState[id];
  } else if (isNullOrUndefined(id) && savedViewConfigurationState) {
    savedViewConfiguration = savedViewConfigurationState;
  }

  if (savedViewConfiguration) {
    return savedViewConfiguration;
  }

  return initialViewConfigState;
};

/**
 * Given the previous state and action payload return the new state for config mode
 * @returns - the initial state for the config store
 */

const viewConfigurationReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case 'INIT': {
      return getInitialState(action.id);
    }
    case ViewConfigurationAction.UPDATE_VIEWCONFIGURATION: {
      const { payload } = action;
      if (!Object.keys(state).find((key) => state[key] !== payload[key])) {
        return state;
      }
      return {
        ...state,
        defaultConfig: false,
        ...action?.payload,
      };
    }
    default:
      return state;
  }
};

export default viewConfigurationReducer;
