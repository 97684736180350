import { CrosshairActionKeys } from '../actionTypes';
import { SessionStorageKeys } from '../../lib/constants';
import { getSessionStorageItem } from '../../lib/sessionStorage/sessionStorage';
import { isNullOrUndefined } from '../../lib/utils';

// initial state values
const initialState = {
  isCrosshairActive: false,
  crosshairCount: 1,
  activeCrosshair: 0,
  crosshairPositions: [],
};

const getInitialState = (id) => {
  const savedCrosshairState = getSessionStorageItem(SessionStorageKeys.CROSSHAIR);
  let savedState;
  if (!isNullOrUndefined(id) && savedCrosshairState?.[id]) {
    savedState = savedCrosshairState[id];
  }
  return savedState || initialState;
};

/**
 * Given the previous state and action payload return the new state for crosshair mode
 * @returns - the initial state for the crosshair store
 */

const crosshairReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case 'INIT': {
      return getInitialState(action.id);
    }
    case CrosshairActionKeys.UPDATE_CROSSHAIR_MODE:
      return {
        ...state,
        isCrosshairActive: action.payload.isCrosshairActive ?? initialState.isCrosshairActive,
      };
    case CrosshairActionKeys.UPDATE_CROSSHAIR_COUNT:
      return {
        ...state,
        crosshairCount: action.payload.crosshairCount ?? initialState.crosshairCount,
      };
    case CrosshairActionKeys.UPDATE_ACTIVE_CROSSHAIR:
      return {
        ...state,
        activeCrosshair: action.payload.activeCrosshair ?? initialState.activeCrosshair,
      };
    case CrosshairActionKeys.UPDATE_CROSSHAIR_POSITION: {
      const updatedPositions = state.crosshairPositions?.filter((_, index) => index !== action.payload.index) || [];
      updatedPositions[action.payload.index] = action.payload.coords;
      return {
        ...state,
        crosshairPositions: updatedPositions,
      };
    }
    case CrosshairActionKeys.CLEAR_CROSSHAIR_STORE:
      return initialState;
    default:
      return state;
  }
};

export default crosshairReducer;
