import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// This hook is used to manage the errors that are returned from the Airtrak Manager API.
const useAirtrackManagerError = (errors, getErrorDataKey) => {
  const [error, setError] = useState({});
  const createErrorMap = () => {
    const errorMap = {};
    for (const error of errors) {
      if (!error.errorData) {
        if (!errorMap[error.errorName]) {
          errorMap[error.errorName] = error;
        } else {
          console.debug(`${error.errorName} already exist in the errors.`);
        }
        continue;
      }

      if (!errorMap[error.errorName]) {
        errorMap[error.errorName] = new Map();
      }

      const errorDataMap = errorMap[error.errorName];
      const key = getErrorDataKey(error);
      errorDataMap.getByKey = (key) => {
        return errorDataMap.get(JSON.stringify(key));
      };
      errorDataMap.set(JSON.stringify(key), error);
    }

    return errorMap;
  };

  useEffect(() => {
    const errorMap = createErrorMap();
    setError(errorMap);
  }, [errors]);

  return {
    error,
  };
};

useAirtrackManagerError.propTypes = {
  errors: PropTypes.array.isRequired,
  getErrorDataKey: PropTypes.func.isRequired,
};

export default useAirtrackManagerError;
