import React from 'react';
import PropTypes from 'prop-types';
import { ThemeMode } from '../../../lib/constants';
import { jsonEqual } from '../../../lib/utils';
import { getDatetimeUtc, getTimeDifference } from '../../../lib/dateTimeUtils';
import './StationPuck.css';
import { useThemeContext } from '../../../contexts/ThemeContext/ThemeContext';
import { getStationLabelColor } from '../GanttChart/ganttHelpers';

/**
 * Renders a ground puck using the given ground puck data and positions the puck
 * with respect to the gantt start time.
 *
 * A ground puck be a station label to represent turn time between flights,
 * **TODO NEW** or a standby ground event. Once we start feeding standby data, we should
 * extract out standby ground events into its own component or merge with GroundPuck
 * @param {Object} props
 * @param {Object} props.data - Ground puck data to be rendered
 * @returns - A StationPuck component
 */
const StationPuck = React.memo(
  ({ data }) => {
    // calculate the width
    const arrival = getDatetimeUtc(data.arrival);
    const departure = data.departure != null ? getDatetimeUtc(data.departure) : null;
    const duration = departure != null ? getTimeDifference(arrival, departure, 'minutes') : 0;
    const { currentTheme, businessRulesStyles } = useThemeContext();

    /**
     * @description Handle a click event. Logs some information to the console.
     */
    const handleClick = () => {
      console.log(
        `aircraft: ${data.aircraft}\nduration: ${duration}m\narrival station: ${data.arrivalStation}\narrival: ${
          arrival != null ? arrival.format() : '(null)'
        }\ndeparture station: ${data.departureStation}\ndeparture: ${departure}`,
      );
    };

    /**
     * @description Logic for determining the display name.
     */
    const getGroundPuckLabel = () => {
      if (data.arrivalStation === null) return data.departureStation;

      if (data.departureStation === null) return data.arrivalStation;

      if (data.arrivalStation !== data.departureStation) return `${data.arrivalStation} / ${data.departureStation}`;

      return data.arrivalStation ?? data.departureStation;
    };

    /**
     * @description Logic for the text-align CSS value for this puck.
     */
    const textAlignmentValue = () => {
      // First ground time label at the far-left before the first flight puck.
      if (data.arrivalStation === null && data.departureStation != null) return 'right';
      // Last ground time label at the far-right after the last flight puck.
      if (data.departureStation === null && data.arrivalStation != null) return 'left';
      // Flight pucks overlap (arriving after next departure).
      // Label needs some padding-left since the label will be too small
      // for proper spacing with the next flight puck wrapping to the next row.
      if (arrival >= departure) return 'left';

      if (data.cancelledFlag) return 'center';

      return 'center';
    };

    /**
     * @description Logic for the padding-left CSS value for this puck.
     */
    const paddingValue = (position) => {
      // Default to 0rem if position isn't provided.
      if (!position) return '0rem';

      const value = textAlignmentValue();

      return value === position ? '2rem' : '0rem';
    };

    /**
     * @description Determine the CSS class name(s) to use for the ground puck.
     */
    const getGroundPuckClassNames = () => {
      const stationDisplayText = getGroundPuckLabel();
      let groundPuckClassName = 'ground-puck';

      // Default if nothing to check.
      if (!stationDisplayText) return groundPuckClassName;

      if (stationDisplayText.includes('/') && !data.cancelledFlag) {
        // Add the mismatch class name if arrival and departure stations don't match,
        // and only if it's not a ground puck for a canceled puck
        groundPuckClassName += ' ground-puck-mismatch-stations';
      } else {
        groundPuckClassName += ' station';
      }

      return groundPuckClassName;
    };

    // set width and additional styles to puck
    const puckStyle = {
      width: duration > 0 ? `calc(${duration} * var(--time-scale-minute))` : null,
      textAlign: `${textAlignmentValue()}`,
      paddingLeft: `${paddingValue('left')}`,
      paddingRight: `${paddingValue('right')}`,
    };

    if (
      [ThemeMode.CLASSIC, ThemeMode.HIGH_CONTRAST].includes(currentTheme) &&
      businessRulesStyles?.airportLabelColors
    ) {
      const station = getGroundPuckLabel();
      if (station && !station.includes('/')) {
        puckStyle.color = getStationLabelColor(station, businessRulesStyles.airportLabelColors);
      }
    }

    return (
      <div
        className={getGroundPuckClassNames()}
        style={puckStyle}
        data-cy={`ground-puck-${data.aircraft}-${data.index}`}
        onClick={handleClick}
      >
        {getGroundPuckLabel()}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return jsonEqual(prevProps.data, nextProps.data);
  },
);

StationPuck.propTypes = {
  data: PropTypes.shape({
    groundFlag: PropTypes.bool.isRequired,
    arrivalStation: PropTypes.string,
    arrival: PropTypes.string,
    departureStation: PropTypes.string,
    departure: PropTypes.string,
    aircraft: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    cancelledFlag: PropTypes.bool.isRequired,
  }),
};

export default StationPuck;
