import React, { useState } from 'react';
import Button from '../Shared/Button/Button';
import Modal from '../Shared/Modal/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './EquipmentChangeTool.css';
import BrickContainer from '../Shared/BrickContainer/BrickContainer';
import CertificateIcon from './CertificateIcon';
import { useEquipmentChange } from '../../hooks/useAirTrakManagerQuery/useAirTrakManagerQuery';
import { useRoleAssignmentContext } from '../../contexts/RoleAssignmentContext/RoleAssignmentContext';
import { RoleNames, KeyCodes } from '../../lib/constants';
import {
  postEquipmentChangeAck,
  postEquipmentChangeAckTail,
  deleteEquipmentChangeAck,
} from '../../services/apiClient/airTrakManagerApi/airTrakManagerApi';
import { useSeatMapsQuery } from '../../hooks/useReferenceQuery/useReferenceAlertsQuery';
import { useAppInsightsContext } from '../../contexts/AppInsightsContext/AppInsightsContext';
import { getUtcStartEndDateTimeForOperationalTz } from '../../lib/dateTimeUtils';

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: 14,
    fontWeight: '800',
    color: 'var(--page-container-text)',
  },
  sectionHeaderGrid: {
    paddingTop: '0.125rem !important',
    paddingBottom: '0.34375rem !important',
  },
}));

/**
 * The EquipmentChangeTool component handle the Equipment Tool
 * @param {func} onClose - function to handle the closing the modal
 * @param {Object} selectedFlightLeg - selected flight Leg data form action menu
 * @returns Equipment Change Tool modal
 */

const EquipmentChangeTool = ({
  onClose,
  onCommit,
  selectedFlightLeg,
  isEquipmentChange,
  isEquipmentChangeAcknowledgment,
}) => {
  const classes = useStyles();
  // useEquipmentChange hook
  const { data: getEquipmentChangeData } = useEquipmentChange(selectedFlightLeg);
  // useSeatMapsQuery hook
  const { data: getSeatMapsData } = useSeatMapsQuery(selectedFlightLeg?.operatingAirlineCode);
  // useAppInsightsContext hook

  const { trackEvent } = useAppInsightsContext();
  // use State
  const [ackFlightButtonClicked, setAckFlightButtonClicked] = useState(false);
  const [ackAircraftButtonClicked, setAckAircraftButtonClicked] = useState(false);

  // useRoleAssignmentContext hook
  const { roleAssignments } = useRoleAssignmentContext();

  // selectedFlightLeg
  const { origin, destination, flightNumber, scheduledOperatingDate, aircraftRegistration, departure } =
    selectedFlightLeg;

  // EquipmentChange data
  const equipmentChangeData = getEquipmentChangeData?.equipmentChange;

  // currentFlightDetails
  const currentFlightDetails = [
    { title: 'Flight', value: flightNumber },
    { title: 'Scheduled Fleet', value: equipmentChangeData?.scheduledFleetType || '--' },
    { title: 'Zulu Date', value: scheduledOperatingDate },
    { title: 'Assigned Fleet', value: equipmentChangeData?.assignedFleetType || '--' },
    { title: 'Origin', value: origin },
    { title: 'Destination', value: destination },
  ];

  // seat count for reference TableHeader
  const SeatCountReferenceTableHeader = [
    { header: 'Fleet', className: 'fleet' },
    { header: 'F', className: 'first-class' },
    { header: 'P', className: 'premium-class' },
    { header: 'Y', className: 'coach-class' },
  ];

  // Handle Ack Flight button click event
  const ackFlightHandler = async () => {
    setAckFlightButtonClicked(true);
    const postApiResult = await postEquipmentChangeAck(selectedFlightLeg);
    trackEvent(`Equipment change tool- API call to submit acknowledge. Status:${postApiResult?.status}`);
    const postApiResultIsSuccess = postApiResult?.status == 'Success';

    if (postApiResultIsSuccess) {
      onCommit(true, 'Ack Flight');
    } else {
      onCommit(false, 'Ack Flight');
    }
    onClose(); // Hide the modal
  };

  // Hitting Enter on Ack Flight button triggers
  const ackFlightHandleKeyPress = (e) => {
    // It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && !isButtonEnabled()) {
      ackFlightHandler();
    }
  };

  // Handle remove flight ack button click event
  const removeFlightAckHandler = async () => {
    setAckFlightButtonClicked(true);
    const deleteApiResult = await deleteEquipmentChangeAck(selectedFlightLeg);
    trackEvent(`Equipment changes tool - API call to Remove Flight Ack. Status:${deleteApiResult?.status}`);
    const deleteApiResultSuccess = deleteApiResult?.status == 'Success';

    if (deleteApiResultSuccess) {
      onCommit(true, 'Remove Flight Ack');
    } else {
      onCommit(false, 'Remove Flight Ack');
    }
    onClose(); // Hide the modal
  };

  // Hitting Enter Key on Remove Flight Ack button
  const removeFlightAckHandleKeyPress = (e) => {
    // It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && !isButtonEnabled()) {
      removeFlightAckHandler();
    }
  };

  // Hitting Enter Key on Ack Aircraft For Day button
  const ackTailHandlerHandleKeyPress = (e) => {
    // It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER) {
      ackTailHandler();
    }
  };

  // isButtonEnabled handler
  const isButtonEnabled = () => {
    return (
      roleAssignments?.role?.name?.includes(RoleNames.AOC_DELIVERY_SUPPORT) ||
      !isEquipmentChange ||
      ackFlightButtonClicked ||
      ackAircraftButtonClicked
    );
  };

  const ackTailHandler = async () => {
    const { startDate, endDate } = getUtcStartEndDateTimeForOperationalTz(departure);

    const ackTailData = {
      aircraftRegistration,
      startDateTimeUTC: startDate,
      endDateTimeUTC: endDate,
    };

    setAckAircraftButtonClicked(true);
    const postApiResult = await postEquipmentChangeAckTail(ackTailData);
    trackEvent(`Equipment change tool- API call to submit acknowledge for tail. Status:${postApiResult?.status}`);
    const postApiResultIsSuccess = postApiResult?.status == 'Success';

    const successMsg = `Acknowledge for day submitted for aircraft ${aircraftRegistration}`;
    const failMsg = `Failed to submit acknowledge for day for aircraft ${aircraftRegistration}`;

    onCommit(postApiResultIsSuccess, 'Ack Aircraft For Day', postApiResultIsSuccess ? successMsg : failMsg);

    onClose(); // Hide the modal
  };

  // formHTML of modal
  const formHtml = (
    <div className="equipment-tool-modal-form" data-cy="equipment-tool-modal-form">
      <Grid container spacing={2}>
        <Grid item xs={5} className={classes.sectionHeaderGrid}>
          <TableContainer className="equipment-change-table-container" data-cy="equipment-change-table-container">
            <Table className="equipment-change-table">
              <TableHead className="equipment-change-table-head reference-head">
                <TableCell colSpan={4}>Reference Table</TableCell>
              </TableHead>
              <TableHead className="equipment-change-table-head">
                <TableRow>
                  {SeatCountReferenceTableHeader.map((col) => (
                    <TableCell
                      key={col}
                      data-cy={`equipment-change-table-header-${col.className}`}
                      className={`th-${col.className}`}
                    >
                      {col.header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody data-cy="equipment-change-table-body" className="equipment-change-table-body">
                {getSeatMapsData?.seatmaps
                  ?.filter((seat) => selectedFlightLeg?.operatingAirlineCode == seat?.airlineCode)
                  ?.map((row, i) => (
                    <TableRow
                      key={`table-row-${i}`}
                      className={`equipment-change-table-body-${i}`}
                      data-cy={`equipment-change-table-body-${i}`}
                    >
                      <TableCell key={`row-cell-${i}`} className={`td-${row.scheduleType}`}>
                        {row.scheduleType}
                      </TableCell>
                      <TableCell key={`row-cell-${i}`} className={`td-${row.zoneFCapacity}`}>
                        {row.zoneFCapacity}
                      </TableCell>
                      <TableCell key={`row-cell-${i}`} className={`td-${row.zonePCapacity}`}>
                        {row.zonePCapacity}
                      </TableCell>
                      <TableCell key={`row-cell-${i}`} className={`td-${row.zoneYCapacity}`}>
                        {row.zoneYCapacity}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={7} className="equipment-change-tool-left">
          <Grid item className="equipment-change-details-container">
            {currentFlightDetails.map((val, idx) => (
              <Grid item key={`fd-g-${idx}`} className={`cfd-child`} data-cy={`cfd-child-${val.value}`}>
                <BrickContainer title={val.title} customCSSTag={`equipment-data-${val.title}`}>
                  <Grid>{val.value}</Grid>
                </BrickContainer>
              </Grid>
            ))}
          </Grid>
          <div className="equipment-tool-modal-form-section-separator"></div>
          <Grid item xs={12} className="equipment-change-details-certificates-satcom-container">
            <Grid item className="equipment-change-details certificates-container">
              <Grid className="cetificates-header">Certificates</Grid>
              <div className="equipment-change-details-items-container">
                {equipmentChangeData?.temporaryAircraftRegistration ? (
                  <div className="equipment-change-certificate-item temp-certificate-container">
                    {/* should show check icons as per the data check */}
                    <CertificateIcon status="applicableIcon" />
                    <div className="label" data-cy="temp-certificate">
                      Temp Certificate
                    </div>
                  </div>
                ) : (
                  <div className="equipment-change-certificate-item aircraft-registration-container">
                    {/* should show check icons as per the data check */}
                    <CertificateIcon status="certificateIcon" />
                    <div className="label" data-cy="aircraft-registration">
                      Aircraft Registration
                    </div>
                  </div>
                )}
                <div className="equipment-change-certificate-item foreign-documents-container">
                  {equipmentChangeData?.foreignDocumentsMissing ? (
                    <CertificateIcon status="notApplicableIcon" />
                  ) : (
                    <CertificateIcon status="certificateIcon" />
                  )}
                  <div className="label" data-cy="foreign-documents">
                    Foreign Documents
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="equipment-tool-modal-form-section-separator"></div>
          <Grid item className="equipment-tool-action-buttons">
            <Grid item>
              {!isEquipmentChangeAcknowledgment ? (
                <Button
                  variant="primary"
                  tabIndex="0"
                  data-cy="acknowledge-flight-btn"
                  isDisabled={isButtonEnabled()}
                  onClick={ackFlightHandler} // handle click method
                  onKeyPress={ackFlightHandleKeyPress}
                  className="btn-equipment-tool acknowledge-flight-btn"
                >
                  {ackFlightButtonClicked ? 'Submitting...' : 'Ack Flight'}
                </Button>
              ) : (
                <Button
                  variant="primary"
                  tabIndex="0"
                  data-cy="remove-flight-ack-btn"
                  isDisabled={isButtonEnabled()}
                  onClick={removeFlightAckHandler} // handle click method
                  onKeyPress={removeFlightAckHandleKeyPress}
                  className="btn-equipment-tool remove-flight-ack-btn"
                >
                  {ackFlightButtonClicked ? 'Submitting...' : 'Remove Flight Ack'}
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                tabIndex="0"
                data-cy="acknowledge-tail-btn"
                isDisabled={isEquipmentChangeAcknowledgment || isButtonEnabled()}
                onClick={ackTailHandler} // handle click method
                onKeyPress={
                  !(isEquipmentChangeAcknowledgment || isButtonEnabled()) ? ackTailHandlerHandleKeyPress : () => {}
                }
                className="btn-equipment-tool acknowledge-tail-btn"
              >
                {ackAircraftButtonClicked ? 'Submitting...' : 'Ack Aircraft For Day'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal
      size={`responsive equipment-tool-modal`}
      dataCyTag="equipment-tool-modal"
      show={true}
      title="Equipment Change"
      body={formHtml}
      footerButtons={[]}
      onHide={onClose}
      customCSSTag="equipment-tool-modal"
      draggable={true}
    />
  );
};

export default withAppInsightsTracking(EquipmentChangeTool);
