/**
 * Flash a warning icon in the title of the page.
 */
const flashWarningIcon = () => {
  const aocTitleIconElement = document.getElementById('aocTitleIcon');
  const originalAocTitleIcon = aocTitleIconElement.href;
  const warningAocTitleIcon =
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><text y='50%' x='50%' dy='.3em' text-anchor='middle' font-size='70'>⚠️</text></svg>";
  let flashing = false;

  setInterval(() => {
    aocTitleIconElement.href = flashing ? originalAocTitleIcon : warningAocTitleIcon;
    flashing = !flashing;
  }, 1000);
};
/**
 * Alter a given json object (e.g. API response) based on the transformers definition.
 * The transformers definition is an array of objects where each object has a key-value pair.
 * The key is the path of the property in the json object to be altered.
 * The value is the new value to be set for the property. Value can be a string, number, boolean and even a json object.
 *
 * Transformers example:
 * "transform":
 * {
 *    "response":
 *    {
 *      "status": 200,
 *      "data": [
 *        {
 *         "lineOfFlights.N620QX.scheduled[1].flightPuckData.hasMaintenanceMessages": "true"
 *        },
 *        {
 *          "lineOfFlights.N623QX.scheduled[1].flightPuckData.hasMaintenanceMessages": "true"
 *        }
 *      ]
 *    }
 *  }
 *  Page title will flash a warning icon if any transformation is applied.
 *
 * @returns {Object} getTransformer and transform functions for altering a json object to alter the API response
 */
export const Transformers = () => {
  const applyTransform = (data, field, value) => {
    const fieldPath = field.trim();
    const keys = fieldPath.replace(/\[(\d+)\]/g, '.$1').split('.');
    let current = data;

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];
      if (current[key] === undefined) {
        console.error(`Transformer: ${key} property defined in ${field} doesn't exist in the json object`);
        return false;
      }
      current = current[key];
    }

    if (current && keys.length >= 0 && keys[keys.length - 1] in current) {
      current[keys[keys.length - 1]] = value;
    }
    return true;
  };

  // Get transformer object from a context (e.g. sessionStorage.getItem('ADO_MOCK_API'))
  const getTransformer = (mockApi, canTransform) => {
    try {
      if (!mockApi) {
        return null;
      }
      if (!mockApi.transform || !mockApi.transform.response || !Array.isArray(mockApi.transform.response?.data)) {
        console.error('Transformer: the given API Mock is not valid', mockApi);
        return null;
      }

      if (!canTransform()) {
        return null;
      }
      return mockApi?.transform.response;
    } catch (e) {
      console.error('Transformer: Error parsing the given API Mock', e);
      return null;
    }
  };

  const transformProps = (data, transformers) => {
    let anyTransformed = false;
    // Loop through the responses array and set the values
    transformers.forEach((field) => {
      for (const key in field) {
        if (field.hasOwnProperty(key)) {
          const transformed = applyTransform(data, key, field[key]);
          anyTransformed = transformed || anyTransformed;
        }
      }
    });
    return anyTransformed;
  };

  const transformAll = (transformers) => {
    try {
      return transformers.find((prop) => prop && Object.keys(prop).some((key) => key.trim() === ''));
    } catch {
      return null;
    }
  };

  const transform = (jsonObject, transformers) => {
    if (!transformers) {
      return jsonObject;
    }
    console.info('Transformer: Use transformers to alter json object', transformers);

    const transform = transformAll(transformers);

    if (transform) {
      flashWarningIcon();
      return Object.values(transform)[0];
    }

    if (!jsonObject) {
      return null;
    }

    const anyTransformed = transformProps(jsonObject, transformers);
    if (anyTransformed) {
      flashWarningIcon();
    }
    return jsonObject;
  };

  return { getTransformer, transform };
};

export default Transformers;
