import { useQuery, useQueryClient } from 'react-query';
import {
  getEquipmentChange,
  getFlightSummary,
  getGanttConfigFilter,
  getFlightSummaryDetails,
  getAircraftDetailsByFlightNumber,
  getAircraftDetailsByRegistration,
  getAircraftCrewMembers,
} from '../../services/apiClient/airTrakManagerApi/airTrakManagerApi';
import { getApiErrorMessage, logError, createApiErrorTelemetryProperties } from '../../lib/appInsightsUtils';
import usePrevious from '../usePrevious/usePrevious';
import { jsonEqual } from '../../lib/utils';
import { useAutoRefreshStore } from '../useAutoRefreshStore/useAutoRefreshStore';
import appSettings from '../../appSettings';
import { getDatetimeUtcNowString } from '../../lib/dateTimeUtils';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../lib/constants';
import { rearrangeTrips } from '../../lib/AirTrakManagerUtils';

const moduleName = 'useAirTrakManagerQuery';

const baseConfig = {
  cacheTime: 0, // override default 5 minutes
  staleTime: 1 * 60 * 1000,
  retry: appSettings.RETRY_QUERY_DELAY, // Will retry failed requests 3 times before displaying an error
  refetchInterval: appSettings.REFRESH_INTERVAL,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: true,
  onError: (error) => {
    // Log the error message from the API.
    logError(getApiErrorMessage(error), moduleName, 'useAirTrakManagerQuery', createApiErrorTelemetryProperties(error));
  },
};

/**
 * @description Custom hook for retrieving data from AirtrakManager API gantt config endpoint
 * @param {string} filterData - filter json data.
 * @param {boolean} enabled - enable/disable the query.
 */
export const useGanttConfigQuery = (payload, enabled = true) => {
  const { showFeature } = useFeatureFlag();
  const showBlockPucksFeature = showFeature(Treatment.BLOCK_PUCKS);
  const autoRefreshFlags = useAutoRefreshStore();
  const enabledAutoRefresh = autoRefreshFlags?.ganttChartRefresh;
  const config = {
    ...baseConfig,
    enabled: enabled,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useGanttConfigQuery', createApiErrorTelemetryProperties(error));
    },
    onSuccess: () => {
      const time = getDatetimeUtcNowString('YYYY-MM-DD HH:mm:ss');
      console.log(`AirtrakManager API gantt data refreshed or fetched at ${time}z`);
    },
    select: (data) => {
      // Returns the ".data" property from the response
      return data.data;
    },
  };
  const { startDateUTC, endDateUTC, airlines, roleKey, origins, destinations } = payload;

  return useQuery(
    ['airtrakmanager-gantt-config', startDateUTC, endDateUTC, airlines, roleKey, origins, destinations],
    () => getGanttConfigFilter(payload, showBlockPucksFeature),
    config,
  );
};

/**
 * @description Custom hook for retrieving data from getEquipmentChange API
 * @param {object} flightLeg - flightLeg data.
 */
export const useEquipmentChange = (flightLeg) => {
  const previousData = usePrevious(flightLeg);
  const queryClient = useQueryClient();

  const config = {
    ...baseConfig,
    enabled: !Object.keys(flightLeg).find((key) => flightLeg[key] == undefined),
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useEquipmentChange', createApiErrorTelemetryProperties(error));
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('EquipmentChange');
    }
  }

  return useQuery(['EquipmentChange', flightLeg], () => getEquipmentChange(flightLeg), config);
};

/**
 * @description Custom hook for retrieving data from FlightSummary API
 * @param {object} flightLeg - flightLeg data.
 */
export const useFlightSummary = (flightLeg) => {
  const previousData = usePrevious(flightLeg);

  const queryClient = useQueryClient();
  let isEnabled = false;

  if (flightLeg) {
    isEnabled = !Object.keys(flightLeg).find((key) => flightLeg[key] == undefined);
  }

  const config = {
    ...baseConfig,
    enabled: isEnabled,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useFlightSummary', createApiErrorTelemetryProperties(error));
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('FlightSummary');
    }
  }

  return useQuery(['FlightSummary', flightLeg], () => getFlightSummary(flightLeg), config);
};

/**
 * @description Custom hook for retrieving data from FlightSummaryDetails API
 * @param {object} flightLeg - flightLeg data.
 */
export const useFlightSummaryDetails = (flightLeg) => {
  const autoRefreshFlags = useAutoRefreshStore();
  const previousData = usePrevious(flightLeg);
  const enabledAutoRefresh =
    autoRefreshFlags?.ganttChartRefresh && flightLeg.origin && flightLeg.destination && flightLeg.flightNumber;
  const queryClient = useQueryClient();

  const config = {
    ...baseConfig,
    enabled: true,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(
        getApiErrorMessage(error),
        moduleName,
        'useFlightSummaryDetails',
        createApiErrorTelemetryProperties(error),
      );
    },
  };

  if (previousData && !jsonEqual(previousData, flightLeg)) {
    queryClient.removeQueries('FlightSummaryDetails');
  }

  return useQuery(
    ['FlightSummaryDetails', flightLeg],
    () => (flightLeg.shouldRetrieveData ? getFlightSummaryDetails(flightLeg) : null),
    config,
  );
};

/**
 * @description Custom hook for retrieving data from FlightSummaryDetails API
 * @param {object} flightLeg - flightLeg data.
 */
export const useAircraftDetails = (flightLeg, isGroundEvent = true, enabledQuery = false) => {
  const autoRefreshFlags = useAutoRefreshStore();
  const previousData = usePrevious(flightLeg);
  const retryQueryDelay = 2;
  const enabledAutoRefresh =
    autoRefreshFlags?.ganttChartRefresh &&
    ((flightLeg.origin != null && flightLeg.destination != null && flightLeg.flightNumber != null) ||
      !!flightLeg.aircraft);
  const queryClient = useQueryClient();

  const config = {
    ...baseConfig,
    retry: retryQueryDelay, // Will retry failed requests 2 times before displaying an error
    enabled: enabledQuery,
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(getApiErrorMessage(error), moduleName, 'useAircraftDetails', createApiErrorTelemetryProperties(error));
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('AircraftDetails');
    }
  }

  return useQuery(
    ['AircraftDetails', flightLeg],
    () =>
      isGroundEvent
        ? getAircraftDetailsByRegistration(flightLeg.aircraft)
        : getAircraftDetailsByFlightNumber(flightLeg),
    config,
  );
};

/**
 * @description Custom hook for retrieving data from getAircraftCrewMembers API
 * @param {object} flightLeg - flightLeg data.
 */
export const useAircraftCrewMembers = (flightLeg) => {
  const autoRefreshFlags = useAutoRefreshStore();
  const previousData = usePrevious(flightLeg);
  const enabledAutoRefresh =
    autoRefreshFlags?.ganttChartRefresh &&
    flightLeg.origin != null &&
    flightLeg.destination != null &&
    flightLeg.flightNumber != null;
  const queryClient = useQueryClient();
  const positionsOrder = ['CA', 'FO', 'FA', 'FB', 'FC', 'FD'];
  const positionComparator = (a, b) => {
    const aPositionIndex = positionsOrder.indexOf(a.position);
    const bPositionIndex = positionsOrder.indexOf(b.position);
    if (aPositionIndex === -1 && bPositionIndex === -1) {
      return a.position.localeCompare(b.position);
    }
    if (aPositionIndex === -1) {
      return 1;
    }
    if (bPositionIndex === -1) {
      return -1;
    }
    return aPositionIndex - bPositionIndex;
  };
  const config = {
    ...baseConfig,
    enabled: !Object.values(flightLeg).includes(undefined),
    refetchInterval: enabledAutoRefresh ? baseConfig.refetchInterval : false,
    onError: (error) => {
      // Log the error message from the API.
      logError(
        getApiErrorMessage(error),
        moduleName,
        'useAircraftCrewMembers',
        createApiErrorTelemetryProperties(error),
      );
    },
    onSuccess: (data) => {
      const crewOnFlights = data.crewOnFlights;
      if (!crewOnFlights) {
        throw new Error('No crewOnFlights data found');
      }

      const { currentFlight, nextFlight } = crewOnFlights;

      if (currentFlight?.crewTrips) {
        currentFlight.crewTrips.sort(positionComparator);
      }

      if (nextFlight?.crewTrips) {
        nextFlight.crewTrips.sort(positionComparator);
      }

      const tripDetails = crewOnFlights?.tripDetails ?? {};
      const rearrangedTrips = rearrangeTrips(tripDetails);
      data.crewOnFlights.tripDetails = rearrangedTrips;
      return data;
    },
  };

  if (typeof previousData != 'undefined' && previousData !== null) {
    if (!jsonEqual(previousData, flightLeg)) {
      queryClient.removeQueries('AircraftCrewMembers');
    }
  }
  return useQuery(['AircraftCrewMembers', flightLeg], () => getAircraftCrewMembers(flightLeg), config);
};
