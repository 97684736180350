import React from 'react';
import PropTypes from 'prop-types';
import CrewTable from '../CrewTable/CrewTable';
import { Grid } from '@material-ui/core';

const CrewTables = ({ flightCrew, nextFlightCrew, currentFlightErr, nextFlightErr, fallbackDepartureStation }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10} md={10} lg={5}>
        <CrewTable
          flightInfo={flightCrew?.flight}
          crewInfoList={flightCrew?.crewTrips}
          errorObj={currentFlightErr}
          hasQualifications={true}
          columnNames={['', '', '', 'TRIP', 'QUALIFICATIONS']}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} lg={1}>
        &nbsp;
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={5}>
        <CrewTable
          flightInfo={nextFlightCrew?.flight}
          crewInfoList={nextFlightCrew?.crewTrips}
          errorObj={nextFlightErr}
          hasQualifications={true}
          columnNames={['', '', '', 'TRIP', 'QUALIFICATIONS']}
          fallbackDepartureStation={fallbackDepartureStation}
        />
      </Grid>
      <Grid item xs={12} sm={1} md={1} lg={1}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

CrewTables.propTypes = {
  flightCrew: PropTypes.object.isRequired,
  nextFlightCrew: PropTypes.object,
  currentFlightErr: PropTypes.object,
  nextFlightErr: PropTypes.object,
  fallbackDepartureStation: PropTypes.string,
};

export default CrewTables;
