import React, { useState } from 'react';
import { withAppInsightsTracking } from '../../../../../services/appInsightsFactory/appInsightsFactory';
import { formatDateTime } from '../../../../../lib/dateTimeUtils';
import { useAircraftCrewMembers } from '../../../../../hooks/useAirTrakManagerQuery/useAirTrakManagerQuery';
import CollapsibleRegion from '../../../../Shared/CollapsibleRegion/CollapsibleRegion';
import TabSkeletonSection from '../../../FlightDetailPane/TabSkeletonSection/TabSkeletonSection';
import { ApiErrorName, DateFormat } from '../../../../../lib/constants';
import CrewTables from './CrewTables/CrewTables';
import DeadheadCrewTables from './DeadheadCrewTables/DeadheadCrewTables';
import FlightInfoTabs from './FlightInfoTabs/FlightInfoTabs';
import PropTypes from 'prop-types';
import './SummaryCrewTab.css';
import useAirtrackManagerError from '../../../../../hooks/useAirtrakManagerError/useAirtrakManagerError';
import { getCrewErrorDataKey } from '../../../../../lib/AirTrakManagerUtils';

const SummaryCrewTabContainer = ({ children }) => {
  return <div className="summary-crew-tab-container">{children}</div>;
};

const CrewInfo = ({ currentFlight, nextFlight, tripDetails, departureDateTime, errors, fallbackDepartureStation }) => {
  const hasDeadheadsData = currentFlight.deadHeadCrewTrips?.length > 0 || nextFlight.deadHeadCrewTrips?.length > 0;
  const [expandCollapsibleRegion, setExpandCollapsibleRegion] = useState(false);
  const { error } = useAirtrackManagerError(errors, (error) =>
    getCrewErrorDataKey(error.errorData.TripNumber, error.errorData.TripDate),
  );

  return (
    <>
      <CrewTables
        flightCrew={currentFlight}
        nextFlightCrew={nextFlight}
        currentFlightErr={error[ApiErrorName.CREW_ON_FLIGHT]}
        nextFlightErr={error[ApiErrorName.CREW_ON_NEXT_FLIGHT]}
        fallbackDepartureStation={fallbackDepartureStation}
      />

      <div className="flightinfo-deadhead-wrapper">
        <CollapsibleRegion
          text="DEADHEADS"
          disabled={!hasDeadheadsData}
          expand={expandCollapsibleRegion}
          onToggle={setExpandCollapsibleRegion}
        >
          <DeadheadCrewTables
            flightCrew={currentFlight}
            nextFlightCrew={nextFlight}
            currentFlightErr={error[ApiErrorName.CREW_ON_FLIGHT]}
            nextFlightErr={error[ApiErrorName.CREW_ON_NEXT_FLIGHT]}
            fallbackDepartureStation={fallbackDepartureStation}
          />
        </CollapsibleRegion>

        <FlightInfoTabs
          isDeadheadsExpanded={expandCollapsibleRegion}
          tripDetails={tripDetails}
          currentFlight={currentFlight}
          nextFlight={nextFlight}
          flightDepartureDateTime={departureDateTime}
          crewTripsErr={error[ApiErrorName.CREW_TRIPS]}
        />
      </div>
    </>
  );
};

const SummaryCrewTab = ({ selectedItem }) => {
  let selectedFlight = selectedItem.data;
  const operatingDate = formatDateTime(selectedFlight?.departureDate, DateFormat.DEFAULT);
  // Captures a fallback departure station in case of standby flights for crew tables
  const fallbackDepStationForNext = selectedFlight?.dest ?? '';

  const { isLoading: loadingCrewMembers, data: crewMembers } = useAircraftCrewMembers({
    airline: selectedFlight?.airline,
    origin: selectedFlight?.orig,
    destination: selectedFlight?.dest,
    flightNumber: selectedFlight?.flightNumber,
    departureCount: selectedFlight?.departureCount,
    scheduledOperatingDate: operatingDate,
    aircraft: selectedFlight?.aircraft,
  });

  if (!['AS', 'QX'].includes(selectedFlight?.airline)) {
    return <SummaryCrewTabContainer>No crew information available for this flight.</SummaryCrewTabContainer>;
  }

  if (loadingCrewMembers) {
    return (
      <SummaryCrewTabContainer>
        <TabSkeletonSection />
      </SummaryCrewTabContainer>
    );
  }

  const currentFlight = crewMembers?.crewOnFlights?.currentFlight;
  const nextFlight = crewMembers?.crewOnFlights?.nextFlight;
  const tripDetails = crewMembers?.crewOnFlights?.tripDetails ?? {};
  const errors = crewMembers?.errors ?? [];

  if (!crewMembers) {
    return <SummaryCrewTabContainer>Failed to retrieve Crew data. Please try again.</SummaryCrewTabContainer>;
  }

  return (
    <SummaryCrewTabContainer>
      <CrewInfo
        currentFlight={currentFlight}
        nextFlight={nextFlight}
        tripDetails={tripDetails}
        airline={selectedFlight?.airline}
        scheduledOperatingDate={operatingDate}
        departureDateTime={selectedFlight?.etd}
        errors={errors}
        fallbackDepartureStation={fallbackDepStationForNext}
      />
    </SummaryCrewTabContainer>
  );
};

SummaryCrewTabContainer.propTypes = {
  children: PropTypes.object.isRequired,
};

CrewInfo.propTypes = {
  currentFlight: PropTypes.object.isRequired,
  nextFlight: PropTypes.object.isRequired,
  tripDetails: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  fallbackDepartureStation: PropTypes.string,
};

SummaryCrewTab.propTypes = {
  selectedItem: PropTypes.shape({
    data: PropTypes.shape({
      airline: PropTypes.string,
      orig: PropTypes.string,
      dest: PropTypes.string,
      flightNumber: PropTypes.string,
      departureCount: PropTypes.string,
      departureDate: PropTypes.string,
      aircraft: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withAppInsightsTracking(SummaryCrewTab);
