import { getMonthDayDisplayString, getPhaseOfFlightLabel } from '../../lib/displayUtils';
import { getPhaseOfFlight } from '../../lib/utils';
import { useFlightDetailsQuery } from '../../react-query/useFlightsQuery/useFlightsQuery';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { EntitlementNames, Treatment, TimeZones } from '../../lib/constants';
import useAuthorizationAccess from '../../hooks/useAuthorizationAccess/useAuthorizationAccess';

const getFlightLegSummary = (flightLeg, flightDetailsData) => {
  let flightNumber = '';
  let origin = '';
  let destination = '';
  let flightDateAndDay = '';
  let phaseOfFlight = '';
  let irropsCode = '';
  let irregularOperationReason = '';

  if (flightLeg && flightLeg.flightLegKey) {
    flightNumber = `${flightLeg.airline} ${flightLeg.flightNumber}`;
    origin = flightLeg.orig;
    destination = flightLeg.projectedDestination ?? (!!flightLeg.dest ? flightLeg.dest : '');
    flightDateAndDay = `${getMonthDayDisplayString(flightLeg.etd, TimeZones.PDT, 'MM/DD (z)')}`;
    irropsCode = flightDetailsData?.irregularOperationCode ?? flightLeg.irropsCode ?? '- -';
    irregularOperationReason = flightDetailsData?.irregularOperationReason;
    if (!flightDetailsData || flightDetailsData?.times?.length !== 4) {
      phaseOfFlight = '';
    } else {
      const flightLegOooiTimes = {
        atd: flightDetailsData?.times[0].actualTimeUTC,
        off: flightDetailsData?.times[1].actualTimeUTC,
        on: flightDetailsData?.times[2].actualTimeUTC,
        ata: flightDetailsData?.times[3].actualTimeUTC,
      };
      phaseOfFlight = `${getPhaseOfFlightLabel(getPhaseOfFlight(flightLegOooiTimes))}`;
    }
  }

  return {
    flightNumber,
    origin,
    destination,
    flightDateAndDay,
    phaseOfFlight,
    irropsCode,
    irregularOperationReason,
  };
};

const useFlightLegSummary = (flightLeg, enabledAutoRefresh) => {
  const { data: flightDetailsData } = useFlightDetailsQuery(
    flightLeg?.flightNumber,
    flightLeg?.departureDate,
    flightLeg?.orig,
    flightLeg?.dest,
    flightLeg?.departureCount,
    flightLeg?.airline,
    enabledAutoRefresh,
  );

  return { getFlightLegSummary: () => getFlightLegSummary(flightLeg, flightDetailsData) };
};

const useFlightLegStatus = (flightLeg) => {
  const { showFeature } = useFeatureFlag();
  const melcdlFlag = showFeature(Treatment.MEL_MX_MESSAGES);
  const hasMxMessageEntitlement = useAuthorizationAccess(null, [EntitlementNames.MXMESSAGES]);
  const hasSupportValidationEntitlement = useAuthorizationAccess(null, [EntitlementNames.SUPPORTVALIDATION]);

  return {
    hasMxMessage: () =>
      flightLeg.hasMaintenanceMessages && melcdlFlag && (hasMxMessageEntitlement || hasSupportValidationEntitlement),
  };
};

export { useFlightLegSummary, useFlightLegStatus };
