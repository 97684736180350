import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ReactComponent as CheckmarkChecked } from '../../../../../../assets/images/checkmark-checked.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import './CrewTable.css';

const CrewTable = ({
  flightInfo,
  crewInfoList,
  errorObj,
  hasQualifications = false,
  columnNames = [],
  displayTitle = true,
  displayHeaders = true,
  fallbackDepartureStation = '',
}) => {
  crewInfoList = crewInfoList || [];
  const flightNumber = flightInfo?.flightNumber ? flightInfo.flightNumber : '----';
  const flightString = errorObj ? '' : flightNumber;

  const departureStation = flightInfo?.departureStation ? flightInfo.departureStation : fallbackDepartureStation;
  const departureString = errorObj ? '' : departureStation;

  return (
    <Grid container spacing={2} style={{ padding: '1rem' }} className="crew-tab-info">
      <Grid item xs={12} style={{ color: '--dark-secondary' }} className="flight-info">
        {displayTitle && (
          <>
            FLIGHT <span style={{ width: '3.5rem' }}>{flightString}</span>&nbsp; DEPARTURE STATION |{' '}
            <span style={{ width: '1rem' }}>{departureString}</span>
          </>
        )}
      </Grid>
      <Table sx={{ minWidth: 300 }}>
        <TableHead>
          <TableRow>
            {displayHeaders &&
              columnNames.map((columnName, index) => (
                <TableCell key={index} align="left" style={{ height: '3.5rem' }}>
                  {columnName}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody data-cy={'crew-table-body'}>
          {Array.from(crewInfoList).map((crewInfo, index) => (
            <TableRow
              key={`${flightInfo?.flightNumber}-${crewInfo.crewName}`}
              data-cy={`${flightInfo?.flightNumber}-${index}`}
            >
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {crewInfo.position}
              </TableCell>
              <TableCell align="left" break autoWidth style={{ verticalAlign: 'top' }}>
                {crewInfo.crewName}
              </TableCell>
              {'sameCrewOnNextFlight' in crewInfo && (
                <TableCell align="left" autoWidth>
                  {crewInfo.sameCrewOnNextFlight ? (
                    <CheckmarkChecked className="checkmark-checked" />
                  ) : (
                    <CancelIcon className="checkmark-unchecked" fontSize="large" />
                  )}
                </TableCell>
              )}
              <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                {crewInfo.tripDisplay}
              </TableCell>
              {hasQualifications && (
                <TableCell align="left" autoWidth style={{ verticalAlign: 'top' }}>
                  {crewInfo.qualificationsDisplay}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {errorObj && <Typography variant="body1">Failed to retrieve Crew data. Please try again.</Typography>}
    </Grid>
  );
};

CrewTable.propTypes = {
  flightInfo: PropTypes.object.isRequired,
  crewInfoList: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorObj: PropTypes.object,
  hasQualifications: PropTypes.bool,
  columnNames: PropTypes.arrayOf(PropTypes.string),
  displayHeaders: PropTypes.bool,
  displayTitle: PropTypes.bool,
  fallbackDepartureStation: PropTypes.string,
};

export default CrewTable;
