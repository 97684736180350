import React from 'react';
import PropTypes from 'prop-types';
import { TimeDisplay } from '../../../../../Shared/TimeDisplay/TimeDisplay';
import { DateDisplay } from '../../../../../Shared/DateDisplay/DateDisplay';
import '../SummaryAircraftTab.css';

/** SummaryAircraftInfoBlock renders the details of an aircraft tab.
 * @param {string} isAircraftDate - date and time of the aircraft tab
 * @param {string} aircraftData - note of the aircraft tab
 * @param {string} isAircraftTime - time of the aircraft tab
 * @param {string} dateTimeformat - format date and time
 * @returns SummaryAircraftInfoBlock */
const SummaryAircraftInfoBlock = ({
  aircraftData,
  dateTimeFormat,
  title,
  isDateField = false,
  isTimeField = false,
  station,
  isPDT = false, // This is needed for due date which is not sent as UTC so no conversion to Seattle time is needed
}) => {
  const dataCyTag = `aircraft-tab-${title?.toLowerCase() || 'time'}`;

  return (
    <div
      className={`aircraft-tab-${isDateField ? 'date' : isTimeField ? 'time' : 'text'} ${title}`}
      data-cy={dataCyTag}
    >
      {isDateField ? (
        <DateDisplay utcDate={aircraftData} station={station} dateTimeFormat={dateTimeFormat} isPDT={isPDT} />
      ) : isTimeField ? (
        <TimeDisplay utcTime={aircraftData} station={station} dataCyTag={`${dataCyTag}-timedisplay-zulu`} />
      ) : (
        aircraftData
      )}
    </div>
  );
};

SummaryAircraftInfoBlock.propTypes = {
  aircraftData: PropTypes.string,
  isAircraftDate: PropTypes.bool,
  isAircraftTime: PropTypes.bool,
  dateTimeformat: PropTypes.string,
};

export default SummaryAircraftInfoBlock;
