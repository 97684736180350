import React from 'react';
import { withAppInsightsTracking } from '../../../services/appInsightsFactory/appInsightsFactory';
import Modal from '../../Shared/Modal/Modal';
import Button from '../../Shared/Button/Button';
import '../Flifo.css';
import { KeyCodes } from '../../../lib/constants';

/**
 * The FlifoDetails component handles the FLIFO input fields
 * @param {obj} formHtml - formHtml document
 * @param {func} onUpdate - action fot Update button
 * @param {func} onCancel - action for Cancel button
 * @param {func} onClose - action for closing the modal
 * @param {bool} isDisabled - enable/disable for Update button
 * @returns FlifoModal component
 */
const FlifoModal = ({ formHtml, onUpdate, onCancel, isDisabled, onClose }) => {
  // Hitting Enter on Update button
  const updateBtnHandleKeyPress = (e) => {
    // It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER) {
      onUpdate();
    }
  };

  const cancelBtnHandleKeyPress = (e) => {
    // It triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER) {
      onCancel();
    }
  };

  // Action buttons
  const footerButtons = [
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="flifo-modal-cancel-button"
      isDisabled={false}
      onClick={onCancel}
      onKeyPress={cancelBtnHandleKeyPress}
      className={'flifo-modal-cancel-button'}
    >
      {'Cancel'}
    </Button>,
    <Button
      variant="primary"
      tabIndex="0"
      data-cy="flifo-modal-update-button"
      isDisabled={isDisabled}
      onClick={onUpdate}
      onKeyPress={updateBtnHandleKeyPress}
      className={'flifo-modal-update-button'}
    >
      {'Update'}
    </Button>,
  ];

  return (
    <Modal
      size={'responsive flifo-modal'}
      dataCyTag={'flifo-modal'}
      show={true}
      title={'FLIGHT INFO & SCHEDULE'}
      body={formHtml}
      footerButtons={footerButtons}
      onHide={onClose}
      customCSSTag={'flifo-modal'}
      draggable={true}
    />
  );
};

export default withAppInsightsTracking(FlifoModal);
