import React from 'react';
import { useCrosshairDispatch, useCrosshairStore } from '../../../../../hooks/useCrosshairStore/useCrosshairStore';
import SingleSelectDropdown from '../../../../Shared/Inputs/SingleSelectDropdown/SingleSelectDropdown';
import './CrosshairCountButton.css';
import { SessionStorageKeys, Treatment } from '../../../../../lib/constants';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext/FeatureFlagContext';

const displayList = [
  { key: '1', name: '1' },
  { key: '2', name: '2' },
  { key: '3', name: '3' },
  { key: '4', name: '4' },
];

/**
 * The CrosshairCountButton component is single select menu for number of crosshairs to display.
 * @returns CrosshairCountButton component
 */
const CrosshairCountButton = () => {
  const { isCrosshairActive, crosshairCount } = useCrosshairStore();
  const { updateCrosshair, updateCrosshairCount, updateActiveCrosshair } = useCrosshairDispatch();
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);

  const onClickCountButton = (e) => {
    const value = parseInt(e.target.value);

    const crosshairSessionStorage = JSON.parse(sessionStorage.getItem(SessionStorageKeys.CROSSHAIR)) ?? {};
    const { crosshairPositions: sessionStoragePositions, crosshairCount: sessionStorageCount } =
      crosshairSessionStorage;

    const newCrosshairSession = { ...crosshairSessionStorage };

    if (sessionStorageCount > value) {
      // Remove crosshair positions from local storage
      if (sessionStoragePositions?.length) {
        newCrosshairSession.crosshairPositions = sessionStoragePositions.slice(0, value);
      }
      newCrosshairSession.activeCrosshair = 0;
      updateActiveCrosshair({ activeCrosshair: 0 });
    }

    newCrosshairSession.crosshairCount = value;
    updateCrosshairCount({ crosshairCount: value });

    // If crosshair is inactive, set active and current
    if (!isCrosshairActive) {
      newCrosshairSession.isCrosshairActive = true;
      newCrosshairSession.activeCrosshair = 0;
      updateCrosshair({ isCrosshairActive: true });
      updateActiveCrosshair({ activeCrosshair: 0 });
    }
    if (!showMultipleViews) {
      sessionStorage.setItem(SessionStorageKeys.CROSSHAIR, JSON.stringify(newCrosshairSession));
    }
  };

  return (
    <SingleSelectDropdown
      displayText={crosshairCount}
      displayList={displayList}
      className="crosshair-count-select"
      dataCyTag="crosshair-count-select"
      isDisabled={false}
      onChange={onClickCountButton}
    />
  );
};

export default CrosshairCountButton;
