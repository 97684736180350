import { useDispatch, useSelector } from 'react-redux';
import { WindowManagerAction } from '../../redux/actionTypes';
import { useCallback } from 'react';

/**
 * Selector for the window manager reducer
 */
const selectWindowManager = (state) => state.viewManagerReducer;

/**
 * Selector for minimized views
 */
export const selectMinimizedViews = (state) => state.viewManagerReducer.minimizedViews;

export const selectDraggedMinimizedViews = (state) => state.viewManagerReducer.draggedMinimizedViews;

export const selectMaximizedViews = (state) => state.viewManagerReducer.maximizedViews;

export const selectViewOrder = (state) => state.viewManagerReducer.viewOrder;

export const selectViewWindows = (state) => state.viewManagerReducer.viewWindows;
/**
 * Custom hook for retrieving the window manager store
 * @returns selector hook for retrieving window manager store
 */

export const useWindowManagerStore = () => {
  return useSelector(selectWindowManager);
};

// Custom hook for dispatching actions to update window manager state
export const useWindowManagerDispatch = () => {
  const dispatch = useDispatch();

  const addViewWindow = useCallback(() => {
    dispatch({ type: WindowManagerAction.ADD_VIEW_WINDOW });
  }, [dispatch]);

  const removeViewWindow = useCallback(
    (id) => {
      dispatch({ type: WindowManagerAction.REMOVE_VIEW_WINDOW, id });
    },
    [dispatch],
  );

  const updateViewWindow = useCallback(
    (id, payload) => {
      dispatch({ type: WindowManagerAction.UPDATE_VIEW_WINDOW, id, payload });
    },
    [dispatch],
  );

  const setActiveView = useCallback(
    (id) => {
      dispatch({ type: WindowManagerAction.SET_ACTIVE_VIEW, id });
    },
    [dispatch],
  );

  const setMinimizedView = useCallback(
    (id) => {
      dispatch({ type: WindowManagerAction.SET_MINIMIZED_VIEW, id });
    },
    [dispatch],
  );

  const removeMinimizedView = useCallback(
    (id) => {
      dispatch({ type: WindowManagerAction.REMOVE_MINIMIZED_VIEW, id });
    },
    [dispatch],
  );

  const setMaximizedView = useCallback(
    (id) => {
      dispatch({ type: WindowManagerAction.SET_MAXIMIZED_VIEW, id });
    },
    [dispatch],
  );

  const removeMaximizedView = useCallback(
    (id) => {
      dispatch({ type: WindowManagerAction.REMOVE_MAXIMIZED_VIEW, id });
    },
    [dispatch],
  );

  const renameViewWindow = useCallback(
    (id, payload) => {
      dispatch({ type: WindowManagerAction.RENAME_VIEW_WINDOW, id, payload });
    },
    [dispatch],
  );

  const setDraggedMiniView = useCallback(
    (id) => {
      dispatch({ type: WindowManagerAction.SET_DRAGGED_MINIMIZED_VIEW, id });
    },
    [dispatch],
  );

  const arrangeViewsCascade = useCallback(() => {
    dispatch({ type: WindowManagerAction.ARRANGE_VIEWS_CASCADE });
  }, [dispatch]);

  /**
   * Arrange views in a grid
   * @param {string} payload.screenOrientation - The screen orientation
   * @returns {void}
   */
  const arrangeViewsGrid = useCallback(
    (payload) => {
      dispatch({
        type: WindowManagerAction.ARRANGE_VIEWS_GRID,
        payload: { screenOrientation: payload.screenOrientation },
      });
    },
    [dispatch],
  );

  /**
   * Arrange views in rows
   * @returns {void}
   */
  const arrnageViewRows = useCallback(() => {
    dispatch({ type: WindowManagerAction.ARRANGE_VIEWS_ROWS });
  }, [dispatch]);

  /**
   * Arrange views in columns
   * @returns {void}
   */
  const arrnageViewColumns = useCallback(() => {
    dispatch({ type: WindowManagerAction.ARRANGE_VIEWS_COLUMNS });
  }, [dispatch]);

  /**
   * Changes each views' isViewFromConfig to true on save.
   * @returns {void}
   */
  const saveViewsConfig = useCallback(() => {
    dispatch({ type: WindowManagerAction.SAVE_VIEWS_CONFIG });
  }, [dispatch]);

  return {
    addViewWindow,
    removeViewWindow,
    updateViewWindow,
    setActiveView,
    setMinimizedView,
    removeMinimizedView,
    setMaximizedView,
    removeMaximizedView,
    renameViewWindow,
    setDraggedMiniView,
    arrangeViewsCascade,
    arrangeViewsGrid,
    arrnageViewColumns,
    arrnageViewRows,
    saveViewsConfig,
  };
};
