import { useState, useCallback, useEffect } from 'react';
import Flights from './Flights/Flights';
import FlightNavigationBar from './FlightNavigationBar/FlightNavigationBar';
import MultipleViewsContainer from './MultipleViews/MultipleViewsContainer/MultipleViewsContainer';
import { useFeatureFlag } from '../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment, ScrollLocalStorageElementId } from '../../lib/constants';
import { clearComponentScrollState } from '../../lib/utils';
import { rehydrateConfigSaveForMultiView, rehydrateConfigSaveForSingleView } from '../../lib/saveableConfigUtils';
import { useRootDispatch } from '../../hooks/useConfigStore/useRootStore';
import { useMultipleViewsRootDispatch } from '../../hooks/useConfigStore/useMultipleViewsRootStore';
import { useSelector } from 'react-redux';
import { ViewIdProvider } from '../../contexts/ViewIdContext/ViewIdContext';
import PropTypes from 'prop-types';
import './ManageOperationsPage.css';
import './MuiGridContainerOverride.css';

function ManageOperationsPage({ onShowHideHeader, showHeader }) {
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);
  const [searchFilterTitles, setSearchFilterTitles] = useState(null);
  // Update root state to be used to Load a configuration for SingleView (when Multiple Views feature is disabled) and MultipleViews (when Multiple Views feature is enabled)
  const { updateRootState } = useRootDispatch();
  const { updateMultipleViewsRootState } = useMultipleViewsRootDispatch();

  const currentActiveId = useSelector((state) => state?.viewManagerReducer?.viewOrder[0]);
  const viewType = useSelector(
    (state) => state?.viewManagerReducer?.viewWindows.find((view) => view.id === currentActiveId)?.viewType,
  );

  //sort state
  // Not going to store sort in session storage for now, as views are not persisted,
  // may not need to since will perist through configs
  // With Multiple Views default sort is defined and applied within OperationsView
  const [sort, setSort] = useState(null);

  const clearSort = () => {
    setSort(null);
  };

  /**
   * Event handler for when new table sort is applied. Saves the sort into session storage.
   * NOTE: Actual sort happens in flightlisttable.js dispatch
   */
  const handleSortClick = useCallback(
    (orderBy, orderDirection) => {
      setSort({
        ...sort,
        [currentActiveId]: {
          orderBy,
          orderDirection,
        },
      });
    },
    [sort],
  );

  useEffect(() => {
    var configStateStr = localStorage.getItem(ScrollLocalStorageElementId.SET_NEWCONFIG_ROOTSTATE);
    // return if configStaterStr is null or empty
    if (configStateStr === null || configStateStr.length == 0) return;
    // parse the configState string to object
    var configState = JSON.parse(configStateStr);

    // clear component scroll state values
    clearComponentScrollState([ScrollLocalStorageElementId.GANTT_CHART, ScrollLocalStorageElementId.FLIGHTLIST_TABLE]);

    if (showMultipleViews) {
      updateMultipleViewsRootState(rehydrateConfigSaveForMultiView(configState));
    } else {
      updateRootState(rehydrateConfigSaveForSingleView(configState));
    }
    localStorage.setItem(ScrollLocalStorageElementId.SET_NEWCONFIG_ROOTSTATE, '');
  }, []);

  if (showMultipleViews) {
    return (
      <div className="manage-operations-page-container">
        <ViewIdProvider id={currentActiveId}>
          <FlightNavigationBar
            onShowHideHeader={onShowHideHeader}
            showNavbar={showHeader}
            viewType={viewType}
            currentActiveId={currentActiveId}
            clearSort={clearSort}
            searchFilterTitles={searchFilterTitles}
          />
          <MultipleViewsContainer
            showHeader={showHeader}
            currentActiveId={currentActiveId}
            handleSortClick={handleSortClick}
            sort={sort}
            searchFilterTitles={searchFilterTitles}
            setSearchFilterTitles={setSearchFilterTitles}
          />
        </ViewIdProvider>
      </div>
    );
  }

  return (
    <ViewIdProvider id={0}>
      <Flights onShowHideHeader={onShowHideHeader} showHeader={showHeader} />
    </ViewIdProvider>
  );
}

export default ManageOperationsPage;

ManageOperationsPage.propTypes = {
  onShowHideHeader: PropTypes.func.isRequired,
  showHeader: PropTypes.bool.isRequired,
};
