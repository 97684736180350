import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './FlightInfoTabs.css';
import FlightInfoTables from './FlightInfoTables/FlightInfoTables';
import { utcToStationLocalTime, addTimeToDate } from '../../../../../../lib/dateTimeUtils';
import { TimeZones } from '../../../../../../lib/constants';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`flightinfo-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function applyProps(index) {
  return {
    id: `flightinfo-tab-${index}`,
    'aria-controls': `flightinfo-tabpanel-${index}`,
  };
}

export default function FlightInfoTabs({
  isDeadheadsExpanded,
  tripDetails,
  currentFlight,
  nextFlight,
  flightDepartureDateTime,
  crewTripsErr,
}) {
  const [value, setValue] = useState(1);

  const timeZoneToDisplay = TimeZones.PDT; // future enhancement: get this from redux store after timezone picker feature is implemented

  const { today, yesterday, tomorrow } = tripDetails;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columnNames = ['AIRCRAFT', 'FLIGHT', 'ORIGIN', 'DEPART', 'DESTIN', 'ARRIVAL'];

  const GetTabLabel = ({ date, daysToAdd }) => {
    const dateAdjusted = addTimeToDate(date, 'd', daysToAdd);
    const stationLocalDate = utcToStationLocalTime(dateAdjusted, timeZoneToDisplay, 'MM/DD z');
    return (
      <div>
        <span data-cy={`flightinfo-tab-${daysToAdd}`}>{stationLocalDate}</span>
      </div>
    );
  };

  return (
    <div className={`flight-info-tabs-container ${isDeadheadsExpanded ? 'deadheads-expanded' : ''}`}>
      <Tabs className="flightinfo-tabs" value={value} onChange={handleChange} aria-label="flight info tabs">
        <Tab
          className="flightinfo-tab"
          label=<GetTabLabel date={flightDepartureDateTime} daysToAdd={-1} />
          {...applyProps(0)}
        />
        <Tab
          className="flightinfo-tab"
          label=<GetTabLabel date={flightDepartureDateTime} daysToAdd={0} />
          {...applyProps(1)}
        />
        <Tab
          className="flightinfo-tab"
          label=<GetTabLabel date={flightDepartureDateTime} daysToAdd={1} />
          {...applyProps(2)}
        />
      </Tabs>

      <TabPanel value={value} index={0}>
        {yesterday === null || (Array.isArray(yesterday) && yesterday.length === 0) ? null : (
          <FlightInfoTables
            flightInfoData={yesterday}
            columnNames={columnNames}
            currentFlight={currentFlight}
            nextFlight={nextFlight}
            isDeadheadsExpanded={isDeadheadsExpanded}
            crewTripsErr={crewTripsErr}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {today === null || (Array.isArray(today) && today.length === 0) ? null : (
          <FlightInfoTables
            flightInfoData={today}
            columnNames={columnNames}
            currentFlight={currentFlight}
            nextFlight={nextFlight}
            isDeadheadsExpanded={isDeadheadsExpanded}
            crewTripsErr={crewTripsErr}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {tomorrow === null || (Array.isArray(tomorrow) && tomorrow.length === 0) ? null : (
          <FlightInfoTables
            flightInfoData={tomorrow}
            columnNames={columnNames}
            currentFlight={currentFlight}
            nextFlight={nextFlight}
            isDeadheadsExpanded={isDeadheadsExpanded}
            crewTripsErr={crewTripsErr}
          />
        )}
      </TabPanel>
    </div>
  );
}
FlightInfoTabs.propTypes = {
  isDeadheadsExpanded: PropTypes.bool,
  tripDetails: PropTypes.object,
  currentFlight: PropTypes.object,
  nextFlight: PropTypes.object,
  crewTripsErr: PropTypes.object,
};
