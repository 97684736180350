import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { isEqual } from 'lodash';
import { SessionStorageKeys } from './constants';
import { isNullOrUndefined } from './utils';

/* Required by Dayjs to include these plugins when working with TimeZones */
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

/**
 * This method ensures that all user saved configurations are applied correctly.
 * JSON.parse(stringified object) can reconstitute an object from a string...however
 * when we store the date objects from Day JS they do not retain their Object status
 * because these objects are not serializable. So when we stringify a day js object
 * it turns it into its string timestamp. So we need to rehydrate it as a day js object
 *
 * This method should be used for MultiView
 * @param {Object} configState
 * @returns
 */
export const rehydrateConfigSaveForMultiView = (configState) => {
  return {
    ...configState,
    viewWindowData: configState.viewWindowData.map((viewData) => rehydrateConfigSaveForSingleView(viewData)),
  };
};

/**
 * This method is utilized for none MultiView modes where the filter reducer is not in viewWindowData.
 */
export const rehydrateConfigSaveForSingleView = (configState) => {
  return {
    ...configState,
    filterReducer: {
      ...configState.filterReducer,
      filter: rehydrateDayJsObjectForFilter(configState.filterReducer.filter),
    },
  };
};

const rehydrateDayJsObjectForFilter = (filterData) => {
  return {
    ...filterData,
    startDate: dayjs(filterData.startDate),
    endDate: dayjs(filterData.endDate),
  };
};

export const getLastSavedConfigOrUndefined = (viewId, configType, timeZone) => {
  const configFromStorage = sessionStorage.getItem(SessionStorageKeys.LAST_SAVED_CONFIG);
  const lastSavedConfig = configFromStorage ? JSON.parse(configFromStorage) : null;

  if (lastSavedConfig) {
    // If multiview the viewId and [configType] must be present
    if (!isNullOrUndefined(viewId) && lastSavedConfig[viewId]?.[configType]) {
      return mapConfigFilter_ToStateFilter(lastSavedConfig[viewId][configType], timeZone);
    }
    // If single view just the [configType] will be present
    if (lastSavedConfig?.[configType]) {
      return mapConfigFilter_ToStateFilter(lastSavedConfig[configType], timeZone);
    }
  }
};

const toNumberArray = (arr) => {
  return arr.map((a) => parseInt(a));
};

export const mapUserConfigToLastSavedConfig = (viewWindowsFromConfig = []) => {
  return viewWindowsFromConfig.reduce((lastSavedConfig, viewWindow) => {
    const { id, filter } = viewWindow;
    lastSavedConfig[id] = { filter };
    return lastSavedConfig;
  }, {});
};

export const mapConfigFilter_ToStateFilter = (filterParam, timeZone) => {
  const {
    startDateDaysFromToday,
    endDateDaysFromToday,
    flight,
    aircraft,
    origin,
    destination,
    assignmentList,
    flightPhase,
    airline,
    alerts,
    fleets,
  } = filterParam;

  const startDateCalc = dayjs().tz(timeZone).add(startDateDaysFromToday, 'day').format('MM-DD-YYYY');
  const endDateCalc = dayjs().tz(timeZone).add(endDateDaysFromToday, 'day').format('MM-DD-YYYY');

  return {
    startDate: dayjs(startDateCalc).tz(timeZone, true).startOf('day'),
    endDate: dayjs(endDateCalc).tz(timeZone, true).endOf('day'),
    flight: flight,
    aircraft: aircraft,
    origin: origin,
    destination: destination,
    assignmentList: toNumberArray(assignmentList),
    flightPhase: toNumberArray(flightPhase),
    airline: toNumberArray(airline),
    alerts: alerts,
    fleets: fleets,
    timezone: timeZone,
  };
};

/**
 * Creates an array from two filter objects to check conditionals
 * Utilized for isClearFilterDisabled
 * Utilzied for isApplyButtonDisabled
 * @param {Object} filterA - filterA object of filter values
 * @param {Object} filterB - filterB object of filter values
 * @returns {Array} - Array of truthy/falsy outcomes
 */
export const getBaseConditions = (filterA, filterB) => [
  isEqual(filterA.startDate.format('DD/MM/YYYY'), filterB.startDate.format('DD/MM/YYYY')),
  isEqual(filterA.endDate.format('DD/MM/YYYY'), filterB.endDate.format('DD/MM/YYYY')),
  isEqual(filterA.airline, filterB.airline),
  isEqual(filterA.fleets, filterB.fleets),
  isEqual(filterA.flightPhase, filterB.flightPhase),
  isEqual(filterA.assignmentList, filterB.assignmentList),
  isEqual(filterA.flight, filterB.flight),
  isEqual(filterA.aircraft, filterB.aircraft),
  isEqual(filterA.origin, filterB.origin),
  isEqual(filterA.destination, filterB.destination),
  isEqual(filterA.alerts, filterB.alerts),
];

/**
 * Validates all necessary fields for equality checks on the filter objects
 * @param {Object} filterA - filterA object of filter values
 * @param {Object} filterB - filterB object of filter values
 * @returns {Boolean}
 */
export const validateFilterFormsAreEqual = (filterA, filterB) => {
  return getBaseConditions(filterA, filterB).every((condition) => condition);
};
