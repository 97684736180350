import React, { useMemo, memo } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import SummaryAircraftInfoBlock from '../SummaryAircraftInfoBlock/SummaryAircraftInfoBlock';
import { ApiErrorName } from '../../../../../../lib/constants';

/**AircraftDetailRow renders the details of a row in the aircraft tab.
 * @param {string} index - index of the row
 * @param {string} title - title of the row
 * @param {string} data - data of the row
 * @param {string} dateTimeFormat - format date and time
 * @param {bool} isDateField - date of the row
 * @param {bool} isTimeField - time of the row
 * @param {int} lGColSize - large column size
 * @param {int} sMColSize - small column size
 * @returns AircraftDetailRow  */
const AircraftDetailRow = ({
  index,
  title,
  data,
  dateTimeFormat,
  isDateField,
  isTimeField,
  lGColSize = 1,
  sMColSize = 1,
  station,
  isPDT,
}) => (
  <Grid item sm={sMColSize} xs={lGColSize} md={lGColSize} lg={lGColSize} xl={lGColSize}>
    {title && index === 0 && (
      <div data-cy={`aircraft-title-${title}`} className="aircraft-title">
        {title}
      </div>
    )}
    <SummaryAircraftInfoBlock
      aircraftData={data}
      dateTimeFormat={dateTimeFormat}
      isDateField={isDateField}
      isTimeField={isTimeField}
      title={title}
      station={station}
      isPDT={isPDT}
    />
  </Grid>
);

const DetailConstants = {
  MAINTENANCE_MESSAGES: {
    title: 'Mx Messages List',
    errorName: ApiErrorName.MAINTENANCE_MESSAGES,
    errorMessage: 'Unable to retrieve Mx Messages. Please try again.',
    noDataMsg: 'No Mx messages exist for this flight.',
    rowType: 'mx-messages-list',
    config: [
      { title: 'From', dataKey: 'source' },
      { title: 'Date', dataKey: 'createdByDateTime', dateTimeFormat: 'MM/DD/YYYY', isDateField: true },
      { title: 'Time', dataKey: 'createdByDateTime', dateTimeFormat: 'hh:mm A', isTimeField: true },
      { title: 'Message', dataKey: 'maintenanceDescription', lGColSize: 3, sMColSize: 5 },
    ],
  },
  MEL_DATA: {
    title: 'MEL/CDL List',
    errorName: ApiErrorName.MEL_DATA,
    errorMessage: 'Unable to retrieve MEL/CDL List data. Please try again.',
    noDataMsg: 'No MEL/CDL List exist on this aircraft.',
    rowType: 'mel-cdl-list',
    config: [
      { title: 'MEL', dataKey: 'melNumber' },
      { title: 'CDL', dataKey: 'cdlNumber' },
      { title: '', dataKey: '' },
      { title: 'Description', dataKey: 'description', lGColSize: 3, sMColSize: 5 },
      { title: 'Reported', dataKey: 'reportedDateTimeUTC', dateTimeFormat: 'MM/DD/YYYY', isDateField: true },
      { title: '\u00A0', dataKey: 'reportedDateTimeUTC', dateTimeFormat: 'hh:mm A', isTimeField: true },
      { title: 'Due Date', dataKey: 'dueDate', dateTimeFormat: 'MM/DD/YYYY', isDateField: true, isPDT: true },
    ],
  },
};

// Define the NoDataMessage component to display when no MEL/CDL or MX Messages exist
const NoDataMessage = ({ message }) => (
  <div data-cy="no-mels-cdls-exist-aircraft" className="no-mels-cdls-exist-aircraft">
    {message}
  </div>
);

/**SummaryAircraftDetailRow renders the details of the aircraft tab.
 * @param {array} aircraftDetails - details of the aircraft
 * @param {string} station - station of the aircraft
 * @param {string} detailId - either MEL_DATA || MAINTENANCE_MESSAGES
 * @param {array} errors - list of errors from the api calls
 * @returns SummaryAircraftDetailRow */
const SummaryAircraftDetailRow = ({ aircraftDetails, station, detailId, errors }) => {
  // Extract the summary details for the row based on the detailId
  const { title, rowType, config, errorName, errorMessage, noDataMsg } = DetailConstants[detailId];
  // Locate an error if it exists for the given detail id and errors list
  const errorObj = useMemo(() => errors.find((e) => e.errorName === errorName), [detailId, errors]);
  // Define the default data messages based on no data or if errors are present
  const dataMessage = useMemo(() => (errorObj ? errorMessage : noDataMsg), [errorObj, errorMessage, noDataMsg]);

  return (
    <>
      <Grid container spacing={0}>
        <div data-cy={rowType} className={`aircraft-header ${rowType}`}>
          {title}
        </div>
      </Grid>
      {errorObj || aircraftDetails.length === 0 ? (
        <NoDataMessage message={dataMessage} />
      ) : (
        aircraftDetails.map((item, index) => (
          <Grid container spacing={1} key={index} data-cy="aircraft-detail-row">
            {config.map(({ title, dataKey, isPDT, ...props }) => (
              <AircraftDetailRow
                key={dataKey}
                title={title}
                data={item[dataKey]}
                index={index}
                station={station}
                isPDT={isPDT}
                {...props}
              />
            ))}
          </Grid>
        ))
      )}
    </>
  );
};

SummaryAircraftDetailRow.propTypes = {
  aircraftDetails: PropTypes.array,
  station: PropTypes.string,
  detailId: PropTypes.string,
  errors: PropTypes.array,
};

export default memo(SummaryAircraftDetailRow);
