import React from 'react';
import NotificationAlert from '../../Shared/NotificationAlert/NotificationAlert';
import FlightActionMenu from '../FlightActionMenu/FlightActionMenu';
import TimeDisplay from '../../Shared/TimeDisplay/TimeDisplay';
import { getMonthYearDisplayString, getIrropsCodeLabel } from '../../../lib/displayUtils';

// Default em dash (-) value for empty table cells
const defaultValue = <span className="flight-list-table-default-value">&#8212;</span>;

const getNewAircraft = (aircraft) => {
  return aircraft?.startsWith('N') ? aircraft.slice(1) : aircraft;
};

// Table configurations for the FlightListTable. It determines the columns and
// how data should be rendered. Each object in the array is one column configuration.
export const flightListTableConfig = [
  {
    columnDisplayName: 'Alerts',
    columnKey: 'alert',
    allowSort: true,
    renderFn: (leg) =>
      leg.notifications?.length > 0 ? <NotificationAlert notifications={leg.notifications} /> : defaultValue,
  },
  {
    // Note: Per Kayo, change to use the date portion of the utc scheduled departure times.
    // ScheduledOperatingDateUTC will only be used for the date filter
    columnDisplayName: 'Date (Z)',
    columnKey: 'departureDate',
    allowSort: true,
    renderFn: (leg) => (leg.std != null && leg.std !== '' ? getMonthYearDisplayString(leg.std) : defaultValue),
  },
  {
    columnDisplayName: 'Flight',
    columnKey: 'flightNumber',
    allowSort: true,
    renderFn: (leg) =>
      leg.flightNumber != null && Number.isInteger(leg.flightNumber) ? leg.flightNumber : defaultValue,
  },
  {
    columnDisplayName: 'Aircraft',
    columnKey: 'aircraft',
    allowSort: true,
    renderFn: (leg) => (!!leg.aircraft ? getNewAircraft(leg.aircraft) : defaultValue),
  },
  {
    columnDisplayName: 'Orig',
    columnKey: 'orig',
    allowSort: true,
    renderFn: (leg) => (!!leg.orig ? leg.orig : defaultValue),
  },
  {
    columnDisplayName: 'ETD',
    columnKey: 'etd',
    allowSort: true,
    renderFn: (leg) =>
      !!leg.etd ? (
        <TimeDisplay
          utcTime={leg.etd}
          dataCyTag={`timedisplay-etd-zulu-${leg.flightLegKey}`}
          comparisonUtcTime={leg.std}
        />
      ) : (
        defaultValue
      ),
  },
  {
    columnDisplayName: 'ETD (Local)',
    columnKey: 'etd-local',
    allowSort: false,
    renderFn: (leg) =>
      !!leg.etd ? (
        <TimeDisplay
          utcTime={leg.etd}
          dataCyTag={`timedisplay-etd-local-${leg.flightLegKey}`}
          station={leg.orig}
          comparisonUtcTime={leg.std}
        />
      ) : (
        defaultValue
      ),
  },
  {
    columnDisplayName: 'Dest',
    columnKey: 'dest',
    allowSort: true,
    renderFn: (leg) => leg.projectedDestination ?? (!!leg.dest ? leg.dest : defaultValue),
  },
  {
    columnDisplayName: 'ETA',
    columnKey: 'eta',
    allowSort: true,
    renderFn: (leg) =>
      !!leg.eta ? (
        <TimeDisplay
          utcTime={leg.eta}
          dataCyTag={`timedisplay-eta-zulu-${leg.flightLegKey}`}
          comparisonUtcTime={leg.sta}
        />
      ) : (
        defaultValue
      ),
  },
  {
    columnDisplayName: 'ETA (Local)',
    columnKey: 'eta-local',
    allowSort: false,
    renderFn: (leg) =>
      !!leg.eta ? (
        <TimeDisplay
          utcTime={leg.eta}
          dataCyTag={`timedisplay-eta-local-${leg.flightLegKey}`}
          station={leg.dest}
          comparisonUtcTime={leg.sta}
        />
      ) : (
        defaultValue
      ),
  },
  {
    columnDisplayName: 'Irrops',
    columnKey: 'irropsCode',
    allowSort: true,
    renderFn: (leg) =>
      !!leg.irropsCode ? (
        <>
          {leg.irropsCode}
          &nbsp;
          <span className="secondary-label">{getIrropsCodeLabel(leg.irropsCode)}</span>
        </>
      ) : (
        defaultValue
      ),
  },
  {
    columnDisplayName: 'Action',
    columnKey: 'action',
    allowSort: false,
    renderFn: (leg, isPaneOpen, openDetailPane, summaryPanelMode, handleChangeActivityKey, containerRef) => (
      <FlightActionMenu
        flightLeg={leg}
        isPaneOpen={isPaneOpen}
        openDetailPane={openDetailPane}
        summaryPanelMode={summaryPanelMode}
        handleChangeActivityKey={handleChangeActivityKey}
        containerRef={containerRef}
      />
    ),
  },
];
