import { SessionStorageKeys, ScrollLocalStorageElementId } from '../../lib/constants';
import {
  WindowManagerAction,
  FilterActions,
  ViewConfigurationAction,
  SortActions,
  CrosshairActionKeys,
  RootAction,
  HideFilterActions,
} from '../actionTypes';

const getActiveId = (state) => state.viewManagerReducer?.viewOrder[0] || 0;

/**
 * @description Store the window manager store in the session storage
 * @param {object} state
 * @param {object} action
 */
const storeWindowManagerStore = (state, action) => {
  if (Object.keys(WindowManagerAction).some((wa) => WindowManagerAction[wa] === action.type)) {
    sessionStorage.setItem('windowManagerStore', JSON.stringify(state.viewManagerReducer));
  } else if (action.type === RootAction.UPDATE_ROOTSTATE) {
    sessionStorage.setItem('windowManagerStore', JSON.stringify(state.viewManagerReducer));
  }
};

/**
 * @description Store the filter store in the session storage
 * @param {object} state
 * @param {object} action
 * @param {number} id
 */
const storeFilter = (state, action, id) => {
  if (action.type === FilterActions.UPDATE_FILTER) {
    const currentSessionFilter = JSON.parse(sessionStorage.getItem(SessionStorageKeys.FILTER)) || {};
    const currentWindowData = state.viewWindowData.find((window) => window.id === id);
    if (currentWindowData && !currentWindowData.swapModeReducer?.isSwapModeActive) {
      currentSessionFilter[id] = currentWindowData.filterReducer;
      sessionStorage.setItem(SessionStorageKeys.FILTER, JSON.stringify(currentSessionFilter));
    }
  } else if (action.type === FilterActions.CLEAR_FILTER) {
    const currentSessionFilter = JSON.parse(sessionStorage.getItem(SessionStorageKeys.FILTER)) || {};
    if (
      currentSessionFilter?.[id] &&
      !state.viewWindowData.find((window) => window.id === id).swapModeReducer.isSwapModeActive
    ) {
      delete currentSessionFilter[id];
      sessionStorage.setItem(SessionStorageKeys.FILTER, JSON.stringify(currentSessionFilter));
    }
  } else if (action.type === WindowManagerAction.REMOVE_VIEW_WINDOW) {
    const currentSessionFilter = JSON.parse(sessionStorage.getItem(SessionStorageKeys.FILTER)) || {};
    if (currentSessionFilter?.[action.id]) {
      delete currentSessionFilter[action.id];
      sessionStorage.setItem(SessionStorageKeys.FILTER, JSON.stringify(currentSessionFilter));
    }
  } else if (action.type === RootAction.UPDATE_ROOTSTATE) {
    const currentSessionFilter = {};
    state.viewWindowData.forEach((window) => {
      currentSessionFilter[window.id] = window.filterReducer;
    });
    sessionStorage.setItem(SessionStorageKeys.FILTER, JSON.stringify(currentSessionFilter));
  }
};

/**
 * @description Store the sort filter store in the session storage
 * @param {object} state
 * @param {object} action
 * @param {number} id
 */
const storeSortFilter = (state, action, id) => {
  if (
    action.type === SortActions.DEFAULT_PRIORITY ||
    action.type === SortActions.ETD_PRIORITY ||
    action.type === SortActions.ETA_PRIORITY
  ) {
    const currentSessionSortFilter = JSON.parse(sessionStorage.getItem(SessionStorageKeys.SORT_FILTER)) || {};
    const currentWindowData = state.viewWindowData.find((window) => window.id === id);
    if (currentWindowData) {
      currentSessionSortFilter[id] = currentWindowData.sortReducer;
      sessionStorage.setItem(SessionStorageKeys.SORT_FILTER, JSON.stringify(currentSessionSortFilter));
    }
  } else if (action.type === WindowManagerAction.REMOVE_VIEW_WINDOW) {
    const currentSessionSortFilter = JSON.parse(sessionStorage.getItem(SessionStorageKeys.SORT_FILTER)) || {};
    if (currentSessionSortFilter?.[action.id]) {
      delete currentSessionSortFilter[action.id];
      sessionStorage.setItem(SessionStorageKeys.SORT_FILTER, JSON.stringify(currentSessionSortFilter));
    }
  }
};

/**
 * @description Store the hide cancelled flights store in the session storage
 * @param {object} state
 * @param {object} action
 * @param {number} id
 */
const storeHideFilter = (state, action, id) => {
  if (
    action.type === HideFilterActions.TOGGLE_HIDE_CANCELLED_FLIGHTS ||
    action.type === HideFilterActions.TOGGLE_HIDE_SHORT_TURN_ALERTS ||
    action.type === HideFilterActions.TOGGLE_HIDE_MX_MESSAGES
  ) {
    const currentHideCancelledFlights = JSON.parse(sessionStorage.getItem(SessionStorageKeys.HIDE_FILTER)) || {};
    const currentWindowData = state.viewWindowData.find((window) => window.id === id);
    if (currentWindowData) {
      currentHideCancelledFlights[id] = currentWindowData.hideFilterReducer;
      sessionStorage.setItem(SessionStorageKeys.HIDE_FILTER, JSON.stringify(currentHideCancelledFlights));
    }
  } else if (action.type === WindowManagerAction.REMOVE_VIEW_WINDOW) {
    const currentHideCancelledFlights = JSON.parse(sessionStorage.getItem(SessionStorageKeys.HIDE_FILTER)) || {};
    if (currentHideCancelledFlights?.[action.id]) {
      delete currentHideCancelledFlights[action.id];
      sessionStorage.setItem(SessionStorageKeys.HIDE_FILTER, JSON.stringify(currentHideCancelledFlights));
    }
  }
};

const storeViewConfiguration = (state, action, id) => {
  if (action.type === ViewConfigurationAction.UPDATE_VIEWCONFIGURATION) {
    const currentViewConfiguration = JSON.parse(sessionStorage.getItem(SessionStorageKeys.VIEW_CONFIGURATION)) || {};
    const currentWindowData = state.viewWindowData.find((window) => window.id === id);
    if (currentWindowData) {
      currentViewConfiguration[id] = currentWindowData.viewConfigurationReducer;
      sessionStorage.setItem(SessionStorageKeys.VIEW_CONFIGURATION, JSON.stringify(currentViewConfiguration));
    }
  } else if (action.type === WindowManagerAction.REMOVE_VIEW_WINDOW) {
    // Local Storage to remove scroll view id of gantt - does not get saved in config object or state
    const currentScrollGanttObject = JSON.parse(localStorage.getItem(ScrollLocalStorageElementId.GANTT_CHART)) || {};
    if (currentScrollGanttObject?.[action.id]) {
      delete currentScrollGanttObject[action.id];
      localStorage.setItem(ScrollLocalStorageElementId.GANTT_CHART, JSON.stringify(currentScrollGanttObject));
    }
    // Local Storage to remove scroll view id of table view - does not get saved in config object or state
    const currentScrollTableObject =
      JSON.parse(localStorage.getItem(ScrollLocalStorageElementId.FLIGHTLIST_TABLE)) || {};
    if (currentScrollTableObject?.[action.id]) {
      delete currentScrollTableObject[action.id];
      localStorage.setItem(ScrollLocalStorageElementId.FLIGHTLIST_TABLE, JSON.stringify(currentScrollTableObject));
    }
    // Session Storage
    const currentViewConfiguration = JSON.parse(sessionStorage.getItem(SessionStorageKeys.VIEW_CONFIGURATION)) || {};
    if (currentViewConfiguration?.[action.id]) {
      delete currentViewConfiguration[action.id];
      sessionStorage.setItem(SessionStorageKeys.VIEW_CONFIGURATION, JSON.stringify(currentViewConfiguration));
    }
  } else if (action.type === RootAction.UPDATE_ROOTSTATE) {
    const currentViewConfiguration = {};
    state.viewWindowData.forEach((window) => {
      currentViewConfiguration[window.id] = window.viewConfigurationReducer;
    });
    sessionStorage.setItem(SessionStorageKeys.VIEW_CONFIGURATION, JSON.stringify(currentViewConfiguration));
  }
};

export const storeCrosshair = (state, action, id) => {
  if (
    action.type === CrosshairActionKeys.UPDATE_CROSSHAIR_MODE ||
    action.type === CrosshairActionKeys.UPDATE_CROSSHAIR_COUNT ||
    action.type === CrosshairActionKeys.UPDATE_ACTIVE_CROSSHAIR ||
    action.type === CrosshairActionKeys.UPDATE_CROSSHAIR_POSITION
  ) {
    const currentWindowData = state.viewWindowData.find((window) => window.id === id);
    if (currentWindowData) {
      const currentCrosshairState = JSON.parse(sessionStorage.getItem(SessionStorageKeys.CROSSHAIR)) || {};
      currentCrosshairState[id] = currentWindowData.crosshairReducer;
      sessionStorage.setItem(SessionStorageKeys.CROSSHAIR, JSON.stringify(currentCrosshairState));
    }
  } else if (action.type === WindowManagerAction.REMOVE_VIEW_WINDOW) {
    const currentCrosshairState = JSON.parse(sessionStorage.getItem(SessionStorageKeys.CROSSHAIR)) || {};
    if (currentCrosshairState?.[action.id]) {
      delete currentCrosshairState[action.id];
      sessionStorage.setItem(SessionStorageKeys.CROSSHAIR, JSON.stringify(currentCrosshairState));
    }
  } else if (action.type === CrosshairActionKeys.CLEAR_CROSSHAIR_STORE) {
    const currentWindowData = state.viewWindowData.find((window) => window.id === id);
    const currentCrosshairState = JSON.parse(sessionStorage.getItem(SessionStorageKeys.CROSSHAIR)) || {};
    if (currentWindowData && currentCrosshairState?.[id]) {
      delete currentCrosshairState[id];
      sessionStorage.setItem(SessionStorageKeys.CROSSHAIR, JSON.stringify(currentCrosshairState));
    }
  }
};

export const storeUserConfig = (state, action) => {
  if (action.type === RootAction.UPDATE_ROOTSTATE) {
    const currentUserConfig = state.configReducer;
    sessionStorage.setItem(SessionStorageKeys.USER_CONFIG, JSON.stringify(currentUserConfig));
  }
};

/**
 * @description Middleware to persist the store in session storage
 * @param {object} store
 * @param {function} next
 * @param {object} action
 * @returns
 */
export const storeSession = (store) => (next) => (action) => {
  let result = next(action);
  const state = store.getState();
  const activeId = getActiveId(state);
  storeUserConfig(state, action);
  storeWindowManagerStore(state, action);
  storeFilter(state, action, activeId);
  storeSortFilter(state, action, activeId);
  storeViewConfiguration(state, action, activeId);
  storeCrosshair(state, action, activeId);
  storeHideFilter(state, action, activeId);
  return result;
};
