import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import SearchInput from '../../Shared/SearchInput/SearchInput';
import './SearchFlightNumber.css';
import useFlightListTableData from '../../../hooks/useFlightListTableData/useFlightListTableData';
import { useFocusedFlightLegDispatch } from '../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import { FlightView } from '../../../lib/constants';
import {
  useSelectedItemDispatch,
  useSelectedItemStore,
} from '../../../hooks/useSelectedItemStore/useSelectedItemStore';
import { isNullOrWhitespace } from '../../../lib/displayUtils';
import { useFlightPuckRefsDispatch } from '../../../hooks/useFlightPuckRefStore/useFlightPuckRefStore';
import { useGanttConfig } from '../../../hooks/useGanttConfig/useGanttConfig';
import { useHideStore } from '../../../hooks/useHideFilterStore/useHideFilterStore';

/**
 * descriptor - function called after user clicks enter
 */
const onSearch = (searchFlightNum, setShowMatches, setCurrentIndex, setFlightInStore) => {
  if (!isNullOrWhitespace(searchFlightNum)) {
    setShowMatches(true);
    setCurrentIndex(0);
    setFlightInStore(0);
  } else {
    setShowMatches(false);
  }
};

/**
 * descriptor - function called when clear button is clicked
 */
const onClear = (
  setShowMatches,
  setCurrentIndex,
  setSearchFlightNumberResults,
  setSearchFlightNum,
  highlightFocusedFlightLeg,
  setSavedCanceledIndex,
  clearFocusedFlightLeg,
) => {
  setShowMatches(false);
  setCurrentIndex(0);
  setSearchFlightNumberResults([]);
  setSearchFlightNum('');
  highlightFocusedFlightLeg(false);
  setSavedCanceledIndex(null);
  clearFocusedFlightLeg();
};

/**
 * descriptor -function called when next button is clicked
 */
const onNextClick = (
  searchFlightNumberResults,
  showMatches,
  currentIndex,
  setCurrentIndex,
  setFlightInStore,
  setSavedCanceledIndex,
) => {
  if (searchFlightNumberResults.length > 1 && currentIndex < searchFlightNumberResults.length - 1 && showMatches) {
    setSavedCanceledIndex(null);
    setCurrentIndex(currentIndex + 1);
    setFlightInStore(currentIndex + 1);
  }
};

/**
 * descriptor - function called after previous button is clicked
 */
const onPreviousClick = (
  searchFlightNumberResults,
  showMatches,
  currentIndex,
  setCurrentIndex,
  setFlightInStore,
  setSavedCanceledIndex,
) => {
  if (searchFlightNumberResults.length > 1 && currentIndex > 0 && showMatches) {
    setSavedCanceledIndex(null);
    setCurrentIndex(currentIndex - 1);
    setFlightInStore(currentIndex - 1);
  }
};

/**
 * descriptor - function called when user edits search flight number
 */
const onChange = (event, setShowMatches, setSearchFlightNum, highlightFocusedFlightLeg, clearFocusedFlightLeg) => {
  setShowMatches(false);
  highlightFocusedFlightLeg(false);
  clearFocusedFlightLeg();
  if (event.target.value == '') {
    setSearchFlightNum(event.target.value);
  } else if (event.target.value.match(/^\d+$/) !== null && event.target.value.length <= 4) {
    setSearchFlightNum(event.target.value);
  }
};

const formatGanttFlightData = (ganttFlightData) => {
  return Object.keys(ganttFlightData)
    .map((key) => [...ganttFlightData[key].scheduled, ...ganttFlightData[key].canceled])
    .flat()
    .filter((flight) => flight.flightPuckData)
    .map((flight) => flight.flightPuckData);
};

const getFlightData = (viewType, tableFlightData, ganttFlightData) => {
  if (viewType === FlightView.GANTT_VIEW && ganttFlightData) {
    return formatGanttFlightData(ganttFlightData);
  } else {
    return tableFlightData;
  }
};

const getSearchedFlightLegs = (searchFlightNum, tableFlightData, ganttFlightData, viewType) => {
  const flightData = getFlightData(viewType, tableFlightData, ganttFlightData);
  return flightData
    .filter((flightLeg) => flightLeg.flightNumber == searchFlightNum)
    .sort((a, b) => (new Date(a.etd) > new Date(b.etd) ? 1 : -1));
};

const getClosestIndex = (filteredFlightLegs, searchFlightNumberResults, currentIndex) => {
  return filteredFlightLegs.reduce((acc, flightLeg, index) => {
    const previousIndex = searchFlightNumberResults.findIndex((f) => f.flightLegKey == flightLeg.flightLegKey);
    return Math.abs(previousIndex - currentIndex) < Math.abs(acc - currentIndex) ? index : acc;
  }, 0);
};

/**
 * Renders a component for searching flight numbers.
 * @returns {SearchFlightNumber} The rendered component.
 */
const SearchFlightNumber = ({ viewType }, ref) => {
  const {
    loading: loadingTable,
    flightData: tableFlightData,
    error: tableError,
  } = useFlightListTableData(viewType === FlightView.TABLE_VIEW);
  const [searchFlightNum, setSearchFlightNum] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showMatches, setShowMatches] = useState(false);
  const [searchFlightNumberResults, setSearchFlightNumberResults] = useState([]);
  const { setFocusedFlightLeg, clearFocusedFlightLeg, highlightFocusedFlightLeg } = useFocusedFlightLegDispatch();
  const { updateSelectedFlightDetails } = useSelectedItemDispatch();
  const selectedFlightDetails = useSelectedItemStore();
  const { removeFlightPuckRef } = useFlightPuckRefsDispatch();
  const ganttConfig = useGanttConfig(viewType === FlightView.GANTT_VIEW);
  const [savedGanttResults, setSavedGanttResults] = useState([]);
  const [savedGanttIndex, setSavedGanttIndex] = useState(null);
  const { hideCancelledFlights } = useHideStore();
  const [savedCanceledIndex, setSavedCanceledIndex] = useState(null);

  const retriggerSearch = () => {
    if (tableFlightData.length > 0 || !!ganttConfig?.ganttConfig) {
      onSearch(searchFlightNum, setShowMatches, setCurrentIndex, setFlightInStore);
    } else {
      setCurrentIndex(0);
      setSearchFlightNumberResults([]);
    }
  };

  useImperativeHandle(ref, () => ({
    resetSearchNum: () => {
      onClear(
        setShowMatches,
        setCurrentIndex,
        setSearchFlightNumberResults,
        setSearchFlightNum,
        highlightFocusedFlightLeg,
        setSavedCanceledIndex,
        clearFocusedFlightLeg,
      );
    },
    clearSearchKeepFocusedFlightLeg: () => {
      onClear(
        setShowMatches,
        setCurrentIndex,
        setSearchFlightNumberResults,
        setSearchFlightNum,
        highlightFocusedFlightLeg,
        setSavedCanceledIndex,
        () => {},
      );
    },
    retriggerSearch,
  }));

  useEffect(() => {
    if (
      (tableFlightData.length > 0 && viewType === FlightView.TABLE_VIEW) ||
      (!!ganttConfig?.ganttConfig && viewType === FlightView.GANTT_VIEW)
    ) {
      retriggerSearch();
    }
  }, [tableFlightData, ganttConfig]);

  useEffect(() => {
    if (!isNullOrWhitespace(searchFlightNum) && showMatches) {
      const filteredFlightLegs = getSearchedFlightLegs(
        searchFlightNum,
        tableFlightData,
        ganttConfig?.ganttConfig,
        viewType,
      );
      let newIndex = null;
      if (!hideCancelledFlights && savedCanceledIndex != null) {
        newIndex = savedCanceledIndex;
        setSavedCanceledIndex(null);
      } else if (selectedFlightDetails?.data?.flightLegKey) {
        newIndex = filteredFlightLegs.findIndex(
          (flightLeg) => flightLeg.flightLegKey == selectedFlightDetails?.data?.flightLegKey,
        );
        if (newIndex === -1) {
          setSavedCanceledIndex(currentIndex);
          newIndex = getClosestIndex(filteredFlightLegs, searchFlightNumberResults, currentIndex);
        }
      }
      setCurrentIndex(newIndex);
      setFlightInStore(newIndex);
    }
  }, [hideCancelledFlights]);

  useEffect(() => {
    var tableFlightSearches = tableFlightData.some((f) => f.flightNumber == searchFlightNum);
    if (
      viewType === FlightView.TABLE_VIEW &&
      !isNullOrWhitespace(searchFlightNum) &&
      (searchFlightNumberResults.length > 0 ||
        (searchFlightNumberResults === 0 && ganttConfig?.ganttErrors?.flights)) &&
      !tableFlightSearches
    ) {
      clearFocusedFlightLeg();
      removeFlightPuckRef();
      setSavedGanttIndex(currentIndex);
      setSavedGanttResults(searchFlightNumberResults);
      setSearchFlightNumberResults([]);
      setCurrentIndex(0);
    } else if (viewType === FlightView.GANTT_VIEW) {
      if (savedGanttResults.length > 0 && savedGanttIndex != null) {
        setSearchFlightNumberResults(savedGanttResults);
        setCurrentIndex(savedGanttIndex);
        updateSelectedFlightDetails({
          data: savedGanttResults[savedGanttIndex],
          isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
          isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
        });
        setFocusedFlightLeg(savedGanttResults[savedGanttIndex]);
        highlightFocusedFlightLeg(true);
      } else if (!tableFlightSearches) {
        onSearch(searchFlightNum, setShowMatches, setCurrentIndex, setFlightInStore);
      }
      if (ganttConfig?.ganttErrors?.flights) {
        onClear(
          setShowMatches,
          setCurrentIndex,
          setSearchFlightNumberResults,
          setSearchFlightNum,
          highlightFocusedFlightLeg,
          setSavedCanceledIndex,
          clearFocusedFlightLeg,
        );
      }
    } else if (viewType === FlightView.TABLE_VIEW && ganttConfig?.ganttErrors?.flights) {
      onSearch(searchFlightNum, setShowMatches, setCurrentIndex, setFlightInStore);
    }
  }, [viewType]);

  /**
   * descriptor - function which sets the values in useFocusedFlightLegStore and useSelectedItemStore
   */
  function setFlightInStore(index = null) {
    if (
      ((!loadingTable && viewType === FlightView.TABLE_VIEW && !tableError) || viewType === FlightView.GANTT_VIEW) &&
      ((!ganttConfig?.loading && viewType === FlightView.GANTT_VIEW && !ganttConfig?.errors?.flights) ||
        viewType === FlightView.TABLE_VIEW)
    ) {
      if (savedGanttIndex != null || savedGanttResults.length > 0) {
        setSavedGanttIndex(null);
        setSavedGanttResults([]);
      }
      const filteredFlightLegs = getSearchedFlightLegs(
        searchFlightNum,
        tableFlightData,
        ganttConfig?.ganttConfig,
        viewType,
      );
      const updatedIndex = index ?? currentIndex;
      if (filteredFlightLegs.length != 0) {
        setSearchFlightNumberResults(filteredFlightLegs);
        updateSelectedFlightDetails({
          data: filteredFlightLegs[updatedIndex],
          isFlightPanelOpen: selectedFlightDetails?.isFlightPanelOpen,
          isActivityTabOpen: selectedFlightDetails?.isActivityTabOpen,
        });
        setFocusedFlightLeg(filteredFlightLegs[updatedIndex]);
        highlightFocusedFlightLeg(true);
      } else {
        setCurrentIndex(0);
        setSearchFlightNumberResults([]);
        clearFocusedFlightLeg();
        highlightFocusedFlightLeg(false);
      }
    }
  }

  return (
    <div className="search-flight-number-input-container" data-cy="search-flight-number-input-container">
      <SearchInput
        matchCount={showMatches ? searchFlightNumberResults.length : null}
        onNextClick={() =>
          onNextClick(
            searchFlightNumberResults,
            showMatches,
            currentIndex,
            setCurrentIndex,
            setFlightInStore,
            setSavedCanceledIndex,
          )
        }
        onEnter={() => onSearch(searchFlightNum, setShowMatches, setCurrentIndex, setFlightInStore)}
        onPreviousClick={() =>
          onPreviousClick(
            searchFlightNumberResults,
            showMatches,
            currentIndex,
            setCurrentIndex,
            setFlightInStore,
            setSavedCanceledIndex,
          )
        }
        onClear={() =>
          onClear(
            setShowMatches,
            setCurrentIndex,
            setSearchFlightNumberResults,
            setSearchFlightNum,
            highlightFocusedFlightLeg,
            setSavedCanceledIndex,
            clearFocusedFlightLeg,
          )
        }
        onChange={(e) =>
          onChange(e, setShowMatches, setSearchFlightNum, highlightFocusedFlightLeg, clearFocusedFlightLeg)
        }
        onSearchClick={() => onSearch(searchFlightNum, setShowMatches, setCurrentIndex, setFlightInStore)}
        label="Search Flight Number"
        value={searchFlightNum}
        hasPrevious={showMatches && searchFlightNumberResults.length > 1 && currentIndex > 0}
        hasNext={
          showMatches && searchFlightNumberResults.length > 1 && currentIndex < searchFlightNumberResults.length - 1
        }
      />
    </div>
  );
};

export default forwardRef(SearchFlightNumber);
