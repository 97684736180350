import React, { useEffect, useState, useRef } from 'react';
import './SwapFooter.css';
import Button from '../../../Shared/Button/Button';
import { KeyCodes, ButtonText, Treatment } from '../../../../lib/constants';
import { useSwapModeDispatch, useSwapModeStore } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import LeaveSwapModeModal from '../LeaveSwapModeModal/LeaveSwapModeModal';
import SwapConfirmationModal from '../SwapConfirmationModal/SwapConfirmationModal';
import SwapWarningModal from '../SwapWarningModal/SwapWarningModal';
import { useMultiSelectedFlightGroupDispatch } from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { createSwapwarningPayload } from '../../../../lib/swapUtil';
import { useNotificationUpdate } from '../../../../contexts/NotificationsContext/NotificationsContext';
import { ToastType } from '../../../Shared/NotificationToast/NotificationToast';
import { swapsWarningsApi } from '../../../../services/apiClient/swapsWarningsApi/swapsWarningsApi';

/**
 * The SwapFooter component render the swap mode footer
 * It provides the action buttons
 * @returns SwapMode footer
 */
export const SwapFooter = () => {
  const { showFeature } = useFeatureFlag();
  const showSwapWarningModalFeature = showFeature(Treatment.FLIGHT_SWAP_SHOWWARNINGMODAL);
  const swapWarningV2flag = showFeature(Treatment.SWAP_WARNINGS_V2);
  const { clearSwapMode, undoSwapFlightLegs, redoSwapFlightLegs, discardSwapFlightLegs, setNeedSwapWarnings } =
    useSwapModeDispatch();
  const {
    isSwapModeActive,
    isSwapSelected,
    swapFlightLegs,
    swapFlightLegsHistory,
    latestSwappedFlightLegs,
    futureSwapFlightLegs,
    disableSwapSubmit,
    previousFlightLegs,
    needSwapWarnings,
  } = useSwapModeStore();
  const { removeAllMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showSwapConfirmModal, setShowSwapConfirmModal] = useState(false);
  const [showSwapWarningModal, setShowSwapWarningModal] = useState(false);
  const [swapWarningsData, setSwapWarningsData] = useState([]);
  const [isSwapSuccess, setIsSwapSuccess] = useState(false);
  const [toastNotificationProps, setToastNotificationProps] = useState({
    showToast: false,
    message: '',
    toastType: '',
    autoHide: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const previousPayloadRef = useRef([]);
  const notificationUpdate = useNotificationUpdate();

  const defaultToastNotificationProps = {
    showToast: false,
    message: '',
    toastType: '',
    autoHide: false,
    onClose: () => {
      setSwapWarningsData([]);
      setToastNotificationProps({ ...toastNotificationProps, showToast: false, message: '' });
    },
  };

  const handleSubmitButtonClick = () => {
    setShowSwapConfirmModal(true);
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && activeSwapsSelected()) {
      handleSubmitButtonClick();
    }
  };

  /**
   * Checks if active swaps are selected.
   * @returns {boolean} True if swap mode is active, there are swap flight legs selected, and there are no swap warning data; otherwise, false.
   */
  const activeSwapsSelected = () => {
    return (
      !disableSwapSubmit &&
      isSwapModeActive &&
      swapFlightLegs?.length > 0 &&
      swapWarningsData &&
      swapWarningsData.length === 0
    );
  };
  //Swap footer cancel Event trigger
  const swapFooterCancelClick = () => {
    setShowConfirmPopup(true);
  };

  // handleOnClose modal
  const handleOnClose = (isSuccess) => {
    setShowSwapConfirmModal(false);
    if (isSuccess) {
      setIsSwapSuccess(true);
    }
  };

  useEffect(() => {
    if (isSwapSuccess && !disableSwapSubmit && !swapFlightLegs?.length) {
      notificationUpdate(ToastType.SUCCESS, '', false, false); // Hide toast notification
      setIsSwapSuccess(false);
    }
  }, [isSwapSuccess, disableSwapSubmit, swapFlightLegs]);

  useEffect(() => {
    // handle swap warning modal
    if (showSwapWarningModalFeature && swapWarningV2flag && latestSwappedFlightLegs?.length > 0 && needSwapWarnings) {
      swapsWarningsApiCall(latestSwappedFlightLegs, previousFlightLegs);
      setNeedSwapWarnings(false);
    }
  }, [needSwapWarnings]);

  useEffect(() => {
    if (latestSwappedFlightLegs?.length > 0 && showSwapWarningModalFeature && !swapWarningV2flag) {
      swapsWarningsApiCall(latestSwappedFlightLegs);
    }
  }, [swapFlightLegs]);

  // handle swap warning modal cancel
  const handleErrorState = () => {
    const failedMessage = 'Failed to perform Swap Warning check, perform manual validation or cancel and try again.';
    setSwapWarningsData([]);
    setToastNotificationProps({
      ...defaultToastNotificationProps,
      showToast: true,
      message: failedMessage,
      toastType: ToastType.ERROR,
    });
    setShowSwapWarningModal(true);
  };

  // update swap warnings data
  const updateSwapWarningsData = (data) => {
    const updateWarningList = data?.reduce((acc, warning) => {
      const operatingAirline = previousPayloadRef?.current?.find(
        (item) => item.aircraftRegistration === warning.aircraftRegistration,
      )?.operatingAirlineCode;
      return [...acc, { operatingAirline, ...warning }];
    }, []);

    setSwapWarningsData(updateWarningList);
    if (updateWarningList?.length) {
      setShowSwapWarningModal(true);
    }
  };

  /**
   * Makes an API call to retrieve swap warnings and updates the swap warnings data.
   * @returns {Promise<void>} A Promise that resolves when the API call is complete.
   */
  const swapsWarningsApiCall = async (latestSwappedFlightLegs, previousSwapFlightLeg) => {
    setIsLoading(true);
    const toastMessageText = 'Checking for Swap Warnings';
    notificationUpdate(ToastType.SUCCESS, toastMessageText, true, false); // Show toast notification

    const payload = createSwapwarningPayload(latestSwappedFlightLegs, previousSwapFlightLeg, swapWarningV2flag);
    previousPayloadRef.current = payload;
    // Call the API to get the swap warnings
    const response = await swapsWarningsApi({ swapsWarningsPayload: payload, swapWarningV2flag: swapWarningV2flag });
    if (!response) {
      handleErrorState();
    } else {
      updateSwapWarningsData(response);
    }
    // Hide the toast notification
    notificationUpdate(ToastType.SUCCESS, '', false, false); // Hide toast notification
    setIsLoading(false);
  };

  // handleOnClose modal
  const handleSwapWarningModalOnClose = () => {
    setToastNotificationProps({ ...defaultToastNotificationProps, showToast: false, message: '' });
    setShowSwapWarningModal(false);
    setSwapWarningsData([]);
  };

  // onHideHandler modal
  const onHideHandler = () => {
    setShowSwapWarningModal(false);
    setSwapWarningsData([]);
    discardSwapFlightLegs();
  };
  //Event trigger after leave button is clicked on LeaveSwapModeModal
  const handleConfirmLeaveClick = () => {
    setShowConfirmPopup(false);
    clearSwapMode();
    removeAllMultiSelectedFlightGroup();
  };

  // handle swap warning modal cancel
  const handleSwapWarningModalOnCancel = () => {
    setToastNotificationProps({ ...defaultToastNotificationProps, showToast: false, message: '' });
    setShowSwapWarningModal(false);
    setSwapWarningsData([]);
    undoSwapFlightLegs();
  };

  //Event trigger after close LeaveSwapModeModal clicked
  const hideHandleConfirmCancel = () => {
    setShowConfirmPopup(false);
  };

  //Event trigger after any key press on LeaveSwapModeModal
  const handleConfirmKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === KeyCodes.ENTER && showConfirmPopup) {
      setShowConfirmPopup(false);
      clearSwapMode();
      removeAllMultiSelectedFlightGroup();
    }
  };

  // undo button event handler
  const undoHandler = () => {
    undoSwapFlightLegs();
  };

  // redo button event handler
  const redoHandler = () => {
    redoSwapFlightLegs();
  };

  return (
    <>
      <div data-cy="flights-gantt-footer" className="flights-gantt-footer">
        <div className="swap-footer-cancel-container" data-cy="swap-footer-cancel-container">
          <FontAwesomeIcon
            data-cy="swap-footer-cancel"
            className="swap-footer-cancel"
            onClick={swapFooterCancelClick}
            icon={faTimes}
          />
        </div>
        <div data-cy="flights-gantt-footer-button-Container" className="footer-buttons">
          <div className="undo-redo-section">
            <div className="vertical-line"></div>
            <div className="undo-redo-button-container">
              <Button
                className="undo-redo-button"
                variant="secondary"
                isDisabled={!(swapFlightLegsHistory && swapFlightLegsHistory.length > 0 && !disableSwapSubmit)}
                tabIndex="0"
                onClick={undoHandler}
              >
                Undo
              </Button>
            </div>
            <div className="undo-redo-button-container">
              <Button
                className="undo-redo-button"
                variant="secondary"
                isDisabled={!(futureSwapFlightLegs && futureSwapFlightLegs.length > 0)}
                tabIndex="0"
                onClick={redoHandler}
              >
                Redo
              </Button>
            </div>
            <div className="vertical-line"></div>
          </div>
          <div className="swap-footer-button-container">
            <Button
              variant="primary"
              tabIndex="0"
              data-cy="flights-gantt-footer-submit-button"
              onClick={handleSubmitButtonClick}
              onKeyPress={handleKeypress}
              isDisabled={!activeSwapsSelected() || isLoading}
            >
              {ButtonText.SUBMIT}
            </Button>
          </div>
        </div>
      </div>
      <LeaveSwapModeModal
        showConfirmPopup={showConfirmPopup}
        handleLeaveClick={handleConfirmLeaveClick}
        handleOnKeyPress={handleConfirmKeypress}
        handleHideClick={hideHandleConfirmCancel}
        dataCyTag="leave-swap-mode"
        title="Leave Swap Mode?"
        containerText={`You will lose unsaved changes if you leave swap mode.`}
      />
      {showSwapConfirmModal && <SwapConfirmationModal onClose={handleOnClose} />}
      {isSwapSelected && showSwapWarningModalFeature && showSwapWarningModal && (
        <SwapWarningModal
          onCancel={handleSwapWarningModalOnCancel}
          onClose={handleSwapWarningModalOnClose}
          onHide={onHideHandler}
          swapWarnings={swapWarningsData}
          toastNotificationProps={toastNotificationProps}
        />
      )}
    </>
  );
};
