import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { useCrosshairDispatch, useCrosshairStore } from '../../../../../hooks/useCrosshairStore/useCrosshairStore';
import './CrosshairActiveButton.css';
import { SessionStorageKeys, Treatment } from '../../../../../lib/constants';
import { useFeatureFlag } from '../../../../../contexts/FeatureFlagContext/FeatureFlagContext';

/**
 * The CrosshairActiveButton component is crosshair icon to active moving lines on gantt chart.
 * @returns CrossHairButton component
 */
const CrosshairActiveButton = () => {
  const { isCrosshairActive } = useCrosshairStore();
  const { updateCrosshair, clearCrosshairStore } = useCrosshairDispatch();
  const { showFeature } = useFeatureFlag();
  const showMultipleViews = showFeature(Treatment.MULTIPLE_VIEWS);

  const activeModeHandler = useCallback(
    () => (!isCrosshairActive ? 'inactive-mode' : 'active-mode'),
    [isCrosshairActive],
  );

  // onclick handler
  const onClickCrosshairButton = () => {
    if (!isCrosshairActive) {
      if (!showMultipleViews) {
        const crosshairSessionStorage = JSON.parse(sessionStorage.getItem(SessionStorageKeys.CROSSHAIR)) ?? {};
        crosshairSessionStorage.isCrosshairActive = true;
        sessionStorage.setItem(SessionStorageKeys.CROSSHAIR, JSON.stringify(crosshairSessionStorage));
      }
      updateCrosshair({ isCrosshairActive: true });
    } else {
      if (!showMultipleViews) {
        sessionStorage.removeItem(SessionStorageKeys.CROSSHAIR);
      }
      clearCrosshairStore();
    }
  };

  return (
    <div
      className={`button crosshair-button-container crosshair-button-${activeModeHandler()}`}
      onClick={onClickCrosshairButton}
      data-cy="crosshair-button"
    >
      <FontAwesomeIcon className="crosshair-icon crosshair-btn" icon={faCrosshairs} data-cy="crosshair-btn" />
    </div>
  );
};

export default CrosshairActiveButton;
