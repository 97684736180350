import { convertRemToPixels } from '../../../lib/utils';
import { PuckSize, GanttScale, PuckType } from '../../../lib/constants';
import { mapFlightDataToOutputWithKey } from '../../../lib/swapUtil';
import dayjs from 'dayjs';

const getRightPuckSize = (ganttRowHeight) => {
  if (ganttRowHeight > convertRemToPixels(GanttScale.xlarge.puckHeight) * 1.5) {
    return PuckSize.X_LARGE;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.large.puckHeight) * 1.4) {
    return PuckSize.LARGE;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.medium.puckHeight) * 1.3) {
    return PuckSize.MEDIUM;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.small.puckHeight) * 1.2) {
    return PuckSize.SMALL;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.xsmall.puckHeight) * 1.1) {
    return PuckSize.X_SMALL;
  } else if (ganttRowHeight > convertRemToPixels(GanttScale.xxsmall.puckHeight)) {
    return PuckSize.XX_SMALL;
  } else {
    return PuckSize.XXX_SMALL;
  }
};

export const setPuckSizeGanttRowHeight = (
  enableEnhancedScalingFlag,
  bottomContentRef,
  viewConfigurationData,
  updateViewConfiguration,
  updatePuckSizePreference,
  setEnhancedGanttRowHeight,
  summaryPanelHeight,
) => {
  if (!enableEnhancedScalingFlag) {
    return;
  }

  if (bottomContentRef) {
    let height = 0;
    if (bottomContentRef.current && viewConfigurationData.numberOfAircrafts) {
      height = bottomContentRef.current.getBoundingClientRect().height + (summaryPanelHeight ?? 0);

      if (height > 0) {
        const currentScaling = viewConfigurationData;
        const ganttRowHeight = height / currentScaling.numberOfAircrafts;
        const rightPuckSize = getRightPuckSize(ganttRowHeight);

        if (rightPuckSize !== viewConfigurationData.puckSize) {
          updateViewConfiguration({ puckSize: rightPuckSize });
          updatePuckSizePreference(rightPuckSize);
        }

        setEnhancedGanttRowHeight(ganttRowHeight);
      }
    }
  }
};

export const sortByEtd = (a, b) => {
  return dayjs(a.etd).diff(dayjs(b.etd));
};

export const getPreviousPuck = (aircraftScheduled, index) => {
  if (index > 0) {
    for (let i = index - 1; i >= 0; i--) {
      if (aircraftScheduled[i].puckType === PuckType.FLIGHT) {
        return aircraftScheduled[i];
      }
    }
  }
};

export const getPreviousFlightLegs = (latestSwappedFlightLegs, ganttConfig) => {
  const swappedAircrafts = new Map();
  latestSwappedFlightLegs.forEach((flightLeg) => {
    if (swappedAircrafts.has(flightLeg.swapAircraftRegistration)) {
      swappedAircrafts.get(flightLeg.swapAircraftRegistration).push(flightLeg);
    } else {
      swappedAircrafts.set(flightLeg.swapAircraftRegistration, [flightLeg]);
    }
  });
  // Sort each aircrafts flight legs by Etd
  swappedAircrafts.forEach((flightLegs) => {
    flightLegs.sort(sortByEtd);
  });
  // Return the previous flight leg for each aircraft
  const previousFlightLegs = {};
  swappedAircrafts.forEach((flightLegs, aircraft) => {
    const index = ganttConfig[aircraft]?.scheduled?.findIndex(
      (flightLeg) => flightLeg?.flightPuckData?.flightLegKey === flightLegs[0].flightLegKey,
    );
    if (index > 0) {
      const previousPuckConfig = getPreviousPuck(ganttConfig[aircraft].scheduled, index);
      if (previousPuckConfig?.flightPuckData) {
        previousFlightLegs[aircraft] = mapFlightDataToOutputWithKey(previousPuckConfig.flightPuckData);
      }
    }
  });
  return previousFlightLegs;
};

export const checkAndSetPreviousFlightLegs = ({
  needPreviousFlightLegs,
  latestSwappedFlightLegs,
  setPreviousFlightLegs,
  ganttConfig,
}) => {
  if (ganttConfig && latestSwappedFlightLegs?.length && needPreviousFlightLegs) {
    const previousFlightLegs = getPreviousFlightLegs(latestSwappedFlightLegs, ganttConfig);
    setPreviousFlightLegs(previousFlightLegs);
  }
};

export default getRightPuckSize;
